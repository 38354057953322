interface Props {
  enabled: boolean;
  Wrapper: any;
  row: any;
  children: any;
}

export const ColumnActions = ({ enabled, Wrapper, row, children }: Props) => {
  if (enabled) {
    return <Wrapper row={row}>{children}</Wrapper>;
  }

  return <div style={{ padding: '24px 16px' }}>{children}</div>;
};
