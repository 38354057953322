import { CSSProperties } from 'react';
import { PRIMARY_SILVER_COLOR } from '@constants/colors';

export const plan: Record<string, CSSProperties | any> = {
  wrapper: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '50% 50%',
    height: '100%',
  },
  title: {
    display: 'flex',
    gap: '10px',
    cursor: 'default'
  },
  planBlock: {
    display: 'flex',
    flexDirection: 'column'
  },
  buildings: {
    display: 'grid',
    gap: '10px',
    margin: '20px 0',
  },
  subtitle: {
    display: 'block',
    height: '50px',
    margin: '20px 0',
  },
  summary: {
    backgroundColor: PRIMARY_SILVER_COLOR[100],
    padding: '30px',
  },
  grid: {
    display: 'grid',
    gap: '24px 38px',
    gridTemplateColumns: '1fr 1fr',
  },
  line: {
    border: '1px solid #D7D7DC',
    display: 'block',
    margin: '20px 0',
    opacity: '0.5',
  },
  planOfRecordLabel: {
    flexShrink: 0,
    padding: '0 12px',
  }
};

export const actionWrapper: CSSProperties = {
  display: 'flex',
  gap: '15px',
};
