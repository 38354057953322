import { ChangeEvent, FC, useState } from 'react';
import moment from 'moment';
import { Typography, InputLabel } from '@mui/material';
import { SideBar, Button, TextInput, PageSpinner } from '@components';
import { PRIMARY_COLORS } from '@constants/colors';
import { handleTrimObjValue } from '@service/helpers';
import { Status } from '../project/Status';
import { savePlanSchema } from '../../schemas';
import { BaseDataState, Project, STATUS_COLORS } from '../../types';
import { styles } from './styles';

interface Props {
  isOpen: boolean;
  toggleSideBar: () => void;
  id: number;
  data: Project[];
  editProject: any;
}

export const EditProjectSideBar: FC<Props> = ({
  isOpen,
  toggleSideBar,
  id,
  data,
  editProject,
}: Props) => {
  const project = data.find((item: Project) => item.id === id)!;
  const { name, description, ...rest } = project;
  const staticData = {
    ...rest,
    updated_at: moment(rest.updated_at).format('D MMM YYYY, HH:mm'),
    client: rest.customer_name,
  };

  return (
    <SideBar isOpen={isOpen} onClose={toggleSideBar}>
      <SideBarContent
        staticData={staticData}
        initialData={{ name, description, id }}
        onClose={toggleSideBar}
        onEdit={editProject}
      />
    </SideBar>
  );
};

const SideBarContent = ({ staticData, initialData, onClose, onEdit }: any) => {
  const [formData, setFormData] = useState<BaseDataState>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: BaseDataState) => ({ ...data, [id]: value }));
      await savePlanSchema.validateAt(id, { ...formData, [id]: value });
      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleSubmit = async () => {
    if (!Object.keys(errors).length) {
      try {
        await onEdit.mutateAsync(handleTrimObjValue(formData));
        onClose();
      } catch (e: any) {
        if (e.response?.data?.error === 'Input data validation error') {
          setErrors((error) => ({ ...error, name: e.response.data.details }));
        }
      }
    }
  };

  return (
    <div style={styles.wrapper}>
      <Button onClick={handleSubmit}>Save Changes</Button>
      <Typography variant="h1" component="h1">
        Project Details
      </Typography>
      <TextInput
        id="name"
        required
        label="Project name"
        value={formData.name || ''}
        onChange={handleFormChange}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextInput
        id="description"
        label="Description"
        multiline
        minRows={6}
        value={formData.description || ''}
        onChange={handleFormChange}
        error={!!errors.description}
        helperText={errors.description}
      />
      <div style={styles.contentGrid}>
        <TextInput
          disabled
          label="Client"
          value={staticData?.client || ''}
          sx={{ gridArea: 'a' }}
        />
        <TextInput
          disabled
          label="Date Modified"
          value={staticData?.updated_at || ''}
          sx={{ gridArea: 'b' }}
        />
        <TextInput
          disabled
          label="Modified By"
          value={staticData?.updated_by || ''}
          sx={{ gridArea: 'c' }}
        />
        <TextInput
          disabled
          label="Headcounts"
          value={staticData?.headcount || ''}
          sx={{ gridArea: 'd' }}
        />
        <TextInput disabled label="Seats" value={staticData?.seats || ''} sx={{ gridArea: 'e' }} />
        <div style={{ gridArea: 'f' }}>
          <InputLabel>Status</InputLabel>
          <Status
            color={PRIMARY_COLORS.PRIMARY_WHITE}
            backgroundColor={
              STATUS_COLORS[staticData?.status?.name] || PRIMARY_COLORS.PRIMARY_SILVER
            }
            sx={{ padding: '10px' }}
          >
            {staticData?.status?.label}
          </Status>
        </div>
      </div>
      <PageSpinner isOpen={onEdit.isLoading} />
    </div>
  );
};
