import { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { Close, Copy, AlertError, AlertInfo, AlertSuccess, AlertWarning } from '@icons/index';
import styles from './style.module.css';

export type AlertType = 'success' | 'error' | 'info' | 'warning';

export interface AlertProps {
  id: number;
  type: AlertType;
  title?: string;
  message: string | Error;
  onClose: (id: number) => void;
}

const titleMap: Record<AlertType, string> = {
  success: 'Success',
  error: 'Error',
  info: 'Info',
  warning: 'Warning',
};

const alertMap: Record<AlertType, JSX.Element> = {
  success: <AlertSuccess />,
  error: <AlertError />,
  info: <AlertInfo />,
  warning: <AlertWarning />,
};

const Alert = ({ id, type, title = titleMap[type], message, onClose }: AlertProps) => {
  const handleClose = () => onClose(id);

  const alertMsg = typeof message === 'string' ? message : errorParser(message);

  useEffect(() => {
    if (type === 'success') {
      const timer = setTimeout(() => {
        handleClose();
      }, id - Date.now());
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <section className={`${styles.wrapper} ${styles[type]}`}>
      <div className={styles.top}>
        {alertMap[type]}
        <h5 className={styles.title}>{title}</h5>
        <span onClick={handleClose} className={styles.close}>
          <Close />
        </span>
      </div>
      <p className={styles.msg}>
        <span>{alertMsg}</span>
        <LogID error={message} />
      </p>
    </section>
  );
};

const AlertWrapper = ({ children }: { children: JSX.Element[] }) => (
  <div className={styles.alertWrapper}>{children}</div>
);

export { Alert, AlertWrapper };

const LogID = ({ error }: { error: any }) => {
  if (!error?.response?.data?.log_id) return null;
  const clickHandler = async () => {
    await navigator.clipboard.writeText(error.response.data.log_id);
  };
  return (
    <span>
      Error ID# {error.response.data.log_id}
      <IconButton onClick={clickHandler} sx={{ padding: '2px 2px 4px 2px' }}>
        <Copy />
      </IconButton>
    </span>
  );
};

const errorParser = (e: any) => {
  let error = 'Something went wrong.';

  if (e?.response) {
    if (e.response.data) {
      error = e.response.data.message || e.response.data.error || e.response.data.details;
    }
  } else if (e?.request) {
    error = e.message;
  } else if (e.message) {
    error += ` ${e.message}`;
  }

  return error;
};
