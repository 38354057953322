import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { UserData, useClient } from '@hooks/useClientData';
import { Roles } from '../types';

interface ContextI {
  client: UserData | undefined;
  checkPermission: (perm: Roles | Roles[]) => boolean;
}

const hasPerm = (perm: Roles, userPerm?: Roles[]) => userPerm?.includes(perm);

export const ClientDataContext = createContext<ContextI>({
  client: undefined,
  checkPermission: () => false,
});

const { Provider } = ClientDataContext;

export const ClientDataProvider = ({ children }: { children: JSX.Element }) => {
  const [clientData, setClientData] = useState<UserData>();
  const { client } = useClient();

  const checkPermission = useCallback(
    (required?: Roles | Roles[]) => {
      if (!required) return true;
      const requiredPermsArr: Roles[] = Array.isArray(required) ? required : [required];
      return requiredPermsArr.some((perm: Roles) => hasPerm(perm, clientData?.userRolesList));
    },
    [clientData],
  );

  useEffect(() => {
    if (client) {
      setClientData(client);
    }
  }, [client]);

  return <Provider value={{ client: clientData, checkPermission }}>{children}</Provider>;
};

export const useClientData = () => {
  const context = useContext(ClientDataContext);
  return context;
};
