export const AlertSuccess = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.00012207C4.92525 0.00012207 0 4.92537 0 11.0001C0 17.0749 4.92525 22.0001 11 22.0001C17.0748 22.0001 22 17.0749 22 11.0001C22 4.92537 17.0748 0.00012207 11 0.00012207ZM11 2.75016C15.5499 2.75016 19.25 6.45028 19.25 11.0002C19.25 15.55 15.5499 19.2502 11 19.2502C6.45012 19.2502 2.75 15.55 2.75 11.0002C2.75 6.45028 6.45012 2.75016 11 2.75016ZM10.028 11.9721L14.8886 7.11152L16.8329 9.05577L10.028 15.8606L9.05589 14.8885L8.08376 13.9164L5.16739 11L7.11164 9.05577L10.028 11.9721Z"
      fill="#80BBAD"
    />
  </svg>
);

export const AlertError = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11.0001C22 17.0749 17.0748 22.0001 11 22.0001C4.92525 22.0001 0 17.0749 0 11.0001C0 4.92537 4.92525 0.00012207 11 0.00012207C17.0748 0.00012207 22 4.92537 22 11.0001ZM2.75 11.0001C2.75 15.55 6.45012 19.2501 11 19.2501C15.5499 19.2501 19.25 15.55 19.25 11.0001C19.25 6.45025 15.5499 2.75012 11 2.75012C6.45012 2.75012 2.75 6.45025 2.75 11.0001ZM9.625 5.50026V12.3753H12.375V5.50026H9.625ZM11 13.7503C11.7594 13.7503 12.375 14.3659 12.375 15.1253C12.375 15.8847 11.7594 16.5003 11 16.5003C10.2406 16.5003 9.625 15.8847 9.625 15.1253C9.625 14.3659 10.2406 13.7503 11 13.7503Z"
      fill="#AD2A2A"
    />
  </svg>
);

export const AlertInfo = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.0001C0 4.92537 4.92525 0.00012207 11 0.00012207C17.0748 0.00012207 22 4.92537 22 11.0001C22 17.0749 17.0748 22.0001 11 22.0001C4.92525 22.0001 0 17.0749 0 11.0001ZM19.25 11.0002C19.25 6.45028 15.5499 2.75016 11 2.75016C6.45012 2.75016 2.75 6.45028 2.75 11.0002C2.75 15.55 6.45012 19.2502 11 19.2502C15.5499 19.2502 19.25 15.55 19.25 11.0002ZM12.375 16.5V9.62502H9.625V16.5H12.375ZM11 8.25002C10.2406 8.25002 9.625 7.63441 9.625 6.87502C9.625 6.11563 10.2406 5.50002 11 5.50002C11.7594 5.50002 12.375 6.11563 12.375 6.87502C12.375 7.63441 11.7594 8.25002 11 8.25002Z"
      fill="#778F9C"
    />
  </svg>
);

export const AlertWarning = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8746 15.1251V9.62512C17.8746 8.64612 17.6642 7.718 17.2943 6.87512C16.4116 4.85662 14.5842 3.3565 12.3746 2.9055V1.37512C12.3746 0.614747 11.76 0.00012207 10.9996 0.00012207C10.2406 0.00012207 9.62459 0.614747 9.62459 1.37512V2.9055C7.41496 3.3565 5.58896 4.85662 4.70484 6.87512C4.33634 7.718 4.12459 8.64612 4.12459 9.62512V15.1251L2.74959 16.5001V17.8751H4.12459H17.8746H19.2496V16.5001L17.8746 15.1251ZM13.75 19.2501C13.75 20.7681 12.518 22.0001 11 22.0001C9.482 22.0001 8.25 20.7681 8.25 19.2501H13.75ZM6.91309 15.1251H15.0875C15.0924 15.1213 15.0974 15.117 15.1023 15.1127C15.1097 15.1063 15.1172 15.0998 15.1246 15.0949V9.62512C15.1246 8.565 14.7121 7.60662 14.0521 6.87512C13.2958 6.03775 12.2151 5.50012 10.9996 5.50012C9.78546 5.50012 8.70334 6.03775 7.94846 6.87512C7.28846 7.60662 6.87459 8.565 6.87459 9.62512V15.0949C6.88228 15.0995 6.8891 15.1054 6.89604 15.1114L6.89604 15.1114C6.9015 15.1161 6.90703 15.1209 6.91309 15.1251ZM0 13.7501H2.75V8.25012H0V13.7501ZM22 13.75H19.25V8.24998H22V13.75Z"
      fill="#D2785A"
    />
  </svg>
);
