import { Font, StyleSheet } from '@react-pdf/renderer';

import { PRIMARY_COLORS, SECONDARY_COLORS, PRIMARY_SILVER_COLOR } from '@constants/colors';

import FinancierSemiBold from '../../../fonts/FinancierDisplay/TestFinancierDisplay-Semibold.otf';
import FinancierSemiRegular from '../../../fonts/FinancierDisplay/TestFinancierDisplay-Regular.otf';

import CalibreLight from '../../../fonts/TestCalibre/Calibre-Light.otf';
import CalibreRegular from '../../../fonts/TestCalibre/Calibre-Regular.otf';
import CalibreMedium from '../../../fonts/TestCalibre/Calibre-Medium.otf';

const floorWidth = '540px';

Font.register({
  family: 'FinancierDisplayPdf',
  fonts: [
    {
      src: FinancierSemiRegular,
      fontWeight: 'normal',
    },
    {
      src: FinancierSemiBold,
      fontWeight: 'semibold',
    },
  ],
});

Font.register({
  family: 'CalibrePdf',
  fonts: [
    {
      src: CalibreLight,
      fontWeight: 'normal',
    },
    {
      src: CalibreRegular,
      fontWeight: 'medium',
    },
    {
      src: CalibreMedium,
      fontWeight: 'semibold',
    },
  ],
});

export const styles = StyleSheet.create({
  container: {
    padding: '40px',
    fontFamily: 'CalibrePdf',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.5,
    color: PRIMARY_COLORS.PRIMARY_BLACK,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  planContainer: {
    width: '50%',
    padding: '0 20px',
  },
  title: {
    fontSize: '28px',
    fontWeight: 600,
    fontFamily: 'FinancierDisplayPdf',
  },
  lockedEmpty: {
    backgroundColor: PRIMARY_COLORS.PRIMARY_SILVER,
  },
  floor: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '6px',
  },
  floorInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  floorMetaTop: {
    maxWidth: floorWidth,
    color: SECONDARY_COLORS.DARK_GREY,
    fontSize: '13px',
  },
  floorMetaLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    color: SECONDARY_COLORS.DARK_GREY,
  },
  floorMetaRight: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    color: PRIMARY_COLORS.PRIMARY_SILVER,
  },
  floorNumber: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginRight: '16px',
  },
  floorMetaRightItem: {
    display: 'flex',
  },
  floorPlate: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  floorFilling: {
    height: '36px',
    marginBottom: '6px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: PRIMARY_SILVER_COLOR[300],
    width: '100%',
  },
  teamOnFloor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamAmount: {
    fontWeight: 600,
    color: PRIMARY_COLORS.PRIMARY_WHITE,
    lineHeight: 1.2,
  },
  emptyFloor: {
    backgroundColor: PRIMARY_SILVER_COLOR[300],
    width: '100%',
    textTransform: 'uppercase',
  },
  emptyFloorText: {
    fontWeight: 500,
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    lineHeight: 1.2,
  },
  whiteEmptyText: {
    fontWeight: 500,
    color: PRIMARY_COLORS.PRIMARY_WHITE,
    lineHeight: 1.2,
  },
  status: {
    fontWeight: 400,
  },
  team: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    // justifyContent: 'center',
    marginRight: '24px',
    marginBottom: '6px',
  },
  teamColor: {
    backgroundColor: 'green',
    height: '36px',
    width: '8px',
    marginRight: '4px',
  },
  teamInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    lineHeight: 1.2,
    // alignItems: 'flex-end',
  },
  teamName: {
    fontSize: '16px',
    fontWeight: 600,
  },
  teamSize: {
    color: PRIMARY_COLORS.PRIMARY_SILVER,
  },

  unAllocatedRow: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  unallocatedTeams: {
    fontWeight: 600,
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    textTransform: 'uppercase',
    width: '100%',
  },

  metaContainer: {
    width: '100%',
    padding: '32px 32px 8px 32px',
    backgroundColor: PRIMARY_SILVER_COLOR[100],
    color: SECONDARY_COLORS.DARK_GREY,
    fontSize: '16px',
    fontWeight: 400,
  },
  metaContainerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '24px',
  },
  metaContainerItem: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  metaContainerLabel: {
    color: PRIMARY_COLORS.PRIMARY_GREEN,
    fontSize: '14px',
    fontWeight: 600,
  },

  pageNumber: {
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    fontSize: '13px',
    position: 'absolute',
    width: '100%',
    bottom: 10,
    left: 40,
  },
});
