import { combineReducers } from '@reduxjs/toolkit';
import mappingDataReducer from './mappingDataReducer';
import mappingReviewReducer from './mappingReviewReducer';
import opportunityStateReducer from './opportunityStateReducer';
import occupancyStateReducer from './occupancyStateReducer';
import uploadReducer from './uploadReducer';
import userDataReducer from './userData';

export default combineReducers({
  mappingReview: mappingReviewReducer,
  mappingData: mappingDataReducer,
  userData: userDataReducer,
  opportunityState: opportunityStateReducer,
  occupancyState: occupancyStateReducer,
  uploadState: uploadReducer,
});
