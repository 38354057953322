import { FC, useState } from 'react';
import { Button } from '../../components';
import CreateProjectSideBar from './CreateProjectSideBar';

const CreateProject: FC = () => {
  const [isOpenSidebar, setSidebarStatus] = useState(false);
  const toggleSideBar = () => setSidebarStatus((value) => !value);

  return (
    <>
      <Button onClick={toggleSideBar}>Create Project</Button>
      {isOpenSidebar && (
        <CreateProjectSideBar
          sideBarStatus={isOpenSidebar}
          toggleSideBar={toggleSideBar}
        />
      )}
    </>
  );
};

export default CreateProject;
