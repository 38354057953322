import ButtonMui from '@mui/material/Button';

// TODO:
// - add icon to button content
// - add icon button

export type ButtonProps = {
  type?: 'primary' | 'secondary';
  disabled?: boolean;
  children: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  endIcon?: React.ReactNode;
};

export const Button = ({
  type = 'primary',
  disabled = false,
  onClick,
  endIcon = null,
  children,
}: ButtonProps) => (
  <ButtonMui
    variant={type === 'primary' ? 'contained' : 'outlined'}
    onClick={onClick}
    disabled={disabled}
    endIcon={endIcon}
  >
    {children}
  </ButtonMui>
);
