import { Tabs as MUITabs, Tab } from '@mui/material';

interface Props {
  active: number;
  onChange: (x: number) => void;
  tabs: string[];
}

const Tabs = ({ onChange, active, tabs }: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: any) => onChange(newValue);

  return (
    <MUITabs value={active} onChange={handleChange} aria-label="Tabs">
      {tabs.map((tab) => (
        <Tab key={tab} label={tab} />
      ))}
    </MUITabs>
  );
};

export default Tabs;
