import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Table as MUITable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { OverflowTooltip } from '../OverflowTooltip';
import { ActionsButton, ActionsButtonProps, DataWithId } from './ActionsButton';
import { SortWrapper } from './SortWrapper';
import { FilterWrapper } from './FilterWrapper';
import { ColumnActions } from './ColumnActions';
import { Order, quickSort } from '../../utils/quickSort';

export type Column<T extends DataWithId> = {
  [columnName in keyof Partial<T>]: string;
};

type Filter<T> = {
  [columnName in keyof Partial<T>]?: Array<string | number>;
};

type TableProps<T extends DataWithId> = {
  data: T[];
  columns: Column<T>;
  emptyMsg?: string;
  config?: {
    columnWrapper?: {
      [columnName in keyof Partial<T>]: any;
    };
    columnRenderer?: {
      [columnName in keyof Partial<T>]: (value: any) => ReactNode;
    };
    filterBy?: {
      [columnName in keyof Partial<T>]: boolean;
    };
    activeFilters?: Filter<T>;
    sortBy?: {
      [columnName in keyof Partial<T>]: boolean;
    };
    styles?: {
      [columnName in keyof Partial<T>]: SxProps<Theme>;
    };
    defaultSortByColumn?: keyof Partial<T>;
    defaultColumnRenderer?: (value: any) => ReactNode;
  } & Partial<ActionsButtonProps<T>>;
};

export const Table = <T extends DataWithId>({
  data,
  columns,
  emptyMsg,
  config = {},
}: TableProps<T>) => {
  const {
    defaultColumnRenderer,
    columnRenderer,
    actions,
    styles,
    sortBy,
    filterBy,
    activeFilters,
    columnWrapper,
    defaultSortByColumn,
  } = config;

  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<keyof Partial<T> | null>(defaultSortByColumn || null);
  const [order, setOrder] = useState<Order>('desc');
  const [filter, setFilter] = useState<Filter<T>>(activeFilters || {});

  const handleOrder = (property: keyof Partial<T>, newOrder: Order) => {
    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleFilter = (key: keyof Filter<T>, value: Array<string | number>) => {
    setFilter((data: Filter<T>) => {
      const updatedData = { ...data };
      if (value.length) {
        updatedData[key] = value;
        searchParams.set(String(key), value.join(','));
        setSearchParams(searchParams);
      } else {
        delete updatedData[key];
        searchParams.delete(String(key));
        setSearchParams(searchParams);
      }
      return updatedData;
    });
  };

  const renderCell = useCallback(
    (columnName: string, value: any) => {
      if (columnRenderer && columnRenderer[columnName]) {
        return columnRenderer[columnName](value);
      }

      if (defaultColumnRenderer) {
        return defaultColumnRenderer(value);
      }

      return value;
    },
    [columnRenderer],
  );

  const handlePrepareSortValue = (value: any) =>
    orderBy === 'updated_at' ? new Date(value) : renderCell(orderBy as string, value);

  const filteredData = useMemo(() => {
    const activeFilters = Object.keys(filter);
    if (!activeFilters.length) return data;

    return data.filter((item: T) =>
      activeFilters.every((key: string) => filter[key]!.includes(item[key])),
    );
  }, [data, filter]);

  const sortedData = useMemo(() => {
    if (!(orderBy && order)) return filteredData;
    return quickSort(filteredData, orderBy, order, handlePrepareSortValue);
  }, [filteredData, orderBy, order, handlePrepareSortValue]);

  return (
    <MUITable>
      <TableHead>
        <TableRow>
          {Object.entries(columns).map(([columnName, label]) => (
            <TableCell key={columnName}>
              <FilterWrapper
                columnName={columnName}
                handleView={renderCell}
                selectedItems={filter[columnName]}
                enabled={!!filterBy?.[columnName]}
                data={data}
                onChange={handleFilter}
              >
                <SortWrapper
                  columnName={columnName}
                  enabled={!!sortBy?.[columnName]}
                  active={orderBy === columnName}
                  order={order}
                  onChange={handleOrder}
                >
                  {label.toString()}
                </SortWrapper>
              </FilterWrapper>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.length > 0 ? (
          sortedData.map((item: T, index: number) => (
            <TableRow key={String(item.id || index)}>
              {Object.keys(columns).map((columnName) => (
                <TableCell
                  key={String(columnName)}
                  sx={{
                    maxWidth: '100px',
                    ...(styles?.[columnName] || {}),
                  }}
                >
                  <ColumnActions
                    enabled={!!columnWrapper?.[columnName]}
                    row={item}
                    Wrapper={columnWrapper?.[columnName]}
                  >
                    <OverflowTooltip>{renderCell(columnName, item[columnName])}</OverflowTooltip>
                  </ColumnActions>
                </TableCell>
              ))}
              {actions && (
                <ActionsButton key={String(item.id)} id={item.id} actions={actions} row={item} />
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={Object.keys(columns).length} sx={{ padding: '57px 16px' }}>
              <Typography variant="body1" component="h1" align="center">
                {emptyMsg || 'No data to display'}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </MUITable>
  );
};
