import { IconButton } from '@components';
import { Copy } from '@icons/index';

export const CopyToClipboardIcon = ({ textToCopy }: { textToCopy: string }) => {
  const clickHandler = async () => {
    await navigator.clipboard.writeText(textToCopy);
  };

  return (
    <IconButton onClick={clickHandler}>
      <Copy />
    </IconButton>
  );
};
