export const Filter = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
    <g transform="translate(0,11) scale(0.003780,-0.003780)" fill="#435254" stroke="none">
      <path
        d="M129 2901 c-92 -29 -145 -125 -120 -218 8 -30 152 -221 546 -724
l534 -684 3 -575 3 -575 23 -37 c13 -20 40 -47 60 -60 35 -22 48 -23 253 -26
282 -4 327 8 370 102 17 38 19 78 19 606 l1 565 534 684 c394 503 538 694 546
724 20 76 -12 157 -79 199 l-37 23 -1315 2 c-723 1 -1327 -2 -1341 -6z"
      />
    </g>
  </svg>
);
