/* eslint-disable eqeqeq */
/* eslint-disable no-unsafe-optional-chaining */
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Typography, FormHelperText } from '@mui/material';
import { useUpdateTeamColor } from '@hooks/useProject';
import { SideBar, Button, TextInput, ConfirmationModal, ColorPicker } from '@components';
import { colors } from '@service/ColorGenerator';
import { editTeamDemandSchema } from '../../schemas';
import {
  StackViewState,
  Team,
  EditTeamDemandState,
  SumCapacity,
  ProjectWithData,
  ProjectTeam,
} from '../../types';
import { sidebar as styles } from './styles';

type StaticDataType = Record<string, SumCapacity | undefined>;
type NumericFields = 'workstations' | 'offices' | 'collaborativeSpace' | 'sharing_ratio';

interface Props {
  sideBarStatus: boolean;
  toggleSideBar: () => void;
  data: StackViewState;
  teamId: string;
  onSave: (v: EditTeamDemandState) => void;
}

interface ContentProps {
  initialTeamData: ProjectTeam | null;
  initialData: EditTeamDemandState;
  staticData: StaticDataType;
  onSave: (v: EditTeamDemandState) => void;
  onChange: (key: keyof EditTeamDemandState, value: string) => void;
}

export const ModifyTeamDemand: FC<Props> = ({
  sideBarStatus,
  toggleSideBar,
  data,
  teamId,
  onSave,
}: Props) => {
  const { projectId } = useParams();
  const updateTeamColor = useUpdateTeamColor(projectId!);
  const queryClient = useQueryClient();
  const [modified, setModified] = useState(new Set());
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const initialProjectData = queryClient.getQueryData(['project', projectId]) as ProjectWithData;
  const initialTeamData =
    initialProjectData?.teams?.find((item: ProjectTeam) => item.id === teamId) || null;

  const allocated = data.allocated.find((team: Team) => team.id === teamId);
  const unallocated = data.unallocated.find((team: Team) => team.id === teamId);
  const totalSum = data.teamData[teamId].original_resources;

  const note = data.notes.teams.find((item) => item.team_id === teamId)?.message || '';

  const initialFormData: EditTeamDemandState = {
    ...data.teamData[teamId],
    workstations: totalSum[1] || 0,
    offices: totalSum[2] || 0,
    collaborativeSpace: totalSum[3] || 0,
    note,
  };

  const staticData: StaticDataType = {
    allocated: allocated?.allocated,
    unallocated: unallocated?.unallocated,
  };

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);
  const handleShowConfirmationDialog = () => {
    if (modified.size) {
      toggleConfirmationDialog();
    } else {
      toggleSideBar();
    }
  };
  const handleModifyingCheck = (key: keyof EditTeamDemandState, value: string) => {
    const isModified = initialFormData[key] != value;
    setModified((state) => {
      const isPresent = state.has(key);
      if (isModified) {
        return isPresent ? new Set(state) : new Set(state.add(key));
      }
      state.delete(key);
      return new Set(state);
    });
  };
  const handleSave = (data: EditTeamDemandState) => {
    if (modified.has('color')) {
      updateTeamColor.mutateAsync({ color: data.color, teamId });
    }
    onSave(data);
  };

  return (
    <>
      <SideBar isOpen={sideBarStatus} onClose={handleShowConfirmationDialog}>
        <SidebarContent
          initialData={initialFormData}
          staticData={staticData}
          onSave={handleSave}
          onChange={handleModifyingCheck}
          initialTeamData={initialTeamData}
        />
      </SideBar>
      {isOpen && (
        <ConfirmationModal
          isOpen
          onCancel={toggleConfirmationDialog}
          onLeave={toggleSideBar}
          title="You have unsaved changes"
          message="Changes will be lost if you leave without saving."
          successText="Return to editing"
          errorText="Discard changes and leave"
        />
      )}
    </>
  );
};

const SidebarContent = ({
  initialData,
  onSave,
  staticData,
  onChange,
  initialTeamData,
}: ContentProps) => {
  const [formData, setFormData] = useState<EditTeamDemandState>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { allocated, unallocated } = staticData;

  const handleSave = () => {
    if (!Object.keys(errors).length) {
      onSave(formData);
    }
  };

  const handleChangeSharingRatio = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    if (/^\d+(\.\d{0,2})?$/.test(value)) {
      handleFormChange(e);
    }
  };

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      const affectSharingRatio = ['workstations', 'offices'].includes(id);
      const context = shouldAddDynamicValidation(id)
        ? handleValidationMap(id as NumericFields, formData, allocated)
        : undefined;
      setFormData((data: EditTeamDemandState) => ({ ...data, [id]: value }));
      onChange(id as keyof EditTeamDemandState, value);
      await editTeamDemandSchema.validateAt(id, { ...formData, [id]: value }, { context });

      if (affectSharingRatio) {
        await editTeamDemandSchema.validateAt(
          'sharing_ratio',
          { ...formData, [id]: value },
          {
            context: handleValidationMap('sharing_ratio', { ...formData, [id]: value }, allocated),
          },
        );
      }

      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        if (affectSharingRatio) {
          delete newState.sharing_ratio;
        }
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleSelectChange = (value: string) => {
    setFormData((data: EditTeamDemandState) => ({ ...data, color: value }));
    onChange('color' as keyof EditTeamDemandState, value);
  };

  return (
    <div style={styles.wrapper}>
      <Button onClick={handleSave}>Save</Button>
      <div style={styles.header}>
        <Typography variant="h1" component="h1">
          Team Demand
        </Typography>
        {initialTeamData && (
          <Typography variant="body2">
            <span style={styles.subtitle}>Initial Demand:</span>
            <p style={styles.info}>
              <span>
                Total Seats:{' '}
                {(initialTeamData.workstations || 0) + (initialTeamData.private_offices || 0)}
              </span>
              <span>Workstations: {initialTeamData.workstations || 0}</span>
              <span>Offices: {initialTeamData.private_offices || 0}</span>
              <span>Collaborative Seats: {initialTeamData.shared_offices || 0}</span>
            </p>
          </Typography>
        )}
      </div>
      <div style={{ ...styles.grid, ...styles.content }}>
        <div style={{ ...styles.grid, ...styles.gridTop }}>
          <TextInput
            id="name"
            label="Team Name"
            value={formData.name}
            onChange={handleFormChange}
            error={!!errors.name}
            helperText={errors.name}
          />
          <ColorPicker
            id="color"
            label="Team Color"
            baseColorList={colors}
            value={formData.color}
            onChange={handleSelectChange}
            labelTooltip="Selected color will be applied to this team within all plans in this project"
          />
        </div>
        <div style={{ ...styles.grid, ...styles.gridBottom }}>
          <TextInput
            id="workstations"
            label="Workstations"
            value={formData.workstations}
            onChange={handleFormChange}
            error={!!errors.workstations}
            type="number"
            InputProps={{ inputProps: { min: allocated?.[1] || 0 } }}
            sx={{ gridArea: 'a' }}
          />
          <TextInput
            id="offices"
            label="Offices"
            value={formData.offices}
            onChange={handleFormChange}
            error={!!errors.offices}
            type="number"
            InputProps={{ inputProps: { min: allocated?.[2] || 0 } }}
            sx={{ gridArea: 'b' }}
          />
          <TextInput
            id="collaborativeSpace"
            label="Collaborative Seats"
            value={formData.collaborativeSpace}
            onChange={handleFormChange}
            error={!!errors.collaborativeSpace}
            type="number"
            InputProps={{ inputProps: { min: allocated?.[3] || 0 } }}
            sx={{ gridArea: 'c' }}
          />
          <FormHelperText
            error={!!errors.workstations || !!errors.offices || !!errors.collaborativeSpace}
            sx={{ gridArea: 'd' }}
          >
            {errors.workstations || errors.offices || errors.collaborativeSpace}
          </FormHelperText>
        </div>
        <div style={styles.sharingRatio}>
          <TextInput
            id="sharing_ratio"
            label="Sharing Ratio"
            labelTooltip="Number of people sharing 1 seat allocated.
            For example, if you need 1 seat to be allocated for every 2 employees from the team (like if they are working in shifts), you can set Sharing Ratio to 2, meaning you'll allocate 1 seat for every 2 employees from Team Demand. You can always see the resulting value in Demand Summary below."
            labelSx={styles.label}
            value={formData.sharing_ratio}
            onChange={handleChangeSharingRatio}
            error={!!errors.sharing_ratio}
            type="number"
            InputProps={{ inputProps: { min: 0.1, step: 0.1 } }}
            sx={{ width: '115px' }}
          />
          <FormHelperText error={!!errors.workstations}>{errors.sharing_ratio}</FormHelperText>
          <div style={styles.sharingRatioResult}>
            <span style={styles.subtitle}>Demand Summary:</span>
            <p style={styles.info}>
              <span>
                Total Seats:{' '}
                {(+formData.workstations && +formData.sharing_ratio
                  ? Math.ceil(+formData.workstations / +formData.sharing_ratio)
                  : 0) +
                  (+formData.offices && +formData.sharing_ratio
                    ? Math.ceil(+formData.offices / +formData.sharing_ratio)
                    : 0)}
              </span>
              <span>
                Workstations:{' '}
                {+formData.workstations && +formData.sharing_ratio
                  ? Math.ceil(+formData.workstations / +formData.sharing_ratio)
                  : 0}
              </span>
              <span>
                Offices:{' '}
                {+formData.offices && +formData.sharing_ratio
                  ? Math.ceil(+formData.offices / +formData.sharing_ratio)
                  : 0}
              </span>
            </p>
          </div>
        </div>
        <TextInput
          id="note"
          label="Note"
          multiline
          minRows={4}
          value={formData.note}
          onChange={handleFormChange}
          error={!!errors.note}
          helperText={errors.note}
        />
      </div>
      <div style={styles.footer}>
        <Typography variant="body2">
          <span style={styles.subtitle}>Allocated:</span>
          <p style={styles.info}>
            <span>Total Seats: {allocated?.total || 0}</span>
            <span>Workstations: {allocated?.[1] || 0}</span>
            <span>Offices: {allocated?.[2] || 0}</span>
          </p>
        </Typography>
        <Typography variant="body2">
          <span style={styles.subtitle}>Unallocated:</span>
          <p style={styles.info}>
            <span>Total Seats: {unallocated?.total || 0}</span>
            <span>Workstations: {unallocated?.[1] || 0}</span>
            <span>Offices: {unallocated?.[2] || 0}</span>
          </p>
        </Typography>
      </div>
    </div>
  );
};

const shouldAddDynamicValidation = (key: string) =>
  ['workstations', 'offices', 'collaborativeSpace', 'sharing_ratio'].includes(key);

const handleValidationMap = (
  item: NumericFields,
  current: EditTeamDemandState,
  source?: SumCapacity,
) => {
  const validationMap: Record<NumericFields, any> = {
    workstations: { min: source?.[1] || 0 },
    offices: { min: source?.[2] || 0 },
    collaborativeSpace: { min: source?.[3] || 0 },
    sharing_ratio: calculateMinMaxSharingRatio(current, source),
  };
  return {
    ...validationMap[item],
    message:
      'You can only decrease team demand for within currently unallocated team scope. If you need to lower the demand beyond it, please go to the stack view and remove the team from the floor(s)',
  };
};

const calculateMinMaxSharingRatio = (current: EditTeamDemandState, source?: SumCapacity) => {
  const maxWorkstationSharingRatio =
    source?.[1] && source[1] > 0 ? calculateRoundedNumber(current.workstations / source?.[1]) : 0;
  const maxOfficesSharingRatio =
    source?.[2] && source[2] > 0 ? calculateRoundedNumber(current.offices / source?.[2]) : 0;
  const minValue = Math.min(
    ...[maxWorkstationSharingRatio, maxOfficesSharingRatio].filter((item: number) => item > 0),
  );
  return { ...(minValue && minValue !== Infinity ? { max: minValue } : {}) };
};

const calculateRoundedNumber = (number: number) =>
  parseFloat((Math.floor(number * 10) / 10).toFixed(1));
