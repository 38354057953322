import { useQuery, useMutation } from 'react-query';
import { useAlert } from '../context/AlertContext';
import { Adjacency, AdjacenciesSaveBody, TeamSchemaResponse } from '../types/adjacencies';
import { useApi } from './useApi';

export const useAdjacencies = (projectId: string) => {
  const api = useApi();
  const alert = useAlert();
  const URL = '/occupancy-planning/api/adj-form';

  const fetchAdjacencies = async () => {
    const response = await api.get<Adjacency[]>(URL, {
      params: {
        project_id: projectId,
      },
    });
    return response?.data;
  };

  const adjacencies = useQuery(['adjacencies', projectId], fetchAdjacencies, {
    enabled: !!projectId,
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });

  const saveAdjacenciesRequest = async ({
    project_id,
    customer_id,
    teams_adjacency,
  }: AdjacenciesSaveBody) =>
    api.post(URL, {
      project_id,
      customer_id,
      teams_adjacency,
    });

  const saveAdjacencies = useMutation(saveAdjacenciesRequest, {
    onSuccess() {
      alert('success', 'Adjacencies successfully saved');
      adjacencies.refetch();
    },
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });

  return {
    adjacencies,
    saveAdjacencies,
  };
};

export const useTeamsForAdjacencies = (projectId: string) => {
  const api = useApi();
  const alert = useAlert();
  const URL = '/occupancy-planning/api/schema/Adjacencies';

  const fetchTeamsForAdjacencies = async () => {
    const response = await api.get<TeamSchemaResponse>(URL, {
      params: {
        project_id: projectId,
      },
    });

    // The first element of the schema array is information only
    const parsedResponse = response?.data?.schema
      .slice(1)
      .map(({ label, key }) => ({ label, value: key }));
    return parsedResponse;
  };

  const teamsForAdjacencies = useQuery(
    ['teamsForAdjacencies', projectId],
    fetchTeamsForAdjacencies,
    {
      refetchOnMount: false,
      enabled: !!projectId,
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    },
  );

  return {
    teamsForAdjacencies,
  };
};
