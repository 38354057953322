import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledMUIIconButton = styled(MUIIconButton)<IconButtonProps>(({ theme }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const IconButton = ({ children, ...props }: IconButtonProps) => (
  <StyledMUIIconButton {...props}>{children}</StyledMUIIconButton>
);
