import { ReactElement, useState } from 'react';
import { MenuProps, SxProps } from '@mui/material';
import { Expand } from '@icons/index';
import { IconButton } from '../IconButton';
import Menu from './Menu';

interface Props extends Omit<MenuProps, 'open'> {
  menuItems: React.ReactNode;
  children: ReactElement;
  type?: 'default' | 'square';
  btnSx?: SxProps;
  withArrow?: boolean;
  disabled?: boolean;
}

const MenuByClick = ({
  id,
  disabled = false,
  menuItems,
  children,
  type = 'default',
  withArrow = false,
  btnSx = {},
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-label={id}
        aria-controls="project-row-actions"
        aria-haspopup="true"
        onClick={handleClick}
        type={type}
        sx={btnSx}
        disabled={disabled}
      >
        {children}
        {withArrow && !disabled && (
          <Expand style={{ transform: `rotate(${anchorEl ? '180' : '0'}deg)` }} />
        )}
      </IconButton>
      <Menu
        {...props}
        MenuListProps={{ onClick: handleClose }}
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default MenuByClick;
