import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import { Search as SearchIcon } from '@icons/index';

const Search = ({ ...props }: TextFieldProps) => (
  <TextField
    variant="standard"
    className="search"
    InputProps={{
      autoComplete: 'off',
      "aria-autocomplete": 'none',
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    fullWidth
    {...props}
  />
);

export default Search;
