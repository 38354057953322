import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  DialogContentText,
  SxProps,
  Box,
} from '@mui/material';
import { Close } from '@icons/index';
import { IconButton, Button, RadioGroup, PageSpinner } from '@components';

import { STRATEGIES_LIST } from '../../types';

export type ReneratePlanDialogProps = {
  selectedStrategy?: number;
  onChangeStrategy?: (value: any) => void;
  onClose: () => void;
  isOpen: boolean;
  onOverrideCurrenPlan: () => void;
  onGenerateNewPlan: () => void;
  isLoading?: boolean;
  isEditable: boolean;
};

const styles: Record<string, SxProps> = {
  dialogContainer: {
    maxWidth: '660px',
  },
  strategiesContainer: {
    width: '270px',
  },
  actionsContainer: {
    justifyContent: 'start',
  },
  overrideBtn: {
    flexGrow: 1,
  },
};

export const ReneratePlanDialog = ({
  selectedStrategy,
  onClose,
  isOpen,
  onOverrideCurrenPlan,
  onGenerateNewPlan,
  onChangeStrategy,
  isLoading = false,
  isEditable,
}: ReneratePlanDialogProps) => {
  // TODO: clarify the exact form of the dialog without strategies
  // PROPOSAL: maybe disable all radiobuttons except only first one chosen
  const renderStrategies = () => {
    if (selectedStrategy && onChangeStrategy) {
      return (
        <Container sx={styles.strategiesContainer}>
          <RadioGroup
            value={selectedStrategy}
            onChange={onChangeStrategy}
            options={STRATEGIES_LIST}
          />
        </Container>
      );
    }

    return null;
  };

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ sx: styles.dialogContainer }}>
      <DialogTitle>
        Regenerate Plan
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`If you've got unlocked teams, their assignments will be reshuffled according to the selected scenario. To preserve the current plan, just kick off the duplication process.`}
        </DialogContentText>
        {selectedStrategy && onChangeStrategy && (
          <DialogContentText>
            Choose from the available algorithm models to regenerate plan based on your specific
            requirements and preferences.
          </DialogContentText>
        )}
        {renderStrategies()}
      </DialogContent>
      <DialogActions sx={styles.actionsContainer}>
        <Box sx={styles.overrideBtn}>
          <Button
            type="secondary"
            onClick={onOverrideCurrenPlan}
            disabled={isLoading || !isEditable}
          >
            Override Current Plan
          </Button>
        </Box>
        <Button type="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onGenerateNewPlan} disabled={isLoading}>
          Generate New Plan
        </Button>
      </DialogActions>
      <PageSpinner isOpen={isLoading} />
    </Dialog>
  );
};
