import { Typography } from '@mui/material';

interface Props {
  number: number;
  label: string;
  plural: string;
}

const ItemsNumber = ({ number, label, plural }: Props) => (
  <Typography variant="body2" component="span">
    {number} {number !== 1 ? plural : label}
  </Typography>
);

export default ItemsNumber;
