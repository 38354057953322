import { FC } from 'react';
import {
  Box,
  Select as MUISelect,
  SelectProps,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { SelectOption } from '../../types/general';

interface Props extends SelectProps {
  options: SelectOption<string | number, string | JSX.Element>[];
  helperText?: string;
}

const Select: FC<Props> = ({
  id,
  required,
  label,
  error,
  helperText,
  options,
  ...props
}: Props) => (
  <Box sx={{ width: '100%' }}>
    <InputLabel htmlFor={id}>
      {label}
      {required ? ' *' : ''}
    </InputLabel>
    <MUISelect id={id} fullWidth error={error} {...props}>
      {options?.map((option: SelectOption<string | number, string | JSX.Element>) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </Box>
);

export default Select;
