/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
  },
  customer: { customerId: null, customerName: '' },
  customers: [],
  schemaId: 'Building',
  title: 'Home',
  selectedSchema: [],
  capabilities: [],
  userFlags: {
    shouldReloadProjectList: false,
  },
};

export const userSelector = (state) => state.userData.user;
export const capabilitiesSelector = (state) => state.userData.capabilities;
export const getCustomerId = (state) => state.userData.customer.customerId;
export const customerNameSelector = (state) => state.userData.customer.customerName;
export const customersSelector = (state) => state.userData.customers;
export const customersHashMapSelector = (state) =>
  state.userData.customers.reduce((acc, customer) => {
    acc[customer.customerId] = customer.customerName;
    return acc;
  }, {});

const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      const { user, capabilities, customer } = payload;
      state.user = {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        roles: user.roles.map((role) => role.roleName),
      };
      state.customer = customer;
      state.customers = user.customers;
      state.capabilities = capabilities;
    },
    updateSelectedSchema: (state, { payload }) => {
      state.selectedSchema = payload;
    },
    updateCustomer: (state, { payload }) => {
      state.customer = payload;
    },
    updateSchemaId: (state, { payload }) => {
      state.schemaId = payload;
    },
    updateTitle: (state, { payload }) => {
      if (payload === '' || !payload) {
        state.title = 'Home';
      }
      state.title = payload;
    },
    updateUserFlags: (state, { payload }) => {
      state.userFlags = { ...state.userFlags, ...payload };
    },
  },
});

export const {
  setUser,
  updateTitle,
  updateSchemaId,
  updateCustomer,
  updateSelectedSchema,
  updateUserFlags,
} = userSlice.actions;

export default userSlice.reducer;
