/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSite: {
    id: 1,
    site_name: 'Site-1',
    headcount: 2601,
    seats: 3675,
    stages_id: 7,
    buildings: 2,
    sqft: 7120,
  },
  planDetails: {},
  highlightedTeam: null,
};

export const MAX_LVL_OF_VARIATIONS = 5;
export const MAX_COUNT_OF_FLOORS = 4;
export const MAX_COUNT_OF_BUILDINGS = 2;

const name = 'occupancyState';

const occupancyStateSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setPlanDetails: (state, action) => {
      // const constraints = state.selectedSite.site_constraints;
      const planDetails = action.payload;

      state.planDetails = planDetails;

      const buildings = Object.entries(planDetails.buildings);
      const { site_constraints } = planDetails.options;
      let currentIdx = 1;

      // setting up for each team unique index that we will use for drag & drop
      buildings.forEach(([buildingName, data]) => {
        state.planDetails.buildings[buildingName].state_dict.floors = data.state_dict.floors.map((floor) => {
          const teams = Object.entries(floor.teams);
          const updatedFloor = {
            ...floor,
            teams: {
              ...floor.teams,
            },
          };

          teams.forEach(([teamName, teamDetails]) => {
            updatedFloor.teams[teamName] = {
              ...teamDetails,
              id: currentIdx,
              floor: floor.name,
              buildingName,
              isAllocated: true,
              isUniversalLock: false,
              isLocked: false,
            };
            currentIdx++;
          });

          return updatedFloor;
        });
      });

      site_constraints?.data?.forEach((details) => {
        // if we don't have isLock it means that there is universal lock,
        // otherwise we can use this variable as boolean value if local lock is active or not
        // eslint-disable-next-line
        const [teamName, size, typeOfSeats, buildingName, teamFloor, isLock] = details;

        const building = state.planDetails.buildings[buildingName];

        if (!building) return;

        const { floors } = building;
        const isUniversalLock = typeof isLock === 'undefined';

        const updatedTeams = floors.map((floor) => {
          if (Number(floor.name) === Number(teamFloor)) {
            return {
              ...floor,
              teams: {
                ...floor.teams,
                [teamName]: {
                  ...floor.teams[teamName],
                  isUniversalLock,
                  isLocked: isUniversalLock ? false : isLock,
                },
              },
            };
          }

          return floor;
        });

        state.planDetails.buildings[buildingName].state_dict.floors = updatedTeams;
      });
    },
    updateLocking: (state, action) => {
      const {
        building,
        floor,
        name: teamName,
      } = action.payload;

      const { floors } = state.planDetails.buildings[building].state_dict;

      state.planDetails.buildings[building].state_dict.floors = floors.map((f) => {
        if (Number(f.name) === Number(floor)) {
          return {
            ...f,
            teams: {
              ...f.teams,
              [teamName]: {
                ...f.teams[teamName],
                isLocked: !f.teams[teamName].isLocked,
              },
            },
          };
        }

        return f;
      });
    },
    updateBuldingFloors: (state, action) => {
      const { buildingName, floors } = action.payload;

      state.planDetails.buildings[buildingName].state_dict.floors = floors;
    },
    setHighlightedTeam: (state, action) => {
      state.highlightedTeam = action.payload;
    },
    resetOccupancyState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
    updateBuildings: (state, action) => {
      const buildings = action.payload;
      state.planDetails.buildings = buildings;
    },
    updatePlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
  },
});

export const {
  setPlanDetails,
  updateBuildings,
  setSelectedSite,
  resetOccupancyState,
  updateBuldingFloors,
  updatePlanDetails,
  updateLocking,
  setHighlightedTeam,
} = occupancyStateSlice.actions;

export const getOccupancyState = (state) => state.occupancyState;

export default occupancyStateSlice.reducer;
