import PropTypes from 'prop-types';
import { Flatfile } from '@flatfile/sdk';
import { useSelector } from 'react-redux';
import { Button } from '@components';
import { userSelector } from '../../redux/reducers/userData';
import { getFlatfileToken } from '../../service/appService';
import './Upload.css';

const Upload = ({ onSubmitData, schemaName, org }) => {
  const user = useSelector(userSelector);

  const userInfo = {
    user: {
      id: user.userId,
      email: user.email,
    },
    org,
    schemaName,
  };

  const importData = () =>
    Flatfile.requestDataFromUser({
      token: async () => {
        const {
          data: { token },
        } = await getFlatfileToken({ data: userInfo });
        return token;
      },
      onData(chunk, next) {
        next();
      },
      async onComplete(result) {
        onSubmitData(result);
      },
      onError(error) {
        console.error(error);
      },
    });

  return (
    <Button disabled={!schemaName} onClick={importData}>
      Choose File
    </Button>
  );
};

Upload.propTypes = {
  schemaName: PropTypes.string,
  onSubmitData: PropTypes.func,
  org: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};
Upload.defaultProps = {
  org: { id: '', name: '' },
  schemaName: '',
  onSubmitData: () => {},
};

export default Upload;
