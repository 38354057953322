import { createContext, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useApi } from '@hooks/useApi';

export const AxiosContext = createContext(null);

const { Provider } = AxiosContext;

export const AxiosProvider = ({ children }: { children: JSX.Element }) => {
  const api = useApi();

  const handleServerError = (error: AxiosError) => {
    const status: number = error.response ? error.response?.status : 0;
    if (status >= 500 && status <= 599) {
      console.log('We have an internal problem. Request failed');
    }
    if (status === 404) {
      window.location.replace('/404');
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    api.interceptors.response.use((response) => response, handleServerError);
  }, []);

  return <Provider value={null}>{children}</Provider>;
};
