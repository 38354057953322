import { createContext, useContext, useState } from 'react';
import { Alert, AlertWrapper, AlertType, AlertProps } from '@components';

type AlertDataType = Omit<AlertProps, 'onClose'>;

export const AlertContext = createContext((type: AlertType, message: string, title?: string) => {});

const { Provider } = AlertContext;

export const AlertProvider = ({ children }: { children: JSX.Element }) => {
  const [alerts, setAlerts] = useState<AlertDataType[]>([]);

  const createAlert = (type: AlertType, message: string | Error, title?: string) => {
    setAlerts((state) => [
      ...state,
      {
        type,
        title,
        message: message || '',
        id: Date.now() + 5000, // alert life time
      } as AlertDataType,
    ]);
  };
  const removeAlert = (id: number) =>
    setAlerts((state) => state.filter((alert: AlertDataType) => alert.id !== id));

  return (
    <Provider value={createAlert}>
      {children}
      <AlertWrapper>
        {alerts.map((alert: AlertDataType) => (
          <Alert key={alert.id} {...alert} onClose={removeAlert} />
        ))}
      </AlertWrapper>
    </Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  return context;
};
