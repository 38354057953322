import { CSSProperties } from 'react';
import Box from '@mui/material/Box';

export type StatusProps = {
  color: string;
  backgroundColor: string;
  children: React.ReactNode;
  sx?: CSSProperties;
};

// TODO: make this component more reusable and put to @components
export const Status = ({ color, backgroundColor, children, sx = {} }: StatusProps) => (
  <Box
    sx={{
      color,
      backgroundColor,
      borderRadius: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px 12px',
      fontSize: '13px',
      width: 'fit-content',
      ...sx,
    }}
  >
    {children}
  </Box>
);
