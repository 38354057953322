import PropTypes from 'prop-types';
import {
} from '../../redux/reducers/uploadReducer';
import { Completed, Default, Disabled } from '../SeatAllocation/SiteUpload/siteIcons';

const UploadTrackerBlock = (props) => {
  const {
    required, completed, name, disabled,
  } = props;

  return (
    <div className="upload-tracker-block">
      <div className="circle-container">
        {
           // eslint-disable-next-line no-nested-ternary
            completed ? <Completed /> : (disabled ? <Disabled /> : <Default />)
         }
      </div>
      <p className="font-subtitle-1-bold">{name}</p>
      <p className="font-body-2">{required ? 'Required' : 'Optional' }</p>
    </div>
  );
};

UploadTrackerBlock.propTypes = {
  required: PropTypes.bool,
  completed: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

UploadTrackerBlock.defaultProps = {
  required: false,
  completed: false,
  name: '',
  disabled: false,
};

export default UploadTrackerBlock;
