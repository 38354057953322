import { Plan } from 'types';

export const findPlanOfRecordName = (plans: Plan[]) => {
  if (plans?.length > 0) {
    const planOfRecord = plans.find((plan) => plan.is_plan_of_record);

    if (planOfRecord) {
      return { name: planOfRecord.name, id: planOfRecord.id };
    }
  }

  return null;
};

export const displayFreeFloors = (value: null | undefined | string | number) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }
  return value;
} 