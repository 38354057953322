import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Typography,
  LinearProgressProps,
  LinearProgress,
} from '@mui/material';
import { Close } from '@icons/index';
import { IconButton, Button } from '@components';

export type GenerateInProgressDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onRedirect: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          // eslint-disable-next-line react/destructuring-assignment
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const GenerateInProgressDialog = ({
  isOpen,
  onClose,
  isLoading,
  isSuccess,
  isError,
  onRedirect,
}: GenerateInProgressDialogProps) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    const maxTime = 80; // 80 seconds, 1.2 minutes
    const tick = 1; // 3 seconds
    const updateRate = maxTime / tick;
    const step = 100 / updateRate;
    const timer = setInterval(() => {
      // never show 100% if still waiting the answer from BE
      // show 99% in that case
      setProgress((prevProgress) => (prevProgress + step <= 99 ? prevProgress + step : 99));
    }, 3000);

    let timer2: NodeJS.Timeout | undefined;

    if (isLoading === false && isSuccess && progress > 0) {
      clearInterval(timer);
      timer2 = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 10 : 100));
      }, 100);
    }

    if (progress === 100) {
      if (timer2) {
        clearInterval(timer2);
      }
      setTimeout(() => {
        onRedirect();
      }, 2000);
    }

    if (isLoading === false && isError && progress > 0) {
      clearInterval(timer);
      if (timer2) {
        clearInterval(timer2);
      }
      onClose();
    }

    return () => {
      clearInterval(timer);
      if (timer2) {
        clearInterval(timer2);
      }
    };
  }, [isLoading, isSuccess, isError, onRedirect, progress, onClose]);

  const handleNavigate = () => navigate('/v2/projects');

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Generate Occupancy Plan(s)
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Smart Occupancy Plan Generation tool is
          processing the Space data and Teams data you uploaded, as well as team adjacency
          requirements in order to generate most optimal initial versions of the Occupancy Plan
          matching the inputs you provided. Once the plans are generated, you will be able to adjust
          those as needed.`}
        </DialogContentText>
        <DialogContentText>
          {`This process may take up to a minute, please be patient and wait here so that we'll
          redirect you to the list of plans once everything is ready. Also, you can return to the
          project list and find your project updated with the new plans being added later.`}
        </DialogContentText>
        <LinearProgressWithLabel value={progress} />
      </DialogContent>
      <DialogActions>
        {/* TODO: discuss this behavious below */}
        <Button onClick={handleNavigate} disabled={!isLoading}>
          Back to Projects
        </Button>
      </DialogActions>
    </Dialog>
  );
};
