export const Search = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2151 9.473L16.3341 14.592L14.9201 16.006L9.80112 10.887C8.82012 11.585 7.62413 12 6.32812 12C3.01513 12 0.328125 9.313 0.328125 6C0.328125 2.687 3.01513 0 6.32812 0C9.64112 0 12.3281 2.687 12.3281 6C12.3281 7.296 11.9131 8.492 11.2151 9.473ZM6.32812 2C4.12313 2 2.32812 3.794 2.32812 6C2.32812 8.205 4.12313 10 6.32812 10C8.53413 10 10.3281 8.205 10.3281 6C10.3281 3.794 8.53413 2 6.32812 2Z"
      fill="#778F9C"
    />
  </svg>
);
