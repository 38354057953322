import { setUser } from '../redux/reducers/userData';
import { parsedErrorMessage } from '../service';
import { getUserByEmail } from '../service/appService';

const dedupCapabilities = (roles) => {
  if (roles.length === 0) return [];
  let userCapabilities = [];
  roles.forEach((role) => {
    const { capabilities } = role;
    userCapabilities = capabilities.reduce((total, { capabilityName }) => {
      if (total.includes(capabilityName)) {
        return [...total];
      }
      return [...total, capabilityName];
    }, userCapabilities);
  });
  return userCapabilities;
};

const authenticateUser = () => async (dispatch) => {
  try {
    const response = await getUserByEmail();
    if (response && response.status === 200) {
      const user = response.data;
      if (user) {
        const { customers, roles } = user;
        // dedup and collect capabilities
        const capabilities = dedupCapabilities(roles);
        // check for active customer, otherwise send the first
        const customerOnSession =
          JSON.parse(sessionStorage.getItem('customer')) &&
          customers?.find(
            (res) => res.customerId === JSON.parse(sessionStorage.getItem('customer')).customerId,
          );
        const customer = customerOnSession || customers?.[0];
        dispatch(
          setUser({
            user,
            capabilities,
            customer,
          }),
        );
        const userRolesList = user.roles.map((role) => role.roleName);
        return Promise.resolve({ capabilities, user, userRolesList, customer });
      }
      return Promise.reject(new Error('No user found'));
    }
  } catch (e) {
    return Promise.reject(new Error(parsedErrorMessage(e)));
  }
  return Promise.reject(new Error('Failed Login'));
};

export { authenticateUser, dedupCapabilities };
