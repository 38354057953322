export const Locked = ({ width = '11', height = '14', color = 'white' }: any) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.2399V3.3362C9 1.85712 7.30206 0.653809 5.5 0.653809C3.69794 0.653809 2 1.85712 2 3.3362V5.2399H0V13.3461H11V5.2399H9ZM5.5 1.4389C4.22537 1.4389 3 2.29002 3 3.3362V5.2399H8V3.3362C8 2.29002 6.77463 1.4389 5.5 1.4389Z"
      fill={color}
    />
  </svg>
);
