import { createTheme } from '@mui/material';

import { PRIMARY_COLORS, SECONDARY_COLORS, PRIMARY_SILVER_COLOR } from '@constants/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLORS.PRIMARY_GREEN,
      dark: SECONDARY_COLORS.DARK_GREY,
      contrastText: PRIMARY_COLORS.PRIMARY_WHITE,
    },
    secondary: {
      main: PRIMARY_COLORS.PRIMARY_WHITE,
      dark: PRIMARY_COLORS.PRIMARY_SILVER,
      contrastText: SECONDARY_COLORS.DARK_GREY,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '8px 16px',
          minWidth: 112,
          borderRadius: 0,
          '&.Mui-disabled': {
            color: PRIMARY_SILVER_COLOR[600],
            backgroundColor: PRIMARY_SILVER_COLOR[100],
            border: `1px solid ${PRIMARY_SILVER_COLOR[300]}`,
          },
          '& .MuiButton-endIcon .MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
          },
        },
        outlined: {
          color: SECONDARY_COLORS.DARK_GREY,
          borderColor: PRIMARY_SILVER_COLOR[400],
          fontWeight: 500,
          '&:hover': {
            backgroundColor: PRIMARY_SILVER_COLOR[150],
            borderColor: PRIMARY_SILVER_COLOR[250],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: '0.5',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: PRIMARY_COLORS.PRIMARY_WHITE,
          },
          '&:nth-of-type(odd)': {
            backgroundColor: PRIMARY_SILVER_COLOR[100],
          },
          '&:hover': {
            backgroundColor: '#eeeeed',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          color: SECONDARY_COLORS.DARK_GREY,
          padding: '0',
          border: 'none',
          '& a': {
            textDecoration: 'none',
            color: SECONDARY_COLORS.DARK_GREY,
            padding: '24px 16px',
            width: '100%',
            display: 'block',
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
          },
        },
        head: {
          padding: '6px 16px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& th': {
            backgroundColor: PRIMARY_COLORS.PRIMARY_WHITE,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& div': {
            fontSize: '20px',
            padding: '6px 12px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& div': {
            borderRadius: 0,
            '&.Mui-error input': {
              color: PRIMARY_COLORS.PRIMARY_RED,
            },
            '&.Mui-error textarea': {
              color: PRIMARY_COLORS.PRIMARY_RED,
            },
            '& input': {
              fontSize: '20px',
              padding: '6px 12px',
              color: PRIMARY_COLORS.PRIMARY_BLACK,
            },
            '&.Mui-disabled': {
              backgroundColor: PRIMARY_SILVER_COLOR[100],
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.search &': {
            borderRadius: '20px',
            paddingLeft: '10px',
            backgroundColor: PRIMARY_SILVER_COLOR[200],
            '&:hover': {
              border: 'none',
              '&::before': {
                border: 'none !important',
              },
            },
            '&::before': {
              border: 'none',
            },
            '&::after': {
              border: 'none',
            },
            '& input': {
              border: 'none',
              fontSize: '16px',
              lineHeight: '24px',
              paddingLeft: 0,
              color: PRIMARY_SILVER_COLOR[500],
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          lineHeight: '20px',
          letterSpacing: '0.5px',
          margin: 0,
          '&.Mui-error': {
            color: PRIMARY_SILVER_COLOR[1000],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: PRIMARY_SILVER_COLOR[500],
          fontSize: '13px',
          lineHeight: '20px',
          letterSpacing: '0.5px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          lineHeight: '28px',
          cursor: 'pointer',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': { width: '5px' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: PRIMARY_COLORS.PRIMARY_SILVER,
            borderRadius: '10px',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '500px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '24px',
        },
        textColorPrimary: {
          color: PRIMARY_SILVER_COLOR[500],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          zIndex: 2000,
          fontSize: '16px',
          lineHeight: '24px',
          background: PRIMARY_SILVER_COLOR[900],
          borderRadius: 0,
          '& > div': {
            maxHeight: '250px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '5px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: PRIMARY_SILVER_COLOR[300],
              borderRadius: '10px',
            },
          },
        },
        arrow: {
          color: PRIMARY_SILVER_COLOR[900],
        },
        popper: {
          zIndex: '2000',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '24px',
          '& a': {
            textDecoration: 'none',
            color: PRIMARY_SILVER_COLOR[500],
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '32px',
          lineHeight: '40px',
          fontWeight: 600,
          fontFamily: 'FinancierDisplay',
          color: PRIMARY_COLORS.PRIMARY_GREEN,
          padding: '24px',
          '& button.MuiIconButton-root': {
            position: 'absolute',
            right: 8,
            top: 8,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 400,
          fontFamily: 'Calibre',
          color: SECONDARY_COLORS.DARK_GREY,
          padding: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          paddingBottom: '24px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '1600',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& [data-testid="RadioButtonUncheckedIcon"]': {
            color: PRIMARY_COLORS.PRIMARY_SILVER,
          },
          '& [data-testid="RadioButtonCheckedIcon"]': {
            color: PRIMARY_COLORS.PRIMARY_ACCENT_GREEN,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1400',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: '1500',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '24px',
          color: PRIMARY_SILVER_COLOR[500],
          fontWeight: 500,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Calibre',
    fontWeightRegular: 400,

    h1: {
      fontFamily: 'FinancierDisplay',
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '52px',
      color: PRIMARY_COLORS.PRIMARY_GREEN,
    },
    h2: {
      fontFamily: 'FinancierDisplay',
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '32px',
      color: PRIMARY_COLORS.PRIMARY_GREEN,
    },
    h5: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: PRIMARY_COLORS.PRIMARY_GREEN,
    },
    h6: {
      fontFamily: 'FinancierDisplay',
    },
    body1: {
      fontSize: '20px',
      lineHeight: '28px',
      color: SECONDARY_COLORS.DARK_GREY,
    },
    body2: {
      fontSize: '16px',
      lineHeight: '24px',
      color: PRIMARY_COLORS.PRIMARY_SILVER,
    },
    button: {
      fontSize: '18px',
      lineHeight: '24px',
      textTransform: 'none',
    },
  },
});
