import React, { useState } from 'react';

import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { MenuDots } from '@icons/index';
import { Menu } from '../Menu';

export type ActionConfig = {
  color?: string;
  disabled?: boolean;
  dividerAfter?: boolean;
};

export type Action<T> = {
  label: string;
  callback: (rowId: string | number, row: T) => void;
  config?: ActionConfig;
};

export type ActionsButtonProps<T> = {
  actions: Action<T>[];
  id: string | number;
  row: T;
};

export type DataWithId = {
  id: number | string;
  [key: string]: any;
};

export const ActionsButton = <T extends DataWithId>({
  actions = [],
  id,
  row,
}: ActionsButtonProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const renderActions = () =>
    actions.map(({ label, callback, config }) => {
      let menuItemProps: any = {
        key: { label },
        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          callback(id, row);
          handleClose();
        },
      };

      if (config?.color) {
        menuItemProps = { ...menuItemProps, sx: { color: config.color } };
      }
      return (
        <div key={label}>
          <MenuItem {...menuItemProps} disabled={config?.disabled}>
            {label}
          </MenuItem>
          {config?.dividerAfter && <Divider sx={{ width: '72%', margin: 'auto' }} />}
        </div>
      );
    });

  return (
    <TableCell sx={{ float: 'right', maxWidth: '72px', position: 'relative', padding: '18px 0' }}>
      <IconButton
        aria-label="row-actions"
        aria-controls="project-row-actions"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuDots />
      </IconButton>
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ padding: '16px' }}
      >
        {renderActions()}
      </Menu>
    </TableCell>
  );
};
