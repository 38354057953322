export const Edit = ({ color = 'white' }: any) => (
  <svg width="16" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9996 2.001L12.9996 0L16.0006 3.001L14.0006 5L10.9996 2.001ZM0 16.0005V13.0005L10 3.0005L13 6.0005L3 16.0005H0Z"
      fill={color}
    />
  </svg>
);
