import { Tooltip as MUITooltip } from '@mui/material';

interface Props {
  position?: 'top' | 'bottom' | 'top-start' | 'top-end';
  tooltip?: any;
  children: any;
}

const Tooltip = ({ position = 'top', tooltip, children }: Props) => (
  <MUITooltip
    placement={position}
    title={<div>{tooltip || (typeof children !== 'object' && children)}</div>}
    arrow
  >
    {typeof children !== 'object' ? (
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'block',
        }}
      >
        {children}
      </span>
    ) : (
      children
    )}
  </MUITooltip>
);
export default Tooltip;
