/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { DropResult } from 'react-beautiful-dnd';
import { ColorGenerator } from '@service/ColorGenerator';
import { Order } from '@utils/quickSort';
import {
  SumCapacity,
  TeamData,
  DataType,
  Team,
  Building,
  Floor,
  StackViewState,
  FloorItem,
  EditTeamDemandState,
  TeamAllocateState,
  PlanDetailsResponse,
  PlanDetails,
  TeamItem,
  FloorPartialLock,
  EditFloorData,
  FloorNote,
  Note,
} from '../../types';

enum SeatsType {
  'workstations' = 1,
  'offices' = 2,
  'collaborativeSpace' = 3,
}

type SourceType = 'allocated' | 'unallocated';

export const handlePlanDetailsResponse = (data: PlanDetails) => {
  const groupedData = grouping(data.solution.teams);
  return {
    name: data.name,
    description: data.description,
    details: {
      ...totalSeats(data.private_offices, data.workstations),
      offices: data.private_offices.allocated,
      workstations: data.workstations.allocated,
    },
    ...groupedData,
    ...handlePrepareBuildings(data.solution.buildings),
    strategy: data.strategy,
    solution: data.solution,
    id: data.id,
    is_plan_of_record: data.is_plan_of_record,
    seats: data.seats,
    workstations: data.workstations,
    private_offices: data.private_offices,
    population: data.population,
    updated_at: data.updated_at,
    updated_by: data.updated_by,
    free_floors: data.free_floors,
    adj_value: data.adj_value,
    notes: data.notes,
  } as PlanDetailsResponse;
};

const handlePrepareBuildings = (arr: Building[]) => {
  const buildings = structuredClone(arr);
  const result: DataType = {};
  for (let i = 0; i < arr.length; i++) {
    for (const floor in arr[i].floors) {
      result[arr[i].floors[floor].id] = { buildingIdx: i, floorIdx: floor };
      buildings[i].floors[floor].allocated_resources = arr[i].floors[
        floor
      ].allocated_resources.filter((item: FloorItem) => item.type !== 3); // manual cleaning from collaborative seats
    }
  }
  return {
    buildings,
    buildingData: result,
  };
};

export const totalSeats = (offices: SumCapacity, workstations: SumCapacity) => ({
  allocated: offices.allocated + workstations.allocated,
  total: offices.total + workstations.total,
});

export const sumHelper = <T extends DataType>(arr: T[] = []) => {
  const result: SumCapacity = {};
  for (let i = 0; i < arr.length; i++) {
    result[arr[i].type] = result[arr[i].type]
      ? result[arr[i].type] + arr[i].capacity
      : arr[i].capacity;
  }
  let total: number = 0;
  Object.keys(result).forEach((i: string) => {
    if (i !== '3') {
      total += result[i];
    }
  });
  result.total = total;
  return result as SumCapacity;
};

export const groupByTeam = <T extends DataType>(arr: T[]) => {
  if (!arr) return {};
  const result: DataType = {};
  for (let i = 0; i < arr.length; i++) {
    result[`${arr[i].team}`] = {
      ...(result[arr[i].team] || arr[i]),
      [arr[i].type]: arr[i].capacity,
      locked: result[arr[i].team]?.locked || arr[i].locked,
      total: result[arr[i].team]?.total
        ? result[arr[i].team].total + arr[i].capacity
        : arr[i].capacity,
    };
    delete result[arr[i].team].type;
    delete result[arr[i].team].capacity;
  }
  return result;
};

const getAllocatedResources = (
  resources: SumCapacity,
  unallocated: SumCapacity,
): SumCapacity | null => {
  const result: SumCapacity = {};
  for (const key in resources) {
    if (key !== '3') {
      // manual cleaning from collaborative seats
      if (unallocated[key]) {
        const diff = resources[key] - unallocated[key];
        if (diff) result[key] = diff;
      } else {
        result[key] = resources[key];
      }
    }
  }
  return Object.keys(result).length ? (result as SumCapacity) : null;
};

const grouping = <T extends DataType>(arr: T[]) => {
  const colorHelper = new ColorGenerator();
  const allocated: Team[] = [];
  const unallocated: Team[] = [];
  const teamData: TeamData = {};
  const colorList = colorHelper.getColors(arr.length);

  for (let i = 0; i < arr.length; i++) {
    const resources = sumHelper(arr[i].resources);
    const original_resources = sumHelper(arr[i].original_resources || arr[i].resources);
    const totalUnallocated = sumHelper(
      arr[i].unallocated_resources.filter((item: any) => item.type !== 3),
    ); // manual cleaning from collaborative seats
    const allocatedResources = getAllocatedResources(resources, totalUnallocated);

    const { id, color, unallocated_resources, sharing_ratio, ...rest } = arr[
      i
    ] as unknown as TeamItem & { unallocated_resources: any[] };

    teamData[id] = {
      id,
      ...rest,
      color: arr[i].color || colorList[i],
      resources,
      original_resources,
      sharing_ratio: sharing_ratio || 1,
    };

    if (arr[i].unallocated_resources?.length && totalUnallocated.total) {
      unallocated.push({
        id: arr[i].id,
        unallocated: totalUnallocated,
      } as Team);
    }

    if (allocatedResources) {
      allocated.push({
        id: arr[i].id,
        allocated: allocatedResources,
      } as Team);
    }
  }

  return { allocated, unallocated, teamData };
};

export const filterByTeam = (data: StackViewState, filter: string) => {
  if (!filter) return data;
  const filteredIds: string[] = Object.keys(data.teamData).filter((id) =>
    data.teamData[id].name.toLowerCase().includes(filter.toLowerCase()),
  );

  const allocated = (data.allocated || []).filter((item: Team) => filteredIds.includes(item.id));
  const unallocated = (data.unallocated || []).filter((item: Team) =>
    filteredIds.includes(item.id),
  );
  const buildings = (data.buildings || []).map(({ name, floors }: Building) => ({
    name,
    floors: floors.filter((floor: Floor) =>
      floor.allocated_resources.some((item) => filteredIds.includes(item.team!)),
    ),
  }));

  return {
    ...data,
    allocated,
    unallocated,
    buildings,
  };
};

export const handleDragChanges = (oldData: StackViewState, results: DropResult) => {
  const droppableId = results?.destination?.droppableId;
  const sourceId = results?.source?.droppableId;
  const draggableRegex = results?.draggableId?.match(/(?<=(floor|team)-).*$/);
  const draggableId = draggableRegex?.[0];
  const type = draggableRegex?.[1];

  if (!(draggableId && droppableId && droppableId !== sourceId)) return null;

  const data = structuredClone(oldData);

  if (type === 'team') {
    const unallocatedTeamIdx = data.unallocated.findIndex((item: Team) => item.id === draggableId);
    const { buildingIdx, floorIdx } = data.buildingData[droppableId];
    const droppableFloor = data.buildings[buildingIdx].floors[floorIdx];
    const draggableTeam = data.unallocated[unallocatedTeamIdx];
    const floorAllocatedSum = sumHelper(droppableFloor.allocated_resources);
    const floorResourcesSum = sumHelper(droppableFloor.resources);
    const placesToChange = calculateAvailablePlaces(
      floorAllocatedSum,
      floorResourcesSum,
      draggableTeam.unallocated,
    );

    // allocated
    const { unallocated, ...teamData } = draggableTeam;
    handleExtraTeam(data, 'allocated', placesToChange, draggableId, teamData);

    // unallocated
    handleExTeam(data, 'unallocated', placesToChange, unallocatedTeamIdx);

    // buildings
    checkResources(placesToChange)(() => {
      const teamOnFloor = droppableFloor.allocated_resources.filter(
        (item: FloorItem) => item.team === draggableId,
      );
      if (teamOnFloor.length) {
        const isLocked = teamOnFloor[0].locked;
        Object.keys(placesToChange).forEach((key: string) => {
          const element = teamOnFloor.find((item: FloorItem) => item.type === +key);
          if (element) {
            element.capacity += placesToChange[key];
          } else {
            droppableFloor.allocated_resources.push({
              capacity: placesToChange[key],
              locked: isLocked,
              team: draggableId,
              type: +key,
            });
          }
        });
      } else {
        Object.keys(placesToChange).forEach((key: string) => {
          droppableFloor.allocated_resources.push({
            capacity: placesToChange[key],
            locked: false,
            team: draggableId,
            type: +key,
          });
        });
      }
    });
  } else if (type === 'floor') {
    const { buildingIdx, floorIdx } = data.buildingData[sourceId];
    const sourceFloor = data.buildings[buildingIdx].floors[floorIdx];
    const resourcesToMove: FloorItem[] = [];
    const filteredResources = sourceFloor.allocated_resources.reduce((acc, curr) => {
      if (curr.team === draggableId && !curr.locked) {
        resourcesToMove.push(curr);
      } else {
        acc.push(curr);
      }
      return acc;
    }, [] as FloorItem[]);
    const resourcesToMoveSum = sumHelper(resourcesToMove);

    if (droppableId === 'unallocated-teams') {
      const allocatedIdx = data.allocated.findIndex((item: Team) => item.id === draggableId);
      const allocatedTeam = data.allocated[allocatedIdx];

      // allocated
      handleExTeam(data, 'allocated', resourcesToMoveSum, allocatedIdx);

      // unallocated
      const { allocated, ...teamData } = allocatedTeam;
      handleExtraTeam(data, 'unallocated', resourcesToMoveSum, draggableId, teamData);
      sourceFloor.allocated_resources = filteredResources;
    } else {
      const { buildingIdx: d_buildingIdx, floorIdx: d_floorIdx } = data.buildingData[droppableId];
      const droppableFloor = data.buildings[d_buildingIdx].floors[d_floorIdx];
      const floorAllocatedSum = sumHelper(droppableFloor.allocated_resources);
      const floorResourcesSum = sumHelper(droppableFloor.resources);
      const placesToChange = calculateAvailablePlaces(
        floorAllocatedSum,
        floorResourcesSum,
        resourcesToMoveSum,
      );

      checkResources(placesToChange)(() =>
        Object.keys(placesToChange).forEach((key: string) => {
          if (resourcesToMoveSum[key] > placesToChange[key] && key !== '3') {
            filteredResources.push({
              capacity: resourcesToMoveSum[key] - placesToChange[key],
              locked: false,
              team: draggableId,
              type: +key,
            });
          }

          const existedItemInFloor = droppableFloor.allocated_resources.find(
            (item: FloorItem) => item.team === draggableId && item.type === +key,
          );
          if (existedItemInFloor) {
            existedItemInFloor.capacity += placesToChange[key];
          } else {
            droppableFloor.allocated_resources.push({
              capacity: placesToChange[key],
              locked: false,
              team: draggableId,
              type: +key,
            });
          }
          sourceFloor.allocated_resources = filteredResources;
        }),
      );
    }

    // check and remove notes
    const isTeamAtFloor = sourceFloor.allocated_resources.some((item) => item.team === draggableId);
    if (!isTeamAtFloor) {
      const floorNotes = data.notes.floors.find((item) => item.floor_id === sourceFloor.id);
      const teamNoteIdx = floorNotes?.teams.findIndex((item) => item.team_id === draggableId);
      const isTeamNote = typeof teamNoteIdx === 'number' && teamNoteIdx !== -1;
      if (isTeamNote) {
        floorNotes!.teams.splice(teamNoteIdx, 1);
      }
    }
  }

  return data;
};

const calculateAvailablePlaces = (
  allocated: SumCapacity,
  resources: SumCapacity,
  team: SumCapacity,
) => {
  const [capacity1, capacity2] = [1, 2].map((item) =>
    (resources[item] || 0) > (allocated[item] || 0)
      ? (resources[item] || 0) - (allocated[item] || 0)
      : 0,
  );
  return {
    1: capacity1 - (team[1] || 0) > 0 ? team[1] || 0 : capacity1,
    2: capacity2 - (team[2] || 0) > 0 ? team[2] || 0 : capacity2,
  } as SumCapacity;
};

export const lockHelper = (
  oldData: StackViewState,
  floorId: string,
  teamId: string,
  locked: boolean,
) => {
  const data = structuredClone(oldData);
  const { buildingIdx, floorIdx } = data.buildingData[floorId];

  data.buildings[buildingIdx].floors[floorIdx].allocated_resources.forEach((item: FloorItem) => {
    if (item.team === teamId) {
      item.locked = locked;
    }
  });

  return data;
};

export const clearFloorHelper = (oldData: StackViewState, floorId: string) => {
  const data = structuredClone(oldData);
  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const dataGroupedByTeam = groupByTeam(
    data.buildings[buildingIdx].floors[floorIdx].allocated_resources,
  );

  data.buildings[buildingIdx].floors[floorIdx].allocated_resources = [];

  Object.entries(dataGroupedByTeam).forEach(([id, team]) => {
    if (id !== 'null') {
      const allocatedIdx = data.allocated.findIndex((item: Team) => item.id === id);
      const allocatedTeam = data.allocated[allocatedIdx];

      // unallocated
      const { allocated, ...teamData } = allocatedTeam;
      handleExtraTeam(data, 'unallocated', team, id, teamData);

      // allocated
      handleExTeam(data, 'allocated', team, allocatedIdx);
    }
  });

  // check and remove notes
  const floorNotes = data.notes.floors.find((item) => item.floor_id === floorId);
  if (floorNotes) {
    floorNotes.teams = [];
  }

  return data;
};

export const lockEmptySpaceHelper = (
  oldData: StackViewState,
  floorId: string,
  locked: FloorPartialLock,
) => {
  const data = structuredClone(oldData);
  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const filteredData = data.buildings[buildingIdx].floors[floorIdx].allocated_resources.filter(
    (item) => item.team,
  );
  const lockedSpace: FloorItem[] = [];

  for (const item in locked) {
    if (+locked[item as keyof FloorPartialLock]) {
      lockedSpace.push({
        capacity: +locked[item as keyof FloorPartialLock],
        type: SeatsType[item as keyof FloorPartialLock],
        locked: true,
        team: null,
      } as FloorItem);
    }
  }

  data.buildings[buildingIdx].floors[floorIdx].allocated_resources = [
    ...filteredData,
    ...lockedSpace,
  ];

  return data;
};

export const unallocateTeamHelper = (oldData: StackViewState, teamId: string) => {
  const data = structuredClone(oldData);

  // buildings
  const allocatedResources: FloorItem[] = [];
  for (let i = 0; i < data.buildings.length; i++) {
    const { floors } = data.buildings[i];

    for (let f = 0; f < floors.length; f++) {
      const filteredResources = floors[f].allocated_resources.reduce((acc, curr) => {
        if (curr.team === teamId && !curr.locked) {
          allocatedResources.push(curr);

          // check and remove note
          const floorNotes = data.notes.floors.find((item) => item.floor_id === floors[f].id);
          const teamNoteIdx = floorNotes?.teams.findIndex((item) => item.team_id === curr.team);
          const isTeamNote = typeof teamNoteIdx === 'number' && teamNoteIdx !== -1;
          if (isTeamNote) {
            floorNotes!.teams.splice(teamNoteIdx, 1);
          }
        } else {
          acc.push(curr);
        }
        return acc;
      }, [] as FloorItem[]);
      floors[f].allocated_resources = filteredResources;
    }
  }
  const totalUnallocated = sumHelper(allocatedResources);
  const allocatedIdx = data.allocated.findIndex((item: Team) => item.id === teamId);
  const allocatedTeam = data.allocated[allocatedIdx];

  // allocated
  handleExTeam(data, 'allocated', totalUnallocated, allocatedIdx);

  // unallocated
  const { allocated, ...teamData } = allocatedTeam;
  handleExtraTeam(data, 'unallocated', totalUnallocated, teamId, teamData);

  return data;
};

export const handleChangeFloorData = (oldData: StackViewState, floorData: EditFloorData) => {
  const data = structuredClone(oldData);
  const { id, note, workstations, offices, collaborativeSpace } = floorData;
  const { buildingIdx, floorIdx } = data.buildingData[id];

  // floor
  const floor = data.buildings[buildingIdx].floors[floorIdx];
  floor.resources = [
    { capacity: +workstations, type: 1 },
    { capacity: +offices, type: 2 },
    { capacity: +collaborativeSpace, type: 3 },
  ];

  // notes
  const floorNotes = data.notes.floors.find((item) => item.floor_id === id);
  if (floorNotes) {
    floorNotes.message = note;
  } else {
    data.notes.floors.push({ floor_id: id, message: note, teams: [] as Note[] } as FloorNote);
  }

  return data;
};

export const handleChangeTeamData = (oldData: StackViewState, teamData: EditTeamDemandState) => {
  const data = structuredClone(oldData);
  const {
    workstations,
    offices,
    collaborativeSpace,
    sharing_ratio,
    original_resources,
    id,
    note,
    ...rest
  } = teamData;
  const updatedOriginalResources = {
    1: +workstations,
    2: +offices,
    3: +collaborativeSpace,
    total: +workstations + +offices,
  } as SumCapacity;

  const updatedResources = {
    1: Math.ceil(+workstations / sharing_ratio),
    2: Math.ceil(+offices / sharing_ratio),
    3: +collaborativeSpace,
    total: Math.ceil(+workstations / sharing_ratio) + Math.ceil(+offices / sharing_ratio),
  } as SumCapacity;

  data.teamData[id] = {
    ...rest,
    id,
    sharing_ratio,
    original_resources: updatedOriginalResources,
    resources: updatedResources,
  };

  // allocated
  const allocatedTeam = data.allocated.find((item: Team) => item.id === id);

  // unallocated
  const unallocatedIdx = data.unallocated.findIndex((item: Team) => item.id === id);
  const unallocated = data.unallocated[unallocatedIdx];

  if (unallocated) {
    const result = allocatedTeam
      ? {
          1: updatedResources[1] - (allocatedTeam.allocated[1] || 0),
          2: updatedResources[2] - (allocatedTeam.allocated[2] || 0),
          total: updatedResources.total - allocatedTeam.allocated.total,
        }
      : updatedResources;
    if (result.total) {
      unallocated.unallocated = result;
    } else {
      data.unallocated.splice(unallocatedIdx, 1);
    }
  } else {
    const { allocated, ...restTeamData } = allocatedTeam!;
    const unallocatedData = {
      1: updatedResources[1] - (allocated[1] || 0),
      2: updatedResources[2] - (allocated[2] || 0),
      total: updatedResources.total - allocated.total,
    } as SumCapacity;

    checkResources(unallocatedData)(() => {
      data.unallocated.push({
        ...restTeamData,
        unallocated: unallocatedData,
      } as Team);
    });
  }

  // notes
  const teamNotes = data.notes.teams.find((item) => item.team_id === id);
  if (teamNotes) {
    teamNotes.message = note;
  } else {
    data.notes.teams.push({ team_id: id, message: note } as Note);
  }

  return data;
};

export const handleChangeAllocateData = (
  oldData: StackViewState,
  teamData: TeamAllocateState,
  floorId: string,
  teamId: string,
) => {
  const data = structuredClone(oldData);
  const { workstations, offices, note } = teamData;
  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const droppableFloor = data.buildings[buildingIdx].floors[floorIdx];
  const teamAtFloor = droppableFloor.allocated_resources.filter(
    (item: FloorItem) => item.team === teamId,
  );
  const floorTotal = sumHelper(teamAtFloor);

  const diff: SumCapacity = {
    1: +workstations - (floorTotal[1] || 0),
    2: +offices - (floorTotal[2] || 0),
    total: +workstations - (floorTotal[1] || 0) + +offices - (floorTotal[2] || 0),
  };

  checkResources(diff)(() => {
    const unallocatedIdx = data.unallocated.findIndex((team: Team) => team.id === teamId)!;
    const unallocated = data.unallocated[unallocatedIdx];
    const allocatedTeamIdx = data.allocated.findIndex((team: Team) => team.id === teamId)!;
    const allocatedTeam = data.allocated[allocatedTeamIdx];

    // allocated
    const allocatedResult = {
      1: (allocatedTeam.allocated[1] || 0) + (diff[1] || 0),
      2: (allocatedTeam.allocated[2] || 0) + (diff[2] || 0),
      total: (allocatedTeam.allocated.total || 0) + (diff.total || 0),
    };

    if (allocatedResult.total) {
      allocatedTeam.allocated = allocatedResult;
    } else {
      data.allocated.splice(allocatedTeamIdx, 1);
    }

    // unallocated
    if (unallocated) {
      const unallocatedResult = {
        1: (unallocated.unallocated[1] || 0) - (diff[1] || 0),
        2: (unallocated.unallocated[2] || 0) - (diff[2] || 0),
        total: (unallocated.unallocated.total || 0) - (diff.total || 0),
      };

      if (unallocatedResult.total) {
        unallocated.unallocated = unallocatedResult;
      } else {
        data.unallocated.splice(unallocatedIdx, 1);
      }
    } else {
      const { allocated, ...restTeamData } = allocatedTeam!;
      data.unallocated.push({
        ...restTeamData,
        unallocated: {
          1: floorTotal[1] - +workstations,
          2: floorTotal[2] - +offices,
          total: floorTotal.total - (+workstations + +offices),
        } as SumCapacity,
      } as Team);
    }

    // floor
    [diff[1], diff[2]].forEach((diffVal: number, idx: number) => {
      const source = teamAtFloor.find((item) => item.type === idx + 1);
      if (source) {
        source.capacity += diffVal;
      } else {
        droppableFloor.allocated_resources.push({
          capacity: diffVal,
          type: idx + 1,
          locked: false,
          team: teamId,
        });
      }
    });

    const isAvailableTeamAtFloor = teamAtFloor.filter(
      (item: FloorItem) => item.capacity && (item.type === 1 || item.type === 2),
    );
    if (isAvailableTeamAtFloor.length) {
      droppableFloor.allocated_resources = droppableFloor.allocated_resources.filter(
        (item: FloorItem) => item.capacity,
      );
    } else {
      droppableFloor.allocated_resources = droppableFloor.allocated_resources.filter(
        (item: FloorItem) => item.team !== teamId,
      );
    }
  });

  // notes
  const floorNotes = data.notes.floors.find((item) => item.floor_id === floorId);
  const teamNoteIdx = floorNotes?.teams.findIndex((item) => item.team_id === teamId);

  const isTeamAtFloor = +workstations + offices > 0;
  const isTeamNote = typeof teamNoteIdx === 'number' && teamNoteIdx !== -1;

  if (isTeamAtFloor && note) {
    if (isTeamNote) {
      floorNotes!.teams[teamNoteIdx].message = note;
    } else if (floorNotes) {
      floorNotes.teams.push({ team_id: teamId, message: note } as Note);
    } else {
      data.notes.floors.push({
        floor_id: floorId,
        message: '',
        teams: [{ team_id: teamId, message: note } as Note] as Note[],
      } as FloorNote);
    }
  } else if (isTeamNote) {
    floorNotes!.teams.splice(teamNoteIdx, 1);
  }

  return data;
};

const handleExtraTeam = (
  data: StackViewState,
  type: SourceType,
  resourcesToMove: SumCapacity,
  teamId: string,
  teamData: Omit<Team, SourceType>,
) => {
  checkResources(resourcesToMove)(() => {
    const teamIdx = (data[type] || []).findIndex((item: Team) => item.id === teamId);
    if (teamIdx >= 0) {
      const team = data[type][teamIdx];
      team[type] = {
        1: (team[type][1] || 0) + (resourcesToMove[1] || 0),
        2: (team[type][2] || 0) + (resourcesToMove[2] || 0),
        total:
          (team[type][1] || 0) +
          (resourcesToMove[1] || 0) +
          (team[type][2] || 0) +
          (resourcesToMove[2] || 0),
      };
    } else {
      data[type].push({
        ...teamData,
        [type]: {
          1: resourcesToMove[1] || 0,
          2: resourcesToMove[2] || 0,
          total: (resourcesToMove[1] || 0) + (resourcesToMove[2] || 0),
        } as SumCapacity,
      } as Team);
    }
  });
};

const handleExTeam = (
  data: StackViewState,
  type: SourceType,
  resourcesToMove: SumCapacity,
  teamIdx: number,
) => {
  data[type][teamIdx][type] = {
    1: (data[type][teamIdx][type][1] || 0) - (resourcesToMove[1] || 0),
    2: (data[type][teamIdx][type][2] || 0) - (resourcesToMove[2] || 0),
    total:
      (data[type][teamIdx][type][1] || 0) +
      (data[type][teamIdx][type][2] || 0) -
      ((resourcesToMove[1] || 0) + (resourcesToMove[2] || 0)),
  };
  const isStillExist = data[type][teamIdx][type][1] || data[type][teamIdx][type][2];

  if (!isStillExist) {
    data[type].splice(teamIdx, 1);
  }
};

export const dataNormalize = (data: StackViewState) => {
  // team
  const teams = [];
  for (const key in data.teamData) {
    const unallocated = data.unallocated.find((item: Team) => item.id === key);
    const unallocated_resources = sumToCapacity(
      unallocated?.unallocated,
      data.teamData[key].resources[3],
    );
    teams.push({
      ...data.teamData[key],
      sharing_ratio: +data.teamData[key].sharing_ratio || 1,
      original_resources: sumToCapacity(
        data.teamData[key].original_resources || data.teamData[key].resources,
      ),
      resources: sumToCapacity(data.teamData[key].resources),
      unallocated_resources,
    });
  }

  // details
  const { workstations, private_offices, seats } = data.solution.details;

  const allocatedSum = totalSumHelper(data.allocated, 'allocated');
  const unallocatedSum = totalSumHelper(data.unallocated, 'unallocated');

  const details = {
    workstations: {
      total: workstations.total,
      allocated: allocatedSum[1],
      unallocated: workstations.total - allocatedSum[1],
    },
    private_offices: {
      total: private_offices.total,
      allocated: allocatedSum[2],
      unallocated: private_offices.total - allocatedSum[2],
    },
    seats: {
      total: seats.total,
      allocated: allocatedSum.total,
      unallocated: seats.total - allocatedSum.total,
    },
    population: {
      total: unallocatedSum.total + allocatedSum.total,
      allocated: allocatedSum.total,
      unallocated: unallocatedSum.total,
    },
  };

  return {
    solution: {
      ...data.solution,
      buildings: data.buildings,
      details,
      teams,
    },
    notes: data.notes,
  };
};

const sumToCapacity = (
  data: SumCapacity = {},
  collaborativeSpaces: number | undefined = undefined,
) => {
  const result = [];
  for (const key in data) {
    if (key !== 'total' && data[key]) {
      result.push({
        type: +key,
        capacity: data[key],
      });
    }
  }
  if (collaborativeSpaces) {
    result.push({
      type: 3,
      capacity: collaborativeSpaces,
    });
  }
  return result;
};

const totalSumHelper = (data: Team[], type: SourceType) => {
  const result = { 1: 0, 2: 0, total: 0 };
  for (let i = 0; i < data.length; i++) {
    result[1] += data[i][type][1] || 0;
    result[2] += data[i][type][2] || 0;
    result.total += data[i][type].total || 0;
  }

  return result as SumCapacity;
};

const checkResources = (resources: SumCapacity) => (cb: Function) => {
  const isResourcesToMovePresent = Object.values(resources).some((value) => !!value);
  if (isResourcesToMovePresent) cb();
};

export const handleReverse = (arr: Floor[], order: Order): Floor[] => {
  if (order === 'asc') {
    return arr;
  }
  return [...arr].reverse();
};
