// Function to calculate the factorial of a number
export const factorial = (n: number): number => {
  if (n === 0 || n === 1) {
    return 1;
  }

  return n * factorial(n - 1);
};

// Function to calculate permutations
/**
 * @param {number} n - Number of items
 * @param {number} k - Number permutations at a time
 * @returns {number} - Number of permutations for the given 'n' and 'k'
 * @example
 * // Example:
 * Calculate permutations for 3 teams taken 2 at a time
 * const result = permutations(3, 2);
 * console.log(result); <--- Output: 6
 */
export const permutations = (n: number, k: number) => factorial(n) / factorial(n - k);
