/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadTrackerList: [],
  schemaName: '',
  schema: [],
  batchId: '',
  filename: '',
};

const getUploadTrackerList = (state) => state.uploadState.uploadTrackerList;
const uploadConfigSelector = ({ uploadState }) => ({
  name: uploadState.schemaName,
  schema: uploadState.schema,
  batchId: uploadState.batchId,
  filename: uploadState.filename,
});

const getSchemaByName = (name) => (state) => state.uploadState.uploadTrackerList.filter((schema) => schema.name === name);
const uploadSlicer = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    releaseNextSchema: {
      reducer: (state, { payload }) => {
        const nextSchema = state.uploadTrackerList[payload];
        if (nextSchema) {
          nextSchema.disabled = false;
        }
      },
    },
    resetUpload: () => initialState,
    setUploadTrackerList: (state, { payload }) => {
      state.uploadTrackerList = payload;
    },
    removeRestriction: (state, { payload: id }) => {
      const targetSchema = state.uploadTrackerList.find((schema) => schema.id === id);
      if (targetSchema) targetSchema.disabled = false;
    },
    setCurrentSchema: (state, { payload: config }) => {
      state.schemaName = config.name;
      state.schema = config.schema;
      state.batchId = config.batchId;
      state.filename = config.filename;
    },
    setSchemaToComplete: (state, { payload: id }) => {
      const currentSchema = state.uploadTrackerList.find((schema) => schema.id === id);
      if (currentSchema) {
        currentSchema.isCompleted = true;
      }
    },
  },
});

export {
  getSchemaByName,
  uploadConfigSelector,
  getUploadTrackerList,
};

export const {
  resetUpload,
  removeRestriction,
  setCurrentSchema,
  releaseNextSchema,
  setSchemaToComplete,
  setUploadTrackerList,
} = uploadSlicer.actions;

export default uploadSlicer.reducer;
