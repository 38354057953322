import { memo } from 'react';
import { Typography, MenuItem } from '@mui/material';
import { MenuDots, Drag, Message } from '@icons/index';
import { MenuByClick, Tooltip } from '@components';
import { Note, SelectOption, Team, TeamItem } from '../../types';
import { teamPlate as styles } from './styles';

type Type = 'allocated' | 'unallocated';

interface Props {
  data: Team;
  type: Type;
  notes?: Note;
  team: TeamItem;
  draggable?: boolean;
  onUnallocate?: (v: string) => void;
  onTeamEdit: (v: string) => void;
  isEditable: boolean;
}

interface OptionProps {
  callback: () => void;
  label: string;
  config: {
    display?: Type;
    isEmpty?: boolean;
  };
}

interface MenuItemProps {
  items: OptionProps[];
  type: Type;
}

export const TeamPlate = memo(
  ({ data, notes, draggable, type, team, onUnallocate, onTeamEdit, isEditable }: Props) => {
    const options: OptionProps[] = [
      {
        label: 'Unallocate Team',
        callback: () => (onUnallocate ? onUnallocate(data.id) : null),
        config: { display: 'allocated', isEmpty: !data.allocated?.total },
      },
      {
        label: 'Edit Demand',
        callback: () => onTeamEdit(data.id),
        config: {},
      },
    ];

    return (
      <div style={styles.wrapper}>
        <span style={{ ...styles.label, backgroundColor: team.color }}>
          {draggable && <Drag />}
        </span>
        <div style={styles.content}>
          <div style={styles.header}>
            <Typography variant="body1" sx={styles.title}>
              {team.name}
              {notes?.message && (
                <Tooltip tooltip={notes.message}>
                  <span style={styles.note}>
                    <Message />
                  </span>
                </Tooltip>
              )}
            </Typography>
            {isEditable && (
              <MenuByClick
                id={`team-plate-${team.name}`}
                menuItems={<MenuItems items={options} type={type} />}
              >
                <MenuDots />
              </MenuByClick>
            )}
          </div>
          <div style={styles.data}>
            <BlockData
              label={`${type} Seats`}
              value={`${data[type].total || 0}/${team.resources.total || 0}`}
            />
            <BlockData
              label="Workstations"
              value={`${data[type]['1'] || 0}/${team.resources['1'] || 0}`}
            />
            <BlockData
              label="Offices"
              value={`${data[type]['2'] || 0}/${team.resources['2'] || 0}`}
            />
            <BlockData label="Collaborative Seats" value={`${team.resources['3'] || '--'}`} />
          </div>
        </div>
      </div>
    );
  },
);

const BlockData = ({ label, value }: SelectOption<string>) => (
  <div>
    <Typography variant="body2" sx={styles.capitalize}>
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </div>
);

const MenuItems = ({ items, type }: MenuItemProps): any =>
  items.map(({ callback, label, config }: OptionProps) => {
    if ((config.display && config.display !== type) || config.isEmpty === true) {
      return null;
    }

    return (
      <MenuItem key={label} onClick={callback}>
        {label}
      </MenuItem>
    );
  });
