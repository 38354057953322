export type Order = 'asc' | 'desc';

export const quickSort = <T>(
  arr: T[],
  property: keyof T | null,
  order: Order,
  prepareValue?: (value: T[keyof T]) => string | number | boolean | Date,
): T[] => {
  if (arr.length <= 1 || !property) {
    return arr;
  }

  const pivotIdx = Math.floor(arr.length / 2);
  const pivot = arr[pivotIdx];
  const leftArr = [];
  const rightArr = [];

  const B = prepareValue
    ? prepareValue(pivot[property])
    : pivot[property]!.toString().toLowerCase();

  for (let i = 0; i < arr.length; i++) {
    if (i !== pivotIdx) {
      const A = prepareValue
        ? prepareValue(arr[i][property])
        : arr[i][property]!.toString().toLowerCase();

      if (order === 'desc' ? A > B : B > A) {
        leftArr.push(arr[i]);
      } else {
        rightArr.push(arr[i]);
      }
    }
  }

  return [
    ...quickSort(leftArr, property, order, prepareValue),
    pivot,
    ...quickSort(rightArr, property, order, prepareValue),
  ];
};
