import { useRef, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';

const OverflowTip = ({ children }: any) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef: any = useRef();

  useEffect(() => {
    if (textElementRef && textElementRef.current) {
      setIsOverflow(!!(textElementRef.current.scrollWidth > textElementRef.current.clientWidth));
    }
  }, []);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed} arrow>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
export default OverflowTip;
