import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { Close } from '@icons/index';
import { Button } from '../Button';
import { IconButton } from '../IconButton';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onLeave: () => void;
  onCancel: () => void;
  title: string;
  message: string;
  successText: string;
  errorText: string;
}

export const ConfirmationModal = ({
  isOpen,
  onLeave,
  onCancel,
  title,
  message,
  successText,
  errorText,
}: ConfirmationDialogProps) => (
  <Dialog open={isOpen} fullWidth>
    <DialogTitle>
      {title}
      <IconButton aria-label="close" onClick={onCancel}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button type="secondary" onClick={onLeave}>
        {errorText}
      </Button>
      <Button onClick={onCancel}>{successText}</Button>
    </DialogActions>
  </Dialog>
);
