import { FC } from 'react';
import { Backdrop } from '@mui/material';
import Spinner from './Spinner';

interface Props {
  isOpen: boolean;
  position?: 'fixed' | 'absolute';
}

const PageSpinner: FC<Props> = ({ isOpen, position = 'absolute' }: Props) => (
  <Backdrop open={isOpen} sx={{ position, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
    <Spinner />
  </Backdrop>
);

export default PageSpinner;
