import { useEffect, useState } from 'react';

export const useDebounce = <T extends string | number>(value: T, delay: number) => {
  const [debouncedValue, setValue] = useState<T>(value);

  useEffect(() => {
    const t = setTimeout(() => setValue(value), delay);
    return () => clearTimeout(t);
  }, [value, delay]);

  return debouncedValue;
};
