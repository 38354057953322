import { FC, useState } from 'react';
import { Switch, SwitchProps, FormControlLabel } from '@mui/material';

interface Props extends Omit<SwitchProps, 'onChange'> {
  onChange: (v: boolean) => void;
  value?: boolean;
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const Toggle: FC<Props> = ({ value = false, onChange, label, labelPlacement, ...rest }: Props) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
    setChecked(event.target.checked);
  };

  if (label) {
    return (
      <FormControlLabel
        sx={{}}
        disableTypography
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            {...rest}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  }

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      {...rest}
    />
  );
};

export default Toggle;
