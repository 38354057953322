import axios, { AxiosInstance } from 'axios';

export class ApiClient {
  private static instance: ApiClient;

  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: new URL(process.env.REACT_APP_TGM_BACKEND_BASE_API_URL as string).origin,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_TGM_BACKEND_API_KEY,
      },
    });
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  getClient() {
    return this.client;
  }
}
