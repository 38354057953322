/* eslint-disable eqeqeq */
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Typography, FormHelperText } from '@mui/material';
import { SideBar, Button, TextInput, ConfirmationModal } from '@components';
import { sumHelper } from './helpers';
import { editFloorSchema } from '../../schemas';
import {
  StackViewState,
  EditFloorData,
  SumCapacity,
  ProjectFloor,
  ProjectWithData,
} from '../../types';
import { sidebar as styles } from './styles';

type NumericFields = 'workstations' | 'offices';

interface Props {
  sideBarStatus: boolean;
  onClose: () => void;
  data: StackViewState;
  floorId: string;
  onSave: (v: EditFloorData) => void;
}

interface ContentProps {
  name: string;
  initialFloorData: ProjectFloor | null;
  initialData: EditFloorData;
  staticData: {
    allocated: Record<string, number>;
    unallocated: Record<string, number>;
  };
  onSave: (v: EditFloorData) => void;
  onChange: (key: keyof EditFloorData, value: string) => void;
}

export const ModifyFloor: FC<Props> = ({
  sideBarStatus,
  onClose,
  data,
  floorId,
  onSave,
}: Props) => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [modified, setModified] = useState(new Set());
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const initialProjectData = queryClient.getQueryData(['project', projectId]) as ProjectWithData;
  const initialFloorData =
    initialProjectData?.floors?.find((item: ProjectFloor) => item.id === floorId) || null;

  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const floor = data.buildings[buildingIdx].floors[floorIdx];

  const totalSum = sumHelper(floor.resources);
  const allocated = sumHelper(floor.allocated_resources);

  const note = data.notes.floors.find((item) => item.floor_id === floorId)?.message || '';

  const initialFormData: EditFloorData = {
    id: floorId,
    workstations: totalSum[1] || 0,
    offices: totalSum[2] || 0,
    collaborativeSpace: totalSum[3] || 0,
    note,
  };

  const staticData = {
    allocated,
    unallocated: {
      workstations: (totalSum[1] || 0) - (allocated[1] || 0),
      offices: (totalSum[2] || 0) - (allocated[2] || 0),
      total: (totalSum[1] || 0) - (allocated[1] || 0) + (totalSum[2] || 0) - (allocated[2] || 0),
    },
  };

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);
  const handleShowConfirmationDialog = () => {
    if (modified.size) {
      toggleConfirmationDialog();
    } else {
      onClose();
    }
  };
  const handleModifyingCheck = (key: keyof EditFloorData, value: string) => {
    const isModified = initialFormData[key] != +value;
    setModified((state) => {
      const isPresent = state.has(key);
      if (isModified) {
        return isPresent ? new Set(state) : new Set(state.add(key));
      }
      state.delete(key);
      return new Set(state);
    });
  };

  return (
    <>
      <SideBar isOpen={sideBarStatus} onClose={handleShowConfirmationDialog}>
        <SidebarContent
          name={floor.name}
          initialData={initialFormData}
          staticData={staticData}
          onSave={onSave}
          onChange={handleModifyingCheck}
          initialFloorData={initialFloorData}
        />
      </SideBar>
      {isOpen && (
        <ConfirmationModal
          isOpen
          onCancel={toggleConfirmationDialog}
          onLeave={onClose}
          title="You have unsaved changes"
          message="Changes will be lost if you leave without saving."
          successText="Return to editing"
          errorText="Discard changes and leave"
        />
      )}
    </>
  );
};

const SidebarContent = ({
  name,
  initialData,
  onSave,
  staticData,
  onChange,
  initialFloorData,
}: ContentProps) => {
  const [formData, setFormData] = useState<EditFloorData>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { allocated, unallocated } = staticData;

  const handleSave = () => {
    if (!Object.keys(errors).length) {
      onSave(formData);
    }
  };

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      const context = shouldAddDynamicValidation(id)
        ? handleValidationMap(id as NumericFields, allocated)
        : undefined;
      setFormData((data: EditFloorData) => ({ ...data, [id]: value }));
      onChange(id as keyof EditFloorData, value);
      await editFloorSchema.validateAt(id, { ...formData, [id]: value }, { context });

      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  return (
    <div style={styles.wrapper}>
      <Button onClick={handleSave}>Save</Button>
      <div style={styles.header}>
        <Typography variant="h1" component="h1">
          Floor Supply
        </Typography>
        <Typography variant="body1" component="h6">
          <span style={styles.subtitle}>{name}</span>
        </Typography>
        {initialFloorData && (
          <Typography variant="body2">
            <span style={styles.subtitle}>Initial Demand:</span>
            <p style={styles.info}>
              <span>
                Total Seats:
                {` ${
                  (initialFloorData.workstations || 0) + (initialFloorData.private_offices || 0)
                }`}
              </span>
              <span>Workstations: {initialFloorData.workstations || 0}</span>
              <span>Offices: {initialFloorData.private_offices || 0}</span>
              <span>Collaborative Seats: {initialFloorData.shared_offices || 0}</span>
            </p>
          </Typography>
        )}
      </div>
      <div style={{ ...styles.grid, ...styles.gridBottom, ...styles.spaces }}>
        <TextInput
          id="workstations"
          label="Workstations"
          value={formData.workstations}
          onChange={handleFormChange}
          error={!!errors.workstations}
          type="number"
          InputProps={{ inputProps: { min: allocated[1] || 0 } }}
          sx={{ gridArea: 'a' }}
        />
        <TextInput
          id="offices"
          label="Offices"
          value={formData.offices}
          onChange={handleFormChange}
          error={!!errors.offices}
          type="number"
          InputProps={{ inputProps: { min: allocated[2] || 0 } }}
          sx={{ gridArea: 'b' }}
        />
        <TextInput
          id="collaborativeSpace"
          label="Collaborative Seats"
          value={formData.collaborativeSpace}
          onChange={handleFormChange}
          error={!!errors.collaborativeSpace}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          sx={{ gridArea: 'c' }}
        />
        <FormHelperText
          error={!!errors.workstations || !!errors.offices || !!errors.collaborativeSpace}
          sx={{ gridArea: 'd' }}
        >
          {errors.workstations || errors.offices || errors.collaborativeSpace}
        </FormHelperText>
      </div>
      <div style={styles.note}>
        <TextInput
          id="note"
          label="Note"
          multiline
          minRows={4}
          value={formData.note || ''}
          onChange={handleFormChange}
          error={!!errors.note}
          helperText={errors.note}
        />
      </div>
      <div style={styles.footer}>
        <Typography variant="body2">
          <span style={styles.subtitle}>Free Space on Floor:</span>
          <p style={styles.info}>
            <span>Total Seats: {unallocated.total}</span>
            <span>Workstations: {unallocated.workstations}</span>
            <span>Offices: {unallocated.offices}</span>
          </p>
        </Typography>
      </div>
    </div>
  );
};

const shouldAddDynamicValidation = (key: string) => ['workstations', 'offices'].includes(key);

const handleValidationMap = (item: NumericFields, source: SumCapacity) => {
  const validationMap: Record<NumericFields, any> = {
    workstations: { min: source[1] || 0 },
    offices: { min: source[2] || 0 },
  };
  return {
    ...validationMap[item],
    message:
      'You can only decrease team demand for within currently free floor scope. If you need to lower the demand beyond it, please go to the stack view and remove the team from the floor',
  };
};
