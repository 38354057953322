export const Unlocked = ({ width = '13', height = '16' }: any) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6082 3C10.3582 1.31436 8.48243 0 6.5 0C4.3703 0 2.36364 1.5169 2.36364 3.38144V6H0V16H13V6H11H10.6364H9.45455H9H3.54545V3.38144C3.54545 2.06261 4.99362 0.989691 6.5 0.989691C7.85813 0.989691 9.16894 1.86182 9.41379 3H10.6082ZM11.82 7H1.18359V15H11.82V7Z"
      fill="white"
    />
  </svg>
);
