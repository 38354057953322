export interface SelectOption<T, K = string> {
  label: K;
  value: T;
}

export interface BreadcrumbOptions {
  label: string;
  link: string;
}

export interface BaseDataState {
  name: string;
  description?: string;
}

export enum Roles {
  SUPER = 'Super Admin',
  OCCUPANCY_PLANER = 'Occupancy Planner',
}
