import { CSSProperties, ChangeEvent, FC, useState } from 'react';
import { Typography } from '@mui/material';
import { SideBar, Button, TextInput, PageSpinner } from '@components';
import { useEditPlan } from '@hooks/usePlans';
import { handleTrimObjValue } from '@service/helpers';
import { savePlanSchema } from '../../schemas';
import { PlanDetailsResponse, BaseDataState, Plan } from '../../types';

interface Props {
  isOpen: boolean;
  toggleSideBar: () => void;
  projectId: string;
  data: PlanDetailsResponse | Plan;
}

interface ContentProps {
  initialData: BaseDataState;
  planId: number;
  projectId: string;
  onClose: () => void;
}

export const EditPlanSideBar: FC<Props> = ({ isOpen, toggleSideBar, data, projectId }: Props) => {
  const initialData = {
    name: data.name,
    description: data.description,
  } as BaseDataState;

  const handleClose = () => toggleSideBar();

  return (
    <SideBar isOpen={isOpen} onClose={handleClose}>
      <SideBarContent
        initialData={initialData}
        planId={data.id}
        projectId={projectId}
        onClose={toggleSideBar}
      />
    </SideBar>
  );
};

const SideBarContent = ({ initialData, planId, projectId, onClose }: ContentProps) => {
  const updatePlan = useEditPlan(projectId);
  const [formData, setFormData] = useState<BaseDataState>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: BaseDataState) => ({ ...data, [id]: value }));
      await savePlanSchema.validateAt(id, { ...formData, [id]: value });
      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleSubmit = async () => {
    if (!Object.keys(errors).length) {
      try {
        await updatePlan.mutateAsync({ planId, data: handleTrimObjValue(formData) });
        onClose();
      } catch (e: any) {
        if (e.response?.data?.error === 'Input data validation error') {
          setErrors((error) => ({ ...error, name: e.response.data.details }));
        }
      }
    }
  };

  return (
    <div style={styles}>
      <Button onClick={handleSubmit}>Save Changes</Button>
      <Typography variant="h1" component="h1">
        Plan Details
      </Typography>
      <TextInput
        id="name"
        required
        label="Plan name"
        value={formData.name || ''}
        onChange={handleFormChange}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextInput
        id="description"
        label="Description"
        multiline
        minRows={6}
        value={formData.description || ''}
        onChange={handleFormChange}
        error={!!errors.description}
        helperText={errors.description}
      />
      <PageSpinner isOpen={updatePlan.isLoading} />
    </div>
  );
};

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
  width: '100%',
};
