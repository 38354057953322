export const MenuDots = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_5971_70988" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10C17.66 10 19 8.66 19 7C19 5.34 17.66 4 16 4C14.34 4 13 5.34 13 7C13 8.66 14.34 10 16 10ZM16 13C14.34 13 13 14.34 13 16C13 17.66 14.34 19 16 19C17.66 19 19 17.66 19 16C19 14.34 17.66 13 16 13ZM13 25C13 23.34 14.34 22 16 22C17.66 22 19 23.34 19 25C19 26.66 17.66 28 16 28C14.34 28 13 26.66 13 25Z"
      />
    </mask>
    <path
      d="M18 7C18 8.10772 17.1077 9 16 9V11C18.2123 11 20 9.21228 20 7H18ZM16 5C17.1077 5 18 5.89228 18 7H20C20 4.78772 18.2123 3 16 3V5ZM14 7C14 5.89228 14.8923 5 16 5V3C13.7877 3 12 4.78772 12 7H14ZM16 9C14.8923 9 14 8.10772 14 7H12C12 9.21228 13.7877 11 16 11V9ZM14 16C14 14.8923 14.8923 14 16 14V12C13.7877 12 12 13.7877 12 16H14ZM16 18C14.8923 18 14 17.1077 14 16H12C12 18.2123 13.7877 20 16 20V18ZM18 16C18 17.1077 17.1077 18 16 18V20C18.2123 20 20 18.2123 20 16H18ZM16 14C17.1077 14 18 14.8923 18 16H20C20 13.7877 18.2123 12 16 12V14ZM16 21C13.7877 21 12 22.7877 12 25H14C14 23.8923 14.8923 23 16 23V21ZM20 25C20 22.7877 18.2123 21 16 21V23C17.1077 23 18 23.8923 18 25H20ZM16 29C18.2123 29 20 27.2123 20 25H18C18 26.1077 17.1077 27 16 27V29ZM12 25C12 27.2123 13.7877 29 16 29V27C14.8923 27 14 26.1077 14 25H12Z"
      fill="#778F9C"
      mask="url(#path-1-inside-1_5971_70988)"
    />
  </svg>
);
