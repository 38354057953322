import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setUser } from '@redux/reducers/userData';
import { authenticateUser } from '@utils/authorization';
import { useAlert } from '../context/AlertContext';
import { Storage } from '../service/Storage';
import { Roles } from '../types';

type StorageData = {
  capabilities: string[];
  user: Omit<UserData, 'customers'>;
  customer: {
    customerName: string;
    customerId: number;
  }[];
} & UserData;

export type UserData = {
  customers: {
    customerName: string;
    customerId: number;
  }[];
  email: string;
  firstName: string;
  lastName: string;
  roles: {
    roleId: number;
    roleName: Roles;
    capabilities: Array<{
      capabilityId: number;
      capabilityName: string;
    }>;
  }[];
  userRolesList: Roles[];
  userId: number;
};

const USER_DATA = 'userData';

export const useClient = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const storage = new Storage<StorageData>();

  const removeClient = useCallback(() => {
    storage.remove(USER_DATA);
  }, []);

  const loadClient = () =>
    authenticateUser()(dispatch)
      .then(({ capabilities, user, userRolesList, customer }) => {
        if (!user.customers.length) {
          alert('warning', 'There is no clients assigned to your user. Please contact administrator to get this resolved.')
        }
        storage.set('userData', { capabilities, user, userRolesList, customer } as StorageData);
        return { ...user, userRolesList } as UserData;
      })
      .catch((e: any) => {
        alert('error', e, e?.response?.data?.status);
        throw new Error(e.message);
      });

  const { data, refetch } = useQuery(
    ['userDetails'],
    async () => {
      const localData = storage.get(USER_DATA);
      if (localData) {
        const { customer, capabilities, user, userRolesList } = localData;
        dispatch(
          setUser({
            user,
            capabilities,
            customer,
          }),
        );
        return { ...user, userRolesList } as UserData;
      }
      const result = await loadClient();
      return result;
    },
    {
      enabled: false,
      suspense: true,
    },
  );

  return {
    client: data,
    removeClient,
    getClient: refetch,
    loadClient,
  };
};
