import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAlert } from '../context/AlertContext';
import { useApi } from './useApi';
import { handlePlanDetailsResponse } from '../modules/plan-details/helpers';
import { PlanDetails, PlanSolution, Plan, Notes } from '../types';

const url = (planId: number | string) => `/occupancy-planning/api/plan/${planId}`;

export const usePlanDetails = (planId: string) => {
  const api = useApi();
  const alert = useAlert();

  const planDetailsQuery = async () => {
    const { data } = await api.get<PlanDetails>(url(planId));
    return handlePlanDetailsResponse(data);
  };

  const planDetails = useQuery(['planDetails', Number(planId)], planDetailsQuery, {
    enabled: !!planId,
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });

  const updatePlanDetailsData = async (data: { solution: PlanSolution; notes: Notes }) => {
    const response = await api.patch(url(planId), data);
    return response?.data;
  };

  const updatePlanMutations = useMutation(updatePlanDetailsData, {
    mutationKey: 'update-plan',
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });

  return {
    planDetails,
    updatePlan: updatePlanMutations.mutateAsync,
  };
};

export const usePlanOfRecord = (projectId: string) => {
  const api = useApi();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const updatePlanOfRecord = async ({ value, planId }: { value: boolean; planId: number }) => {
    queryClient.setQueryData(['planDetails', planId], (oldData: any) => {
      if (oldData) {
        return {
          ...oldData,
          is_plan_of_record: value,
        };
      }
      return oldData;
    });

    return api.patch(url(planId), {
      is_plan_of_record: value,
    });
  };

  return useMutation(updatePlanOfRecord, {
    onSuccess: (res, payload) => {
      queryClient.setQueryData(['planList', projectId], (oldData: any) => {
        if (oldData && res?.data) {
          const rest = oldData.filter((item: Plan) => item.id !== res.data.id);
          const planOfRecordIdx = rest.findIndex((item: Plan) => item.is_plan_of_record);
          if (planOfRecordIdx >= 0) {
            rest[planOfRecordIdx].is_plan_of_record = false;
          }
          return [res.data, ...rest];
        }
        return oldData;
      });

      alert('success', `Plan successfully ${payload ? 'set' : 'unset'} as a Plan of record`);
    },
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });
};
