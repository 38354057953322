import { PRIMARY_COLORS } from '@constants/colors';
import { Notes } from './plan';

export interface CreateProjectForm {
  name: string;
  customerId?: number;
  description?: string;
}

export interface CustomerOption {
  customerId: number;
  customerName: string;
}

// TODO: move this to specific module directory
export interface ProjectStatus {
  label: string;
  name: string;
}

export type Project = {
  id: number;
  customer_id: number;
  customer_name: string;
  status: ProjectStatus;
  name: string;
  description: string;
  headcount: number;
  seats: number;
  total_area_sqft: number;
  updated_by: string;
  updated_at: string;
  is_archived?: boolean;
};

export type ProjectWithData = Project & {
  floors?: ProjectFloor[];
  teams?: ProjectTeam[];
};

export type UniversalProject<Condition extends boolean> = Condition extends true
  ? ProjectWithData
  : Project;

export type ProjectFloor = {
  site_name: string;
  building_name: string;
  area_in_sqft: number;
  floor_number: number;
  floor_name: string;
  id: string;
  private_offices: number;
  shared_offices: number;
  total_capacity_count: number;
  workstations: number;
};

export type ProjectTeam = {
  current_headcount: number;
  future_headcount: number;
  id: string;
  name: string;
  private_offices: number;
  shared_offices: number;
  shared_ratio: number | 'N/A';
  total_seat_count: number | null;
  workstations: number;
  color?: string;
};

export interface Plan {
  id: number;
  name: string;
  description: string;
  strategy: string;
  free_floors: number;
  updated_at: number;
  updated_by: string;
  adj_value: number;
  seats: PlanCounts;
  workstations: PlanCounts;
  private_offices: PlanCounts;
  population: PlanCounts;
  is_plan_of_record: boolean;
  sort_order: number;
  notes: Notes;
}

interface PlanCounts {
  total: number;
  allocated: number;
  unallocated: number;
}

export const STATUS_COLORS: Record<string, string> = {
  data_collection: PRIMARY_COLORS.PRIMARY_SILVER,
  ready_to_generate_plan: '#80BBAD', // clarify if this status is needed and check the color
  available_n_plans: PRIMARY_COLORS.PRIMARY_ACCENT_GREEN,
  plan_of_record: '#A388BF',
};

export type AwsGenerateUrl = {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    'x-amz-security-token': string;
    policy: string;
    signature: string;
  };
};

export type UploadedFile = {
  id: number;
  name: string;
  path: string;
  relative_to: 'spaces' | 'teams';
  extension: 'xlsx' | 'csv';
  created_at: string;
};

export type DownloadLink = {
  download_link: string;
};
