import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { DragDropContext, DropResult, Droppable, Draggable } from 'react-beautiful-dnd';
import { usePlanOfRecord } from '@hooks/usePlanDetails';
import { useEditPlan, usePlanOrder } from '@hooks/usePlans';
import { PlanCard } from './PlanCard';
import { PlanOfRecordModal } from './PlanOfRecordModal';
import { EditPlanSideBar } from '../edit-plan';
import { SaveAsSideBar } from '../save-as';
import { findPlanOfRecordName } from './helpers';
import { Plan } from '../../types';

interface Props {
  data: Plan[];
}

const PlanList = ({ data }: Props) => {
  const { projectId } = useParams();
  const [planAction, setPlanAction] = useState<Record<'edit' | 'duplicate' | 'planOfRecord', number>>();
  const PlanOfRecord = findPlanOfRecordName(data);
  const updatePlanOfRecord = usePlanOfRecord(projectId!);
  const updatePlan = useEditPlan(projectId!);
  const changeOrder = usePlanOrder(projectId!);
  const queryClient = useQueryClient();

  const handleAction = (data?: Record<string, number>) => {
    if (data) {
      if (data.edit || data.duplicate) {
        setPlanAction(data);
      } else if (data.planOfRecord) {
        if (PlanOfRecord && PlanOfRecord.id !== data.planOfRecord) {
          setPlanAction(data);
        } else {
          setAsPlanOfRecord(data.planOfRecord as number);
        }
      } else if (data.delete) {
        handleDeletePlan(data.delete);
      } else {
        setPlanAction(undefined);
      }
    } else {
      setPlanAction(data);
    }
  };

  const setAsPlanOfRecord = async (planOfRecord?: any) => {
    try {
      const planId = (typeof planOfRecord === 'number' && planOfRecord) || planAction!.planOfRecord;
      const value = !data.find((item: Plan) => item.id === planId)?.is_plan_of_record;
      if (planId) {
        await updatePlanOfRecord.mutateAsync({ value, planId });
      }
      setPlanAction(undefined);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeletePlan = async (planId: number) => {
    try {
      await updatePlan.mutateAsync({ planId,  data: { is_deleted: true }});
    } catch (e) {
      console.error(e);
    }
  };

  const handleDrag = (results: DropResult) => {
    if (results.destination) {
      const startIndex = results.source.index;
      const endIndex = results.destination.index;

      queryClient.setQueryData(['planList', projectId], (oldData: any) => {
        if (oldData) {
          const hasPlanOfRecord = oldData[0].is_plan_of_record;
          const list = [...oldData];
          const [removed] = list.splice(startIndex, 1);
          list.splice((endIndex === 0 && hasPlanOfRecord) ? 1 : endIndex, 0, removed);

          list.forEach((item, idx) => {
            item.sort_order = idx;
          });
          changeOrder(list);
          return list;
        }
        return oldData;
      });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId={`plan-list-${projectId}`} direction="horizontal">
          {
            (provider) => (
              <div ref={provider.innerRef} style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {data.map((item: Plan, idx: number) => (
                  <Draggable
                    key={item.id}
                    draggableId={`team-${item.id}`}
                    index={idx}
                    isDragDisabled={item.is_plan_of_record}
                  >
                    {
                      (provider) => (
                      <div
                        ref={provider.innerRef}
                        {...provider.draggableProps}
                      >
                        <PlanCard
                          key={item.name}
                          data={item}
                          projectId={projectId!}
                          onAction={handleAction}
                          isDraggable={!item.is_plan_of_record}
                          dragBy={provider.dragHandleProps}
                        />
                      </div>)
                    }
                  </Draggable>
                ))}
              </div>)
          }
        </Droppable>
      </DragDropContext>
      {planAction?.edit && (
        <EditPlanSideBar
          isOpen={!!planAction.edit}
          toggleSideBar={handleAction}
          data={data.find((item: Plan) => item.id === planAction.edit)!}
          projectId={projectId!}
        />
      )}
      {planAction?.duplicate && (
        <SaveAsSideBar
          isOpen={!!planAction.duplicate}
          toggleSideBar={handleAction}
          planId={planAction.duplicate}
          projectId={projectId!}
        />
      )}
      {
        planAction?.planOfRecord && PlanOfRecord && (
          <PlanOfRecordModal
            onClose={() => setPlanAction(undefined)}
            isOpen
            planName={PlanOfRecord.name}
            onSubmit={setAsPlanOfRecord}
            isDisabled={updatePlanOfRecord.isLoading}
          />
        )
      }
    </>
  );
};

export { PlanList };
