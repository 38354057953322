import { FC, createContext, useContext, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadcrumbOptions } from '../types';

export interface HeaderData {
  title: string | FC<any>;
  ActionBar?: FC<any>;
  breadcrumb?: BreadcrumbOptions[];
  props?: any;
}

interface ContextI {
  data: HeaderData;
  setHeaderData: (d: HeaderData) => void;
}

const initData: HeaderData = {
  title: '',
  ActionBar: undefined,
  breadcrumb: undefined,
  props: undefined,
};

export const HeaderDataContext = createContext<ContextI>({
  data: initData,
  setHeaderData: () => {},
});

const { Provider } = HeaderDataContext;

export const HeaderDataProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [data, setHeaderData] = useState<HeaderData>(initData);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    setHeaderData(initData);
  }, [pathname]);

  return <Provider value={{ data, setHeaderData }}>{children}</Provider>;
};

export const useHeaderData = () => {
  const context = useContext(HeaderDataContext);
  return context;
};
