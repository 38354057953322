export type SumCapacity = Record<string, number>;

export type ResponseCapacity = {
  total: number;
  allocated: number;
  unallocated: number;
};
export type TeamItem = {
  id: string;
  name: string;
  color: string;
  description: string;
  total_seat_count: any;
  min_days_policy_id: any;
  resources: SumCapacity;
  original_resources: SumCapacity;
  sharing_ratio: number;
};
export type TeamData = Record<string, TeamItem>;
export type DataType = Record<string, any>;

export type Team = {
  id: string;
  name: string;
} & { [x in 'allocated' | 'unallocated']: SumCapacity };

export type FloorItem = {
  capacity: number;
  type: number;
  locked: boolean;
  team: string | null;
};

export type Floor = {
  id: string;
  name: string;
  floor_number: number;
  total_capacity_count: number;
  resources: Record<string, number>[];
  allocated_resources: FloorItem[];
};

export type Building = {
  floors: Floor[];
  name: string;
};

export type PlanSolution = {
  details: {
    seats: SumCapacity;
    workstations: SumCapacity;
    private_offices: SumCapacity;
    population: SumCapacity;
  };
  adjacencies: any;
  score: any;
  free_floors: any;
  teams: Array<any>;
  buildings: Building[];
};

export interface PlanDetails {
  id: number;
  seats: SumCapacity;
  workstations: SumCapacity;
  private_offices: SumCapacity;
  population: SumCapacity;
  name: string;
  description: string;
  project_id: number;
  strategy: string;
  updated_by: string;
  updated_at: string;
  solution: PlanSolution;
  is_plan_of_record: boolean;
  [x: string]: any;
}

export type PlanDetailsResponse = StackViewState & {
  name: string;
  description: string;
  details: {
    allocated: number;
    total: number;
    offices: number;
    workstations: number;
  };
  is_deleted: boolean;
  id: number;
  is_plan_of_record: boolean;
  seats: SumCapacity;
  workstations: SumCapacity;
  private_offices: SumCapacity;
  population: SumCapacity;
  updated_at: string;
  updated_by: string;
  free_floors?: number;
  adj_value?: number;
  notes: Notes;
};

export type StackViewState = {
  buildings: Building[];
  allocated: Team[];
  unallocated: Team[];
  teamData: TeamData;
  buildingData: DataType;
  solution: PlanSolution;
  strategy: string;
  is_plan_of_record: boolean;
  notes: Notes;
};

export type GeneratePlanBody = {
  strategies: number[];
  project_id: number;
};

export type GenerateNewPlanForm = {
  strategies: number[];
  name: string;
  description?: string;
  plan_id?: number;
};

export type GenerateNewPlanBody = GenerateNewPlanForm & {
  project_id: number;
};

export type Strategy = {
  value: number;
  key: string;
  label: string;
  description: string;
};

export type DuplicatePlan = {
  name: string;
  description?: string;
  project_id: number;
  solution: PlanSolution;
  strategy_id: number;
};

export type EditTeamDemandState = {
  id: string;
  name: string;
  description: string;
  color: string;
  workstations: number;
  offices: number;
  collaborativeSpace: number;
  min_days_policy_id: any;
  total_seat_count: any;
  resources: SumCapacity;
  original_resources: SumCapacity;
  sharing_ratio: number;
  note: string;
};

export type EditFloorData = {
  id: string;
  workstations: number;
  offices: number;
  collaborativeSpace: number;
  note: string;
};

export type TeamAllocateState = {
  workstations: number;
  offices: number;
  collaborativeSpace: number;
  note: string;
};

export type FloorPartialLock = {
  workstations: number;
  offices: number;
};

export type Note =
  | {
      team_id: string | null;
      floor_id: never;
      message: string;
    }
  | {
      floor_id: string;
      team_id: never;
      message: string;
    };

export type FloorNote = Note & {
  teams: Note[];
};

export interface Notes {
  teams: Note[];
  floors: FloorNote[];
}

export const STRATEGIES_LIST: Strategy[] = [
  {
    value: 1,
    key: 'Space Reduction',
    label: 'Space Reduction',
    description:
      'Primary objective is to free-up as much of space (make entire floors or half of the floor non-occupied)',
  },
  {
    value: 2,
    key: 'Adjacency',
    label: 'Best Adjacency Score',
    description:
      'Primary objective is to allocate the seats to the teams to best match Adjacency requirements',
  },
  {
    value: 3,
    key: 'Best Balance',
    label: 'Balanced Ratio',
    description:
      'Allocate seats in a way that is optimal in terms of getting free floors, while covering as much of team adjacency requirements as possible',
  },
];
