export class ColorGenerator {
  private baseColors: string[];

  constructor() {
    this.baseColors = colors;
  }

  public getColors(number: number) {
    if (number > this.baseColors.length) {
      const shades: string[] = this.generate(number - this.baseColors.length);
      return [...this.baseColors, ...shades];
    }
    return this.baseColors.slice(0, number);
  }

  private generate(number: number) {
    const arr: string[] = [];

    for (let i = 0, b = 0, p = 1; i < number; i++) {
      if (!this.baseColors[b]) {
        b = 0;
        p++;
      }
      arr.push(this.shade(this.baseColors[b], p * 10));
      b++;
    }

    return arr;
  }

  private shade(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + percent)) / 100).toString(), 10);
    G = parseInt(((G * (100 + percent)) / 100).toString(), 10);
    B = parseInt(((B * (100 + percent)) / 100).toString(), 10);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    R = Math.round(R);
    G = Math.round(G);
    B = Math.round(B);

    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

    return `#${RR}${GG}${BB}`;
  }
}

export const colors = [
  '#80BBAD',
  '#435254',
  '#17E88F',
  '#DBD99A',
  '#D2785A',
  '#885073',
  '#A388BF',
  '#1F3765',
  '#3E7CA6',
  '#94E5D7',
  '#9AE9C8',
  '#EEEB83',
  '#FFBAA3',
  '#FFACE0',
  '#E2C6FF',
  '#92B7FF',
  '#6DC4FF',
  '#014371',
  '#00308B',
  '#0D6149',
  '#0F4C50',
  '#354A4C',
];
