import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@components';
import { Save } from '@icons/index';

import { StackViewPdf } from './ComparePlansPdf';
import { PlanDetailsResponse } from '../../../types';

export type DownloadComparePlansPdfProps = {
  planA: PlanDetailsResponse;
  planB: PlanDetailsResponse;
};

export const DownloadComparePlansPdf = ({ planA, planB }: DownloadComparePlansPdfProps) => {
  if (!planA || !planA.name || !planA.buildings || !planA.allocated) {
    return (
      <Button type="secondary" endIcon={<Save />} disabled>
        PDF
      </Button>
    );
  }

  return (
    <PDFDownloadLink
      document={<StackViewPdf planA={planA} planB={planB} />}
      fileName={`${planA?.name}_compare_${planB?.name}.pdf`}
    >
      {({ loading }) => (
        <Button type="secondary" endIcon={<Save />} disabled={loading}>
          PDF
        </Button>
      )}
    </PDFDownloadLink>
  );
};
