import { memo, useState, useEffect } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Typography, Box } from '@mui/material';
import { ArrowV } from '@icons/ArrowV';
import { quickSort, Order } from '@utils/quickSort';
import { Building } from './Building';
import { TeamPlate } from './TeamPlate';
import { ModifyTeamDemand } from './ModifyTeamDemand';
import { PartialTeamAllocate } from './PartialTeamAllocate';
import { ModifyFloor } from './ModifyFloor';
import { PartialFloorLock } from './PartialFloorLock';
import {
  handleDragChanges,
  lockHelper,
  clearFloorHelper,
  unallocateTeamHelper,
  filterByTeam,
  handleChangeTeamData,
  handleChangeAllocateData,
  handleChangeFloorData,
  lockEmptySpaceHelper,
} from './helpers';
import {
  Building as BuildingType,
  Team,
  StackViewState,
  EditTeamDemandState,
  EditFloorData,
  TeamAllocateState,
  FloorPartialLock,
} from '../../types';
import { view as styles } from './styles';

interface StackViewProps {
  data: StackViewState;
  onDataEdit: (v: StackViewState | null) => void;
  filter: string;
  order: Order;
  onChangeOrder: () => void;
}

interface ComponentProps {
  data: StackViewState;
  onDragEnd: (v: DropResult) => void;
  onLockTeam: (fID: string, tID: string, v: boolean) => void;
  onClear: (fID: string) => void;
  onUnallocate: (fId: string) => void;
  invokeTeamDemand: (tID: string) => void;
  invokeTeamAllocate: (fID: string, tID: string) => void;
  invokeFloorAction: (data: { action: string; id: string }) => void;
  order: Order;
  onChangeOrder: () => void;
}

export const StackView = memo(
  ({ data, onDataEdit, filter, order, onChangeOrder }: StackViewProps) => {
    const [isOpenTDSidebar, setTDSidebarStatus] = useState(false);
    const [isOpenTASidebar, setTASidebarStatus] = useState(false);
    const [filtered, setFiltered] = useState<StackViewState>(data);
    const [teamToEdit, setTeamToEdit] = useState<Record<string, string | undefined>>({});
    const [floorToAction, setFloorToAction] = useState<Record<string, string | undefined>>({});

    const handleDragEnd = (results: DropResult) => onDataEdit(handleDragChanges(data, results));
    const handleLockTeam = (floorId: string, teamId: string, value: boolean) =>
      onDataEdit(lockHelper(data, floorId, teamId, value));
    const handleLockEmptySpace = (floorId: string, spaces: FloorPartialLock) => {
      onDataEdit(lockEmptySpaceHelper(data, floorId, spaces));
      handleCloseFloorSidebar();
    };
    const handleClearFloor = (floorId: string) => onDataEdit(clearFloorHelper(data, floorId));
    const handleUnallocateTeam = (floorId: string) =>
      onDataEdit(unallocateTeamHelper(data, floorId));
    const handleSaveTeamData = (teamData: EditTeamDemandState) => {
      onDataEdit(handleChangeTeamData(data, teamData));
      toggleTDSideBar();
    };
    const handleCloseFloorSidebar = () => setFloorToAction({});
    const handleFloorTeamData = (floorData: EditFloorData) => {
      onDataEdit(handleChangeFloorData(data, floorData));
      handleCloseFloorSidebar();
    };
    const handleAllocateTeamData = (
      teamData: TeamAllocateState,
      floorId: string,
      teamId: string,
    ) => {
      onDataEdit(handleChangeAllocateData(data, teamData, floorId, teamId));
      toggleTASideBar();
    };
    const toggleTASideBar = (fId?: string, tId?: string) => {
      setTeamToEdit(tId && fId ? { teamId: tId, floorId: fId } : {});
      setTASidebarStatus((value) => !value);
    };
    const toggleTDSideBar = (id?: string) => {
      setTeamToEdit(id ? { teamId: id } : {});
      setTDSidebarStatus((value) => !value);
    };
    const toggleFDSideBar = ({ action, id }: { action: string; id: string }) => {
      setFloorToAction({ [action]: id });
    };

    useEffect(() => {
      setFiltered(filterByTeam(data, filter));
    }, [data, filter]);

    return (
      <>
        <StackViewComponent
          data={filtered}
          onDragEnd={handleDragEnd}
          onLockTeam={handleLockTeam}
          onClear={handleClearFloor}
          onUnallocate={handleUnallocateTeam}
          invokeFloorAction={toggleFDSideBar}
          invokeTeamDemand={toggleTDSideBar}
          invokeTeamAllocate={toggleTASideBar}
          order={order}
          onChangeOrder={onChangeOrder}
        />
        {isOpenTDSidebar && teamToEdit.teamId && (
          <ModifyTeamDemand
            teamId={teamToEdit.teamId}
            data={data}
            sideBarStatus
            toggleSideBar={toggleTDSideBar}
            onSave={handleSaveTeamData}
          />
        )}
        {isOpenTASidebar && teamToEdit.teamId && teamToEdit.floorId && (
          <PartialTeamAllocate
            IDs={teamToEdit as Record<string, string>}
            data={data}
            sideBarStatus
            toggleSideBar={toggleTASideBar}
            onSave={handleAllocateTeamData}
          />
        )}
        {floorToAction.edit && (
          <ModifyFloor
            floorId={floorToAction.edit}
            data={data}
            sideBarStatus
            onClose={handleCloseFloorSidebar}
            onSave={handleFloorTeamData}
          />
        )}
        {floorToAction.lock && (
          <PartialFloorLock
            floorId={floorToAction.lock}
            data={data}
            onClose={handleCloseFloorSidebar}
            onSave={handleLockEmptySpace}
          />
        )}
      </>
    );
  },
);

const StackViewComponent = ({
  data,
  onDragEnd,
  onLockTeam,
  onClear,
  onUnallocate,
  invokeTeamDemand,
  invokeTeamAllocate,
  invokeFloorAction,
  order,
  onChangeOrder,
}: ComponentProps) => {
  const handlePrepareSortValue = (value: unknown) => data.teamData[value as string].name;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={styles.wrapper}>
        <div>
          <Typography variant="body1" sx={styles.title} onClick={onChangeOrder}>
            Floor
            <ArrowV style={{ transform: order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </Typography>
          <Box sx={{ ...styles.block, ...styles.buildings }}>
            {(data?.buildings || []).map((props: BuildingType) => (
              <Building
                key={props.name}
                onLockTeam={onLockTeam}
                onClear={onClear}
                onTeamAllocate={invokeTeamAllocate}
                teams={data.teamData}
                notes={data.notes.floors}
                isEditable={!data.is_plan_of_record}
                onFloorAction={invokeFloorAction}
                order={order}
                {...props}
              />
            ))}
          </Box>
        </div>
        <Box sx={styles.block}>
          <div>
            <Typography variant="body1" sx={styles.title}>
              Unallocated Teams
            </Typography>
            <Droppable droppableId="unallocated-teams" isDropDisabled={data.is_plan_of_record}>
              {(provider) => (
                <div style={styles.team} ref={provider.innerRef}>
                  {data?.unallocated?.length ? (
                    (
                      quickSort(data.unallocated, 'id', 'asc', handlePrepareSortValue) as Team[]
                    ).map((props: Team, idx: number) => (
                      <Draggable
                        key={props.id}
                        draggableId={`team-${props.id}`}
                        index={idx}
                        isDragDisabled={data.is_plan_of_record}
                      >
                        {(provider, state) => (
                          <div
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                            {...provider.dragHandleProps}
                            style={{
                              boxShadow: state.isDragging
                                ? '0px 0px 15px 0px rgba(0,0,0,0.7)'
                                : 'none',
                              height: 'min-content',
                              ...provider.draggableProps.style,
                            }}
                          >
                            <TeamPlate
                              data={props}
                              team={data.teamData[props.id]}
                              notes={data.notes.teams.find((item) => item.team_id === props.id)}
                              type="unallocated"
                              onTeamEdit={invokeTeamDemand}
                              isEditable={!data.is_plan_of_record}
                              draggable
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Typography variant="body2" sx={styles.emptyMsg}>
                      There are no unallocated teams
                    </Typography>
                  )}
                </div>
              )}
            </Droppable>
          </div>
          <div>
            <Typography variant="body1" sx={styles.title}>
              Allocated Teams
            </Typography>
            <div style={styles.team}>
              {data?.allocated?.length ? (
                (quickSort(data.allocated, 'id', 'asc', handlePrepareSortValue) as Team[]).map(
                  (props: Team) => (
                    <TeamPlate
                      key={props.id}
                      data={props}
                      team={data.teamData[props.id]}
                      notes={data.notes.teams.find((item) => item.team_id === props.id)}
                      type="allocated"
                      onUnallocate={onUnallocate}
                      onTeamEdit={invokeTeamDemand}
                      isEditable={!data.is_plan_of_record}
                    />
                  ),
                )
              ) : (
                <Typography variant="body2" sx={styles.emptyMsg}>
                  There are no allocated teams
                </Typography>
              )}
            </div>
          </div>
        </Box>
      </div>
    </DragDropContext>
  );
};
