import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageSpinner } from '../Spinner';
import { useClientData } from '../../context/ClientData';
import { useAuth } from '../../context/AuthContext';
import { Roles } from '../../types';

interface Props {
  children: JSX.Element;
  permission: Roles;
}

export const ProtectedRoute = ({ permission, children }: Props) => {
  const navigate = useNavigate();
  const { client, checkPermission } = useClientData();
  const { logout, authState } = useAuth();
  const hasPermission = checkPermission(permission);

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      logout();
    }
  }, [authState]);

  useEffect(() => {
    if (client && !hasPermission) {
      navigate('/404');
    }
  }, [client, hasPermission]);

  if (!client) {
    return <PageSpinner isOpen />;
  }

  return children;
};
