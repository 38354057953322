import axios from 'axios';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { useApi } from '@hooks/useApi';
import { useClient } from '@hooks/useClientData';

type AuthContextType = {
  logout: () => void;
  authState: AuthState | null;
  oktaAuth?: OktaAuth;
};

export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  authState: null,
});

const { Provider } = AuthContext;

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { getClient, removeClient } = useClient();
  const api = useApi();

  const injectAuthToken = (type: string, token: string) => {
    axios.defaults.headers.common.Authorization = `${type} ${token}`;
    api.defaults.headers.common.Authorization = `${type} ${token}`;
  };

  const logout = useCallback(() => {
    removeClient();
    oktaAuth.signOut();
  }, [oktaAuth, removeClient]);

  const login = useCallback(async () => {
    await oktaAuth.signInWithRedirect({ originalUri: '/v2/projects' });
  }, [oktaAuth]);

  const updateTokenByExpireTime = (expiresAt: number) => {
    const timeToExpire = expiresAt * 1000 - Date.now() - 10000;
    if (timeToExpire > 0) {
      setTimeout(updateToken, timeToExpire);
    } else {
      updateToken();
    }
  };

  const updateToken = () => {
    oktaAuth.tokenManager.renew('accessToken').then((token: any) => {
      injectAuthToken(token.tokenType, token.accessToken);
      updateTokenByExpireTime(token?.expiresAt);
    });
  };

  const updateClientData = async() => {
    try {
      injectAuthToken(authState!.accessToken?.tokenType!, authState!.accessToken?.accessToken!);
      updateTokenByExpireTime(authState!.accessToken?.expiresAt!);
      const result: any = await getClient();
      if (result.status === 'error') {
        throw new Error(result.error.message);
      }
    } catch(e) {
      logout();
    }
  }

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      login();
    }
    if (authState && authState.isAuthenticated) {
      updateClientData()
    }
  }, [authState, login]);

  const value = useMemo(
    () => ({
      logout,
      authState,
      oktaAuth,
    }),
    [authState, logout, oktaAuth],
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
