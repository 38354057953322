import { IconButton as MUIIconButton, SxProps } from '@mui/material';
import { PRIMARY_SILVER_COLOR } from '@constants/colors';

type ButtonType = 'default' | 'square';

interface Props {
  onClick: (e?: any) => void;
  children: any;
  type?: ButtonType;
  id?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const IconButton = ({
  onClick,
  type = 'default',
  children,
  id = 'defaultId',
  sx = {},
  disabled = false,
}: Props) => {
  const styles =
    type === 'square'
      ? { borderRadius: '0', border: `1px solid ${PRIMARY_SILVER_COLOR[400]}` }
      : {};

  return (
    <MUIIconButton sx={{ ...styles, ...sx }} disabled={disabled} onClick={onClick} aria-label={id}>
      {children}
    </MUIIconButton>
  );
};

export default IconButton;
