import { useEffect, useState } from 'react';
import { Queue } from '@service/Queue';

enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  FAILED = 'failed',
}

type Details = {
  status: Status;
  results?: any;
  error?: any;
};

export const useQueue = <T>(
  cb: any,
  modifier?: (data: T) => any,
  onSuccess?: any,
  onError?: any,
): { enqueue: (data: T) => void; details: Details } => {
  const [details, setDetails] = useState<Details>({ status: Status.INACTIVE });
  const Q: Queue<T> = Queue.getInstance();

  const enqueue = (data: T) => {
    Q.enqueue(data);
    setDetails((state) => ({ ...state, status: Status.ACTIVE }));
  };

  const peek = async () => {
    try {
      const data = Q.peek();
      const results = await cb(modifier ? modifier(data) : data);
      if (onSuccess) {
        onSuccess(results);
      }
      setDetails((state) => ({ ...state, results, error: undefined }));
    } catch (error: any) {
      if (onError) {
        onError(error);
      }
      setDetails((state) => ({ ...state, status: Status.FAILED, error }));
    } finally {
      Q.dequeue();
      checkQueue();
    }
  };

  const checkQueue = () => {
    if (Q.isEmpty()) {
      setDetails((state) => ({ ...state, status: Status.INACTIVE }));
    } else {
      peek();
    }
  };

  useEffect(() => {
    if (details.status === Status.ACTIVE) {
      peek();
    }
  }, [details.status]);

  return { enqueue, details };
};
