import { Droppable } from 'react-beautiful-dnd';
import { Order } from '@utils/quickSort';
import { FloorPlate } from './FloorPlate';
import { handleReverse } from './helpers';
import { TeamData, Floor as FloorType, FloorNote } from '../../types';

interface Props {
  floors: FloorType[];
  name: string;
  teams: TeamData;
  notes?: FloorNote[];
  onLockTeam?: (fID: string, tID: string, v: boolean) => void;
  onClear?: (fID: string) => void;
  onTeamAllocate?: (fID: string, tID: string) => void;
  onFloorAction?: (data: { action: string; id: string }) => void;
  isEditable: boolean;
  order?: Order;
}

export const Building = ({ floors, order, notes, ...props }: Props) => (
  <>
    {handleReverse(floors, order!).map((data: FloorType) => (
      <Droppable
        droppableId={data.id}
        key={data.id}
        direction="horizontal"
        mode="standard"
        isDropDisabled={!props.isEditable}
      >
        {(provider, state) => (
          <div ref={provider.innerRef} {...provider.droppableProps}>
            <FloorPlate
              isDraggingOver={state.isDraggingOver}
              {...props}
              data={data}
              notes={notes?.find((item) => item.floor_id === data.id)}
            />
          </div>
        )}
      </Droppable>
    ))}
  </>
);
