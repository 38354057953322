import { ChangeEvent, FC, useState } from 'react';
import { Typography, FormHelperText } from '@mui/material';
import { SideBar, Button, TextInput, ConfirmationModal } from '@components';
import { Info } from '@icons/Info';
import { teamAllocateSchema } from '../../schemas';
import { sumHelper, groupByTeam } from './helpers';
import { StackViewState, Team, TeamAllocateState, SumCapacity, FloorItem } from '../../types';
import { sidebar as styles } from './styles';

type NumberField = 'workstations' | 'offices';

type StaticDataType = {
  buildingName: string;
  floorName: string;
  availablePlaces: SumCapacity;
  unallocated: SumCapacity;
  maxValue: {
    workstations: number;
    offices: number;
  };
};

interface Props {
  sideBarStatus: boolean;
  toggleSideBar: () => void;
  data: StackViewState;
  IDs: Record<string, string>;
  onSave: (v: TeamAllocateState, fId: string, tId: string) => void;
}

interface ContentProps {
  initialData: TeamAllocateState;
  staticData: StaticDataType;
  onSave: (v: TeamAllocateState) => void;
  onChange: (key: keyof TeamAllocateState, value: string) => void;
}

export const PartialTeamAllocate: FC<Props> = ({
  sideBarStatus,
  toggleSideBar,
  data,
  IDs,
  onSave,
}: Props) => {
  const [modified, setModified] = useState(new Set());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { teamId, floorId } = IDs;

  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const droppableFloor = data.buildings[buildingIdx].floors[floorIdx];
  const floorAllocatedSum = sumHelper(droppableFloor.allocated_resources);
  const floorResourcesSum = sumHelper(droppableFloor.resources);

  const availablePlaces: SumCapacity = {
    1: (floorResourcesSum[1] || 0) - (floorAllocatedSum[1] || 0),
    2: (floorResourcesSum[2] || 0) - (floorAllocatedSum[2] || 0),
    3: floorResourcesSum[3] || 0,
    total:
      (floorResourcesSum[1] || 0) -
      (floorAllocatedSum[1] || 0) +
      (floorResourcesSum[2] || 0) -
      (floorAllocatedSum[2] || 0),
  };

  const teamAtFloor = groupByTeam(
    droppableFloor.allocated_resources.filter((item: FloorItem) => item.team === teamId),
  )[teamId];

  const unallocated = data.unallocated.find((team: Team) => team.id === teamId);
  const totalSum = data.teamData[teamId].resources;

  const note =
    data.notes.floors
      .find((item) => item.floor_id === floorId)
      ?.teams?.find((item) => item.team_id === teamId)?.message || '';

  const initialFormData: TeamAllocateState = {
    workstations: teamAtFloor[1] || 0,
    offices: teamAtFloor[2] || 0,
    collaborativeSpace: totalSum[3] || 0,
    note,
  };

  const staticData: StaticDataType = {
    buildingName: data.buildings[buildingIdx].name,
    floorName: droppableFloor.name,
    availablePlaces,
    unallocated: unallocated ? unallocated.unallocated : { 1: 0, 2: 0, total: 0 },
    maxValue: {
      workstations:
        (availablePlaces[1] < (unallocated?.unallocated[1] || 0)
          ? availablePlaces[1]
          : unallocated?.unallocated[1] || 0) + (teamAtFloor[1] || 0),
      offices:
        (availablePlaces[2] < (unallocated?.unallocated[2] || 0)
          ? availablePlaces[2]
          : unallocated?.unallocated[2] || 0) + (teamAtFloor[2] || 0),
    },
  };

  const handleSave = (state: TeamAllocateState) => onSave(state, floorId, teamId);

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);
  const handleShowConfirmationDialog = () => {
    if (modified.size) {
      toggleConfirmationDialog();
    } else {
      toggleSideBar();
    }
  };
  const handleModifyingCheck = (key: keyof TeamAllocateState, value: string) => {
    const isModified = initialFormData[key] !== +value;
    setModified((state) => {
      const isPresent = state.has(key);
      if (isModified) {
        return isPresent ? new Set(state) : new Set(state.add(key));
      }
      state.delete(key);
      return new Set(state);
    });
  };

  return (
    <>
      <SideBar isOpen={sideBarStatus} onClose={handleShowConfirmationDialog}>
        <SidebarContent
          initialData={initialFormData}
          staticData={staticData}
          onSave={handleSave}
          onChange={handleModifyingCheck}
        />
      </SideBar>
      {isOpen && (
        <ConfirmationModal
          isOpen
          onCancel={toggleConfirmationDialog}
          onLeave={toggleSideBar}
          title="You have unsaved changes"
          message="Changes will be lost if you leave without saving."
          successText="Return to editing"
          errorText="Discard changes and leave"
        />
      )}
    </>
  );
};

const SidebarContent = ({ initialData, onSave, staticData, onChange }: ContentProps) => {
  const [formData, setFormData] = useState<TeamAllocateState>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { buildingName, floorName, availablePlaces, unallocated, maxValue } = staticData;

  const handleSave = () => {
    if (!Object.keys(errors).length) {
      onSave(formData);
    }
  };

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: TeamAllocateState) => ({ ...data, [id]: value }));
      onChange(id as keyof TeamAllocateState, value);
      await teamAllocateSchema.validateAt(
        id,
        { ...formData, [id]: value },
        { context: { max: maxValue[id as NumberField] } },
      );
      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  return (
    <div style={styles.wrapper}>
      <Button onClick={handleSave}>Save</Button>
      <div style={styles.header}>
        <Typography variant="h1" component="h1">
          Team Allocation Adjustment
        </Typography>
        <Typography variant="body1" component="h6">
          <span style={styles.subtitle}>{`${buildingName}. ${floorName}`}</span>
        </Typography>
        <Typography variant="body2">
          {`Available Collaborative Seats: ${availablePlaces[3]} seats`}
        </Typography>
      </div>
      <div style={{ ...styles.grid, ...styles.gridBottom, ...styles.mt30 }}>
        <TextInput
          id="workstations"
          label="Workstations"
          value={formData.workstations}
          onChange={handleFormChange}
          error={!!errors.workstations}
          type="number"
          InputProps={{ inputProps: { min: 0, max: maxValue.workstations } }}
          sx={{ gridArea: 'a' }}
        />
        <TextInput
          id="offices"
          label="Offices"
          value={formData.offices}
          onChange={handleFormChange}
          error={!!errors.offices}
          type="number"
          InputProps={{ inputProps: { min: 0, max: maxValue.offices } }}
          sx={{ gridArea: 'b' }}
        />
        <TextInput
          id="collaborativeSpace"
          label="Collaborative Seats"
          value={formData.collaborativeSpace}
          onChange={handleFormChange}
          error={!!errors.collaborativeSpace}
          type="number"
          sx={{ gridArea: 'c' }}
          disabled
        />
        <FormHelperText
          error={!!errors.workstations || !!errors.offices || !!errors.collaborativeSpace}
          sx={{ gridArea: 'd' }}
        >
          {errors.workstations || errors.offices || errors.collaborativeSpace}
        </FormHelperText>
      </div>
      <div style={styles.note}>
        <TextInput
          id="note"
          label="Note"
          multiline
          minRows={4}
          value={formData.note || ''}
          onChange={handleFormChange}
          error={!!errors.note}
          helperText={errors.note}
        />
        <p style={styles.hint}>
          <Info size={12} />
          This note is relevant for the specific team allocation at the floor. It will be removed
          automatically if the team relocates.
        </p>
      </div>
      <div style={styles.footer}>
        <Typography variant="body2">
          <span style={styles.subtitle}>Free Space on Floor:</span>
          <p style={styles.info}>
            <span>Total Seats: {availablePlaces?.total || 0}</span>
            <span>Workstations: {availablePlaces?.[1] || 0}</span>
            <span>Offices: {availablePlaces?.[2] || 0}</span>
          </p>
        </Typography>
        <Typography variant="body2">
          <span style={styles.subtitle}>Unallocated Team:</span>
          <p style={styles.info}>
            <span>Total Seats: {unallocated?.total || 0}</span>
            <span>Workstations: {unallocated?.[1] || 0}</span>
            <span>Offices: {unallocated?.[2] || 0}</span>
          </p>
        </Typography>
      </div>
    </div>
  );
};
