// TEMP FILE
// SHOULD BE RAPLACED WITH HOOKS IN FUTURE

import { baseUrl, getQuery, postQuery, delQuery } from '@service/index';
import { Adjacency } from './adjacency-form/types';

export const getUploadStatus = (projectId: string) => {
  const url = `${baseUrl}occupancy-planning/api/check-data-upload?project_id=${projectId}`;
  return getQuery(url);
};

export const getTeamSchemaAdjacencies = async (projectId: string) => {
  // const url = `${baseUrl}occupancy-planning/api/adj-form?project_id=${projectId}`;
  const url = `${baseUrl}occupancy-planning/api/schema/Adjacencies?project_id=${projectId}`;
  let parsedResponse;
  await getQuery(url).then((res) => {
    const data = { ...res.data };
    if (data.schema) {
      // The first element of the schema array is information only
      data.schema.splice(0, 1);
      parsedResponse = data.schema.map(({ label, key }: { label: string; key: string }) => ({
        label,
        value: key,
      }));
    }
  });
  return parsedResponse;
};

export const getUserSavedAdjacencies = () => {
  const url = `${baseUrl}occupancy-planning/api/adj-form`;
  return getQuery(url);
};

export const getSchema = (id: string) => {
  const url = `${baseUrl}occupancy-planning/api/schema/${id}`;
  return getQuery(url);
};

export const saveAdjacencies = (customerId: string, projectId: string, adjData: Adjacency[]) => {
  const url = `${baseUrl}occupancy-planning/api/adj-form`;
  const body = {
    data: {
      customer_id: Number(customerId),
      project_id: Number(projectId),
      teams_adjacency: adjData,
    },
  };
  return postQuery(url, body);
};

export const saveMappedData = (
  schemaId: string,
  customerId: string,
  columns: any,
  data: any,
  projectId: string,
) => {
  const url = `${baseUrl}common/api/data?schemaId=${schemaId}&customerId=${customerId}&project_id=${projectId}`;
  const body = {
    data: {
      columns,
      data,
    },
  };
  return postQuery(url, body);
};

export const getSchemaList = () => {
  const url = `${baseUrl}occupancy-planning/api/schema`;
  return getQuery(url);
};

export const deleteCustomerOPData = (customerId: string) => {
  const url = `${baseUrl}occupancy-planning/api/cleanup/${customerId}`;
  return delQuery(url);
};
