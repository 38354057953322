import { Box, TextField, TextFieldProps, InputLabel, SxProps } from '@mui/material';
import { Info } from '@icons/Info'
import { Tooltip } from '../Tooltip';

type Props = TextFieldProps & {
  labelTooltip?: string;
  labelSx?: SxProps;
}

const TextInput = ({ id, required, label, labelTooltip, labelSx = {}, sx = {}, ...props }: Props) => (
  <Box sx={{ width: '100%', ...sx }}>
    <InputLabel htmlFor={id} sx={labelSx}>
      {label}
      {labelTooltip && <Tooltip tooltip={labelTooltip}><span style={{ margin: '3px 0 0 3px' }}><Info size={12} /></span></Tooltip> }
      {required ? ' *' : ''}
    </InputLabel>
    <TextField id={id} fullWidth {...props} />
  </Box>
);

export default TextInput;
