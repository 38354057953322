export const Copy = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33464 0.333008V2.99967H0.667969V13.6663H8.66797V10.9997H11.3353V0.333008H3.33464ZM4.66864 1.66634H10.002V9.66567H8.66797V2.99967H4.66864V1.66634ZM3.33464 4.33301H2.0013V12.333H7.33463V10.9997V9.66567V4.33301H4.66863H3.33464Z"
      fill="#85868C"
    />
  </svg>
);
