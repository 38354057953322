import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Typography, MenuItem, Divider, IconButton } from '@mui/material';

import { PRIMARY_COLORS } from '@constants/colors';
import { MenuByClick, Tooltip, OverflowTooltip } from '@components';
import { MenuDots, ForwardArrow, Info, Drag, Message } from '@icons/index';
import { Plan } from '../../types';

import { displayFreeFloors } from './helpers';
import styles from './PlanCard.module.css';

interface Props {
  data: Plan;
  projectId: string;
  onAction: (v: Record<string, number>) => void;
  isDraggable: boolean;
  dragBy?: any;
}

export const PlanCard = ({
  data: {
    id,
    name,
    description,
    free_floors,
    updated_at,
    updated_by,
    adj_value,
    seats,
    workstations,
    private_offices,
    population,
    strategy,
    is_plan_of_record,
  },
  projectId,
  onAction,
  isDraggable,
  dragBy,
}: Props) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/v2/projects/${projectId}/plans/${id}`);

  const prepareOptions = () => {
    const planOfRecordLabel = is_plan_of_record
      ? 'Unset as Plan of Record'
      : 'Set as Plan of Record';

    return [
      {
        label: 'Edit Details',
        callback: () => onAction({ edit: id }),
        config: { disabled: is_plan_of_record },
      },
      {
        label: 'Duplicate Plan',
        callback: () => onAction({ duplicate: id }),
      },
      {
        label: planOfRecordLabel,
        callback: () => onAction({ planOfRecord: id }),
        config: { dividerAfter: true },
      },
      {
        label: 'Delete',
        callback: () => onAction({ delete: id }),
        config: { color: PRIMARY_COLORS.PRIMARY_RED, disabled: is_plan_of_record },
      },
    ];
  };

  const renderPlanOfRecordLabel = () =>
    is_plan_of_record && (
      <>
        <div className={styles.planOfRecordCircle} /> <span>Plan of Record,</span>
      </>
    );

  return (
    <div className={`${styles.wrapper} ${is_plan_of_record && styles.planOfRecord}`}>
      {isDraggable && dragBy && (
        <span className={styles.dragBy} {...dragBy}>
          <Drag color={PRIMARY_COLORS.PRIMARY_SILVER} />
        </span>
      )}
      <div>
        <Typography className={styles.title} variant="h2">
          <OverflowTooltip>{name}</OverflowTooltip>
          {description && (
            <Tooltip tooltip={description}>
              <span className={styles.message}>
                <Message />
              </span>
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2">
          <OverflowTooltip>
            <div className={styles.strategyRow}>
              {renderPlanOfRecordLabel()}
              {strategy} Algorithm
            </div>
          </OverflowTooltip>
        </Typography>
        <div className={styles.menu}>
          <MenuByClick id="plan-card-menu" menuItems={<MenuItems items={prepareOptions()} />}>
            <MenuDots />
          </MenuByClick>
        </div>
      </div>
      <div className={styles.grid}>
        <KeyValue label="Allocated Seats" value={`${seats.allocated} of ${seats.total}`} />
        <KeyValue
          label="Unallocated Demand"
          value={`${population.unallocated} of ${population.total}`}
        />
        <KeyValue
          label="Workstations"
          value={`${workstations.allocated} of ${workstations.total}`}
        />
        <KeyValue
          label="Offices"
          value={`${private_offices.allocated} of ${private_offices.total}`}
        />
        <KeyValue label="Modified Date" value={moment(updated_at).format('D MMM YYYY, HH:mm')} />
        <KeyValue label="Modified By" value={updated_by} />
      </div>
      <span className={styles.line} />
      <div className={styles.grid}>
        <KeyValue
          label="Adjacency Score"
          value={adj_value ? `${adj_value} of 5` : 'N/A'}
          tooltip="The higher is the score, the more efficient is the plan from the point of view of the team adjacency requirements"
        />
        <KeyValue
          label="Free Floors"
          value={displayFreeFloors(free_floors)}
          tooltip="Sum of floors that are either completely free (counts as +1 to the score) or at least half-free (+0.5 to the score)"
        />
      </div>
      <IconButton
        aria-label="row-actions"
        aria-controls="plan-row-actions"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ position: 'absolute', bottom: '15px', right: '15px' }}
      >
        <ForwardArrow />
      </IconButton>
    </div>
  );
};

export const KeyValue = ({ label, value, tooltip }: any) => (
  <div>
    <Typography variant="h5" className={styles.key}>
      {label}
      {tooltip && (
        <Tooltip tooltip={tooltip}>
          <span style={{ margin: '5px 0 0 5px' }}>
            <Info />
          </span>
        </Tooltip>
      )}
    </Typography>
    <Typography variant="body1">
      <OverflowTooltip>{value}</OverflowTooltip>
    </Typography>
  </div>
);

const MenuItems = ({ items }: any) =>
  items.map(({ callback, label, config }: any) => (
    <div key={label}>
      <MenuItem sx={{ color: config?.color }} disabled={!!config?.disabled} onClick={callback}>
        {label}
      </MenuItem>
      {config?.dividerAfter && <Divider sx={{ width: '80%', margin: 'auto' }} />}
    </div>
  ));
