import { Document, Page, View, Text } from '@react-pdf/renderer';

import moment from 'moment';

import { sumHelper, groupByTeam } from '../../plan-details/helpers';
import { Team, PlanDetailsResponse, Floor, TeamData } from '../../../types';

import { styles } from './styles';

export type StackViewPdfProps = {
  planA: PlanDetailsResponse;
  planB: PlanDetailsResponse;
};

interface ItemProps {
  '1'?: number;
  '2'?: number;
  '3'?: number;
  total: number;
  locked: boolean;
  team: string;
}

type TeamBoxProps = {
  name: string;
  color: string;
  seats: number;
  totalSeats: number;
};

const TeamBox = ({ name, color, seats, totalSeats }: TeamBoxProps) => (
  <View style={styles.team}>
    <View style={{ ...styles.teamColor, backgroundColor: color }} />
    <View style={styles.teamInfo}>
      <Text style={styles.teamName}>{name}</Text>
      <Text style={styles.teamSize}>
        {totalSeats ? `${seats}/${totalSeats} seats` : `${seats} seats`}
      </Text>
    </View>
  </View>
);

const Status = () => <Text style={styles.status}>[plan of record]</Text>;

type FloorPlateProps = {
  name: string;
  floor: Floor;
  teams: TeamData;
};

const FloorPlate = ({ name, floor, teams }: FloorPlateProps) => {
  const allocatedTotal = sumHelper(floor?.allocated_resources);
  const resourcesTotal = sumHelper(floor?.resources);
  const dataGroupedByTeam: Record<string, ItemProps> = groupByTeam(floor?.allocated_resources);

  const renderFloorFilling = () => {
    if (Object.keys(dataGroupedByTeam).length) {
      return Object.values(dataGroupedByTeam).map((item: ItemProps, idx: number) => {
        if (item.team) {
          return (
            <View
              key={item.team}
              style={{
                ...styles.teamOnFloor,
                width: `${((item.total || 0) / resourcesTotal.total) * 100}%`,
                backgroundColor: teams[item.team]?.color,
              }}
            >
              <Text style={styles.teamAmount}>{item.total}</Text>
            </View>
          );
        }

        return (
          <View
            key={`${idx}-empty-floor`}
            style={{
              ...styles.teamOnFloor,
              ...styles.emptyFloor,
              ...styles.lockedEmpty,
              width: `${((item.total || 0) / resourcesTotal.total) * 100}%`,
            }}
          >
            <Text style={styles.whiteEmptyText}>{item.total}</Text>
          </View>
        );
      });
    }

    return (
      <View style={{ ...styles.teamOnFloor, ...styles.emptyFloor }}>
        <Text style={styles.emptyFloorText}>empty floor</Text>
      </View>
    );
  };

  return (
    <View wrap={false} style={styles.floor}>
      <View style={styles.floorInfo}>
        <View style={styles.floorMetaTop}>
          <View style={styles.floorMetaLeft}>
            <Text style={styles.floorNumber}>{floor?.name}</Text>
            <Text>{name}</Text>
          </View>
          <View style={styles.floorMetaRight}>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.['2'] || 0}/${
              resourcesTotal['2'] || 0
            } offices`}</Text>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.['1'] || 0}/${
              resourcesTotal['1'] || 0
            } workstations`}</Text>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.total || 0}/${
              resourcesTotal.total || 0
            } seats`}</Text>
          </View>
        </View>
        <View style={styles.floorPlate}>
          <View style={styles.floorFilling}>{renderFloorFilling()}</View>
        </View>
      </View>
    </View>
  );
};

const TeamDataList = ({ floor, teams }: Omit<FloorPlateProps, 'name'>) => {
  const dataGroupedByTeam: Record<string, ItemProps> = groupByTeam(floor?.allocated_resources);

  const renderTeams = () => {
    if (Object.keys(dataGroupedByTeam).length) {
      return Object.values(dataGroupedByTeam).map((item: ItemProps) => {
        if (item.team) {
          const { color, name, resources } = teams[item.team];
          const totalTeamAmount = resources.total || 0;
          return (
            <TeamBox
              key={item.team}
              name={name}
              color={color}
              seats={item.total}
              totalSeats={totalTeamAmount}
            />
          );
        }

        return (
          <TeamBox
            key={item.team}
            name="Locked empty"
            color="#778F9C"
            seats={item.total}
            totalSeats={0}
          />
        );
      });
    }

    return null;
  };
  return <View style={styles.floorPlate}>{renderTeams()}</View>;
};

const UnAllocatedTeamsSection = ({
  unallocatedTeams,
  teams,
}: {
  unallocatedTeams: Team[];
  teams: TeamData;
}) => {
  if (unallocatedTeams?.length) {
    return (
      <View wrap={false} style={{ ...styles.floorPlate, ...styles.unAllocatedRow }}>
        <Text style={styles.unallocatedTeams}>unallocated teams:</Text>

        <View style={styles.floorPlate}>
          {unallocatedTeams.map((team) => (
            <TeamBox
              key={team.id}
              name={teams[team.id].name}
              color={teams[team.id].color}
              seats={team.unallocated.total}
              totalSeats={teams[team.id].resources.total}
            />
          ))}
        </View>
      </View>
    );
  }

  return null;
};

const MetaInfo = ({ plan }: { plan: PlanDetailsResponse }) => (
  <View wrap={false} style={styles.metaContainer}>
    <View style={styles.metaContainerRow}>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Allocated Seats</Text>
        <Text>{`${plan?.seats.allocated} of ${plan?.seats.total}`}</Text>
      </View>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Unallocated Demand</Text>
        <Text>{`${plan?.population.unallocated} of ${plan?.population.total}`}</Text>
      </View>
    </View>

    <View style={styles.metaContainerRow}>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Workstations</Text>
        <Text>{`${plan?.workstations.allocated} of ${plan?.workstations.total}`}</Text>
      </View>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Offices</Text>
        <Text>{`${plan?.private_offices.allocated} of ${plan?.private_offices.total}`}</Text>
      </View>
    </View>

    <View style={styles.metaContainerRow}>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Modified Date</Text>
        <Text>{moment(plan?.updated_at).format('D MMM YYYY, HH:mm')}</Text>
      </View>
      <View style={styles.metaContainerItem}>
        <Text style={styles.metaContainerLabel}>Modified By</Text>
        <Text>{plan?.updated_by}</Text>
      </View>
    </View>
  </View>
);

export const StackViewPdf = ({ planA, planB }: StackViewPdfProps) => (
  <Document>
    <Page orientation="landscape" wrap style={styles.container}>
      <View style={styles.row}>
        <View style={styles.planContainer}>
          <Text style={styles.title}>
            {planA?.name} {planA?.is_plan_of_record && <Status />}
          </Text>
        </View>
        <View style={styles.planContainer}>
          <Text style={styles.title}>
            {planB?.name} {planB?.is_plan_of_record && <Status />}
          </Text>
        </View>
      </View>

      {planA.buildings.map((building, bIdx) =>
        building.floors.map((_, fIdx) => (
          <>
            <View style={styles.row}>
              <View style={styles.planContainer}>
                <FloorPlate
                  key={planA?.buildings[bIdx].floors[fIdx].id}
                  name={planA?.buildings[bIdx].name}
                  floor={planA?.buildings[bIdx].floors[fIdx]}
                  teams={planA?.teamData}
                />
              </View>
              <View style={styles.planContainer}>
                <FloorPlate
                  key={planB?.buildings[bIdx].floors[fIdx].id}
                  name={planB?.buildings[bIdx].name}
                  floor={planB?.buildings[bIdx].floors[fIdx]}
                  teams={planA?.teamData}
                />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.planContainer}>
                <TeamDataList
                  key={planA?.buildings[bIdx].floors[fIdx].id}
                  floor={planA?.buildings[bIdx].floors[fIdx]}
                  teams={planA?.teamData}
                />
              </View>
              <View style={styles.planContainer}>
                <TeamDataList
                  key={planB?.buildings[bIdx].floors[fIdx].id}
                  floor={planB?.buildings[bIdx].floors[fIdx]}
                  teams={planA?.teamData}
                />
              </View>
            </View>
          </>
        )),
      )}

      <View style={styles.row}>
        <View style={styles.planContainer}>
          <UnAllocatedTeamsSection unallocatedTeams={planA?.unallocated} teams={planA?.teamData} />
        </View>
        <View style={styles.planContainer}>
          <UnAllocatedTeamsSection unallocatedTeams={planB?.unallocated} teams={planB?.teamData} />
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.planContainer}>
          <MetaInfo plan={planA} />
        </View>
        <View style={styles.planContainer}>
          <MetaInfo plan={planB} />
        </View>
      </View>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);
