import { object, string, number, array } from 'yup';

const checkDynamicMinMax = (value: any, { options, createError }: any) => {
  if (
    ((options.context.min || typeof options.context.min === 'number') &&
      options.context.min > value) ||
    ((options.context.max || typeof options.context.max === 'number') &&
      options.context.max < value)
  ) {
    return createError({ path: options.path, message: options.context.message });
  }
  return true;
};

const checkDynamicMax = (value: any, { options, createError }: any) => {
  if (options.context.max < value) {
    return createError({
      path: options.path,
      message:
        'Please change the number of seats allocated to not exceed the number of Free Seats on the floor and Unallocated Team Demand.',
    });
  }
  if (value < 0) {
    return createError({
      path: options.path,
      message: 'Value can`t be less than 0',
    });
  }
  return true;
};

export const createProjectSchema = object().shape({
  name: string().trim().max(255).required().label('Project name'),
  customerId: number().required().label('Client'),
  description: string().trim().max(1024).optional().label('Description'),
});

export const savePlanSchema = object().shape({
  name: string().trim().max(255).required().label('Plan name'),
  description: string().trim().max(1024).optional().label('Description'),
});

export const editTeamDemandSchema = object().shape({
  name: string().trim().max(255).required().label('Team Name'),
  color: string().required().label('Color Legend'),
  workstations: number()
    .typeError('Workstations should be a number and can`t be empty')
    .required()
    .test(checkDynamicMinMax)
    .label('Workstations'),
  offices: number()
    .typeError('Offices should be a number and can`t be empty')
    .required()
    .test(checkDynamicMinMax)
    .label('Offices'),
  collaborativeSpace: number()
    .typeError('Collaborative Seats should be a number and can`t be empty')
    .required()
    .test(checkDynamicMinMax)
    .label('Collaborative Seats'),
  sharing_ratio: number()
    .typeError('Sharing Ratio should be a number and can`t be empty')
    .min(0.1)
    .required()
    .test(checkDynamicMinMax)
    .label('Sharing Ratio'),
  note: string().trim().max(1024).optional().label('Note'),
});

export const generateNewPlanSchema = object().shape({
  name: string().trim().max(255).required().label('Plan Name'),
  description: string().trim().max(1024).optional().label('Description'),
  strategies: array().of(number().required()).required(),
});

export const teamAllocateSchema = object().shape({
  workstations: number()
    .typeError('Workstations should be a number and can`t be empty')
    .required()
    .test(checkDynamicMax)
    .label('Workstations'),
  offices: number()
    .typeError('Offices should be a number and can`t be empty')
    .required()
    .test(checkDynamicMax)
    .label('Offices'),
  collaborativeSpace: number().required().label('Collaborative Seats'),
  note: string().trim().max(1024).optional().label('Note'),
});

export const editFloorSchema = object().shape({
  workstations: number()
    .typeError('Workstations should be a number')
    .min(0)
    .required()
    .test(checkDynamicMinMax)
    .label('Workstations'),
  offices: number()
    .typeError('Offices should be a number')
    .min(0)
    .required()
    .test(checkDynamicMinMax)
    .label('Offices'),
  collaborativeSpace: number()
    .typeError('Collaborative Seats should be a number')
    .min(0)
    .required()
    .label('Collaborative Seats'),
  note: string().trim().max(1024).optional().label('Note'),
});

export const editFloorLocks = object().shape({
  workstations: number()
    .typeError('Workstations should be a number')
    .min(0)
    .required()
    .test(checkDynamicMinMax)
    .label('Workstations'),
  offices: number()
    .typeError('Offices should be a number')
    .min(0)
    .required()
    .test(checkDynamicMinMax)
    .label('Offices'),
});
