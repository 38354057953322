// eslint-disable-next-line no-underscore-dangle
const _fieldTypesAdapter = (Schema) => Schema.reduce((prev, curr) => ({
  ...prev,
  [curr.key]: curr.fieldType,
}), {});

const correctOutpuFormat = (records, matchedFields, Schema) => {
  const fieldTypes = _fieldTypesAdapter(Schema);
  return records.map(({ data: record }) => (
    matchedFields.map((c) => {
      if (fieldTypes[c] === 'int') {
        return parseInt(record[c], 10);
      }
      if (fieldTypes[c] === 'float') {
        return parseFloat(record[c]);
      }
      return record[c];
    })
  ));
};

export default correctOutpuFormat;
