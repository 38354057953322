import { CSSProperties, FC, JSX } from 'react';
import { Drawer } from '@mui/material';
import { Close } from '@icons/index';
import styles from './SideBar.module.css';

export enum Anchor {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  anchor?: Anchor;
  sx?: CSSProperties;
}

const SideBar: FC<Props> = ({
  isOpen,
  onClose,
  anchor = Anchor.RIGHT,
  children,
  sx = {},
}: Props) => (
  <Drawer anchor={anchor} open={isOpen} onClose={onClose} sx={sx}>
    <div className={styles.wrapper}>
      <span className={styles.closeButton} onClick={onClose}>
        <Close />
      </span>
      {children}
    </div>
  </Drawer>
);

export default SideBar;
