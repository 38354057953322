import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@components';
import { DownloadComparePlansPdf } from '../download-pdf';
import { actionWrapper as styles } from './styles';
import { PlanDetailsResponse } from '../../types';

type Props = {
  planA: PlanDetailsResponse;
  planB: PlanDetailsResponse;
};

export const ActionBar = ({ planA, planB }: Props) => {
  const { projectId, planIdA } = useParams();
  const navigate = useNavigate();

  const handleComplete = () => navigate(`/v2/projects/${projectId}/plans/${planIdA}`);

  return (
    <div style={styles}>
      <DownloadComparePlansPdf planA={planA} planB={planB} />
      <Button onClick={handleComplete}>Complete Comparison</Button>
    </div>
  );
};
