import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePlans } from '@hooks/usePlans';
import { useProject } from '@hooks/useProject';
import { useHeaderData } from '@hooks/useHeaderData';
import { useUploadedFiles } from '@hooks/useUploadFiles';
import { ItemsNumber, PageSpinner, Tabs } from '@components';

import { GeneratePlan } from '../generate-new-plan';
import { PlanList } from './PlanList';
import { SpaceData } from './SpaceData';
import { TeamsData } from './TeamsData';
import { UploadedFilesData } from './UploadedFiles';
import { BreadcrumbOptions, Plan, ProjectFloor, ProjectTeam, UploadedFile } from '../../types';

enum TabList {
  PLAN = 'Plans',
  SPACE = 'Space',
  TEAMS = 'Teams',
  FILES = 'Uploaded Files',
}

const OccupancyPlanListWithHeader = () => {
  const { projectId } = useParams();
  const { planList, isLoading } = usePlans(projectId!);
  const { data: project, isLoading: isProjectLoading } = useProject(projectId!, true);
  const { uploadedFiles } = useUploadedFiles(Number(projectId));

  const breadcrumbList: BreadcrumbOptions[] = [
    { label: 'Occupancy Projects', link: '/v2/projects' },
    {
      label: project?.customer_name || '',
      link: `/v2/projects${project ? `?customer_name=${project.customer_name}` : ''}`,
    },
  ];

  useHeaderData({
    title: project?.name || '',
    breadcrumb: breadcrumbList,
    ActionBar: GeneratePlan,
  });


  return (
    <>
      <OccupancyPlanList
        plans={planList}
        floors={project?.floors || []}
        teams={project?.teams || []}
        uploadedFiles={uploadedFiles?.data || []}
      />
      <PageSpinner isOpen={isLoading || isProjectLoading} />
    </>
  );
};

const OccupancyPlanList = ({
  plans,
  floors,
  teams,
  uploadedFiles,
}: {
  plans?: Plan[];
  floors: ProjectFloor[];
  teams: ProjectTeam[];
  uploadedFiles: UploadedFile[];
}) => {
  const [activeTab, setTab] = useState(0);

  if (!plans) return;

  const TabMap = {
    [TabList.PLAN]: <PlanList data={plans} />,
    [TabList.SPACE]: <SpaceData data={floors} />,
    [TabList.TEAMS]: <TeamsData data={teams} />,
    [TabList.FILES]: <UploadedFilesData data={uploadedFiles} />,
  };

  const tabs = Object.values(TabList).map((t: string) => {
    if (t === TabList.PLAN) {
      return `${t} (${plans?.length || '0'})`;
    }
    return t;
  });

  const handleChange = (v: number) => setTab(v);

  const getItemsNumber = () => {
    switch (activeTab) {
      case 1:
        return <ItemsNumber number={floors?.length || 0} label="Floor" plural="Floors" />;
      case 2:
        return <ItemsNumber number={teams?.length || 0} label="Team" plural="Teams" />;
      case 3:
        return <ItemsNumber number={uploadedFiles?.length || 0} label="File" plural="Files" />;
      case 0:
      default:
        return <ItemsNumber number={plans?.length || 0} label="Plan" plural="Plans" />;
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs active={activeTab} tabs={tabs} onChange={handleChange} />
        {getItemsNumber()}
      </div>
      <div style={{ marginTop: '32px' }}>{Object.values(TabMap)[activeTab]}</div>
    </>
  );
};

export { OccupancyPlanListWithHeader as OccupancyPlanList };
