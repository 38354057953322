export const Save = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9999 20.9999H2.99985L2.99985 11.9999H4.99985V18.9999H18.9999V11.9999H20.9999V20.9999ZM12.9994 13.1712L16.2434 9.92918L17.6574 11.3432L13.4134 15.5862L12.0004 17.0002L10.5864 15.5862L6.34235 11.3432L7.75735 9.92918L11.0004 13.1712L11.0004 3.00018H12.9994L12.9994 13.1712Z"
      fill="#435254"
    />
  </svg>
);
