interface Props {
  size?: number;
}
export const Info = ({ size = 16 }: Props) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.582 3.582 0 8 0C12.418 0 16 3.582 16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8ZM14 8C14 4.691 11.309 2 8 2C4.691 2 2 4.691 2 8C2 11.309 4.691 14 8 14C11.309 14 14 11.309 14 8ZM9 11.9999V6.9999H7V11.9999H9ZM8 5.9999C7.44772 5.9999 7 5.55219 7 4.9999C7 4.44762 7.44772 3.9999 8 3.9999C8.55229 3.9999 9 4.44762 9 4.9999C9 5.55219 8.55229 5.9999 8 5.9999Z"
        fill="#778F9C"
      />
    </g>
  </svg>
);
