const Disabled = () => <div className="disabled-circle" />;
const Completed = () => (
  <div className="checkmark-circle-parent">
    <div className="checkmark-circle" />
  </div>
);
const Default = () => (<div className="dotted-circle" />);

export {
  Disabled,
  Completed,
  Default,
};
