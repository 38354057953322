import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties
}

export const Expand = ({ style = {}}: Props) => (
  <svg width="12" height="8" style={style} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1.99631L10.586 0.582306L6.001 5.16831L1.414 0.582306L0 1.99631L4.586 6.58231L6.001 7.99631L7.415 6.58231L12 1.99631Z" fill="#778F9C"/>
  </svg>
);