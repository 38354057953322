export const Message = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.333V1H15V12H4L1 14.999V12V10.333ZM12 5H4V4H12V5ZM4 6.999H12V5.999H4V6.999ZM12 8.999H4V7.999H12V8.999Z"
        fill="#778F9C"
      />
    </g>
  </svg>
);
