import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useApi } from './useApi';
import { useAlert } from '../context/AlertContext';
import {
  Plan,
  GeneratePlanBody,
  GenerateNewPlanForm,
  PlanDetailsResponse,
  DuplicatePlan,
} from '../types';

export const usePlans = (id: string) => {
  const api = useApi();
  const alert = useAlert();

  const planListQuery = async () => {
    const response = await api.get<Plan[]>(`/occupancy-planning/api/plan?project_id=${id}`);
    return response?.data;
  };

  const planList = useQuery(['planList', id], planListQuery, {
    enabled: !!id,
    keepPreviousData: true,
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });

  return {
    planList: planList.data,
    isLoading: planList.isLoading,
    isFetching: planList.isFetching,
  };
};

export const useEditPlan = (projectId: string) => {
  const api = useApi();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const editPlanRequest = async ({ planId, data }: { data: Partial<PlanDetailsResponse>, planId: number }) => {
    const isEdit = !!data.name;
    queryClient.setQueryData(['planList', projectId], (oldData: any) => {
      if (oldData) {
        const idx = oldData.findIndex((item: Plan) => item.id === planId);
        if (idx >= 0) {
          const dataToEdit = [...oldData];
          if (isEdit) {
            dataToEdit[idx] = { ...dataToEdit[idx], ...data };
          } else {
            dataToEdit.splice(idx, 1);
          }
          return dataToEdit;
        }
      }
      return oldData;
    });

    if (isEdit) {
      queryClient.setQueryData(['planDetails', planId], (oldData: any) => {
        if (oldData) {
          return {
            ...oldData,
            name: data.name,
            description: data.description,
          }
        }
        return oldData;
      });
    }
    return api.patch(`/occupancy-planning/api/plan/${planId}`, { ...data });
  }

  return useMutation(editPlanRequest, {
    onSuccess: (_, { data }) => {
      const isEdit = !!data.name;
      alert(
        'success',
        isEdit ? `Plan ${data.name} successfully edited` : 'Plan successfully deleted',
      );
    },
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        return;
      }
      alert('error', e, e?.response?.data?.status);
    },
  });
};

export const useDuplicatePlan = () => {
  const api = useApi();
  const alert = useAlert();

  const duplicatePlanRequest = async (data: DuplicatePlan) =>
    api.post('/occupancy-planning/api/plan', data);

  return useMutation(duplicatePlanRequest, {
    onSuccess: (_, payload) => alert('success', `Plan ${payload.name} successfully duplicated`),
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        return;
      }
      alert('error', e, e?.response?.data?.status);
    },
  });
};

export const useGeneratePlan = () => {
  const api = useApi();
  const alert = useAlert();

  const generatePlanRequest = async ({ project_id, strategies = [1] }: GeneratePlanBody) =>
    api.post('/occupancy-planning/api/generate-plan', {
      project_id,
      strategies,
    });

  return useMutation(generatePlanRequest, {
    onSuccess: () => alert('success', 'We have started generating plan(s)'),
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });
};

export const useGenerateNewPlan = (project_id: number) => {
  const api = useApi();
  const alert = useAlert();

  const generatePlanRequest = async ({
    name,
    description,
    strategies = [1],
    plan_id,
  }: GenerateNewPlanForm) =>
    api.post('/occupancy-planning/api/generate-plan', {
      name,
      description,
      project_id,
      strategies,
      plan_id,
    });

  return useMutation(generatePlanRequest, {
    onSuccess: () => {
      alert('success', 'The generation of a new plan is started');
    },
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        return;
      }
      alert('error', e, e?.response?.data?.status);
    },
  });
};

export const useRegeneratePlan = (plan_id: number) => {
  const api = useApi();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const regeneratePlanRequest = async (strategy_id: number) =>
    api.patch('/occupancy-planning/api/generate-plan', {
      plan_id,
      strategy_id,
    });

  return useMutation(regeneratePlanRequest, {
    onSuccess: () => {
      alert('success', 'Plan regeneration has started');
      queryClient.invalidateQueries(['planDetails', plan_id]);
    },
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });
};

export const usePlanOrder = (projectId: string) => {
  const api = useApi();
  const changeOrderRequest = (plans: Plan[]) => {
    const data = plans.map(({ id, sort_order }) => ({ id, sort_order }));
    api.patch(`/occupancy-planning/api/plan?project_id=${projectId}`, data);
  };

  return changeOrderRequest;
};