import { Container } from '@mui/material';

import { Checkbox, Tooltip } from '@components';
import { Help } from '@icons/index';

export type StrategyCheckboxProps = {
  label: string;
  description: string;
  value: boolean;
  onChange: (value: any) => void;
};

export const StrategyCheckbox = ({
  label,
  description,
  value,
  onChange,
}: StrategyCheckboxProps) => (
  <Container sx={{ display: 'flex', alignItems: 'center' }}>
    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
    <span>{label}</span>
    <Tooltip tooltip={description}>
      <span style={{ margin: '5px 0 0 5px ' }}>
        <Help />
      </span>
    </Tooltip>
  </Container>
);
