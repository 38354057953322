import { CSSProperties } from 'react';
import { PRIMARY_COLORS, PRIMARY_SILVER_COLOR, SECONDARY_COLORS } from '@constants/colors';

export const floorPlate: Record<string, CSSProperties | any> = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    alignItems: 'baseline',
  },
  title: {
    display: 'flex',
    gap: '10px',
    fontWeight: 600,
  },
  note: { marginTop: '3px' },
  noteMessage: {
    fontSize: '13px',
    margin: '5px 0 0 0',
  },
  nameBlock: {
    display: 'flex',
    gap: '20px',
    alignItems: 'baseline',
  },
  name: { color: SECONDARY_COLORS.DARK_GREY },
  resources: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  progressWrapper: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    backgroundColor: PRIMARY_SILVER_COLOR[300],
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
    transition: 'height 0.2s',
  },
  progress: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: PRIMARY_COLORS.PRIMARY_WHITE,
    borderRight: `1px solid ${PRIMARY_SILVER_COLOR[300]}`,
  },
  teamSeats: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  empty: {
    color: PRIMARY_COLORS.PRIMARY_SILVER,
  },
  lockedEmpty: {
    background: "url('/Lines.svg')",
  },
  w100: { width: '100%' },
  tipWrapper: { fontWeight: 400, padding: '10px' },
  tipHeader: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  tipTitle: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    gap: '3px',
  },
  tipActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '30px',
    cursor: 'pointer',
  },
  tipIcon: { width: '18px', margin: '0 -10px 0px 0px' },
};

export const teamPlate: Record<string, CSSProperties> = {
  wrapper: { display: 'flex', backgroundColor: PRIMARY_SILVER_COLOR[100], height: '115px' },
  label: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '12px' },
  labelIcon: { width: '10px', color: 'white', cursor: 'pointer' },
  content: { padding: '10px 0 10px 25px', flex: 1 },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'start', height: '40px' },
  data: { display: 'flex', gap: '30px' },
  capitalize: { textTransform: 'capitalize' },
  title: {
    display: 'flex',
    gap: '10px',
    fontWeight: 600,
  },
  note: { marginTop: '3px' },
};

export const planDetails: Record<string, CSSProperties> = {
  wrapper: { display: 'flex', justifyContent: 'space-between' },
  tabBlock: { display: 'flex', alignItems: 'center', gap: '15px' },
  content: { marginTop: '32px' },
  w100: { width: '100%' },
  titleContainer: { display: 'flex', alignItems: 'center' },
  planOfRecordLabel: {
    height: '26px',
    marginLeft: '10px',
    fontFamily: 'Calibre',
    fontSize: '13px',
    fontWeight: 400,
    flexShrink: 0,
  },
};

export const view = {
  wrapper: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '32px', height: '100%' },
  block: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 410px)',
    paddingRight: '10px',
    '&::-webkit-scrollbar': { width: '5px' },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: PRIMARY_COLORS.PRIMARY_SILVER,
      borderRadius: '10px',
      marginLeft: '10px',
    },
  },
  buildings: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  title: {
    color: PRIMARY_SILVER_COLOR[500],
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    gap: '10px',
  },
  team: {
    display: 'grid',
    gap: '15px',
    gridTemplateRows: 'repeat(auto-fill, minmax(60px, 1fr))',
    margin: '15px 0',
  },
  content: { marginTop: '32px' },
  w100: { width: '100%' },
  emptyMsg: { margin: '20px 0' },
};

export const sidebar: Record<string, CSSProperties> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  header: {
    marginTop: '40px',
    width: '100%',
  },
  content: {
    marginTop: '60px',
    width: '100%',
  },
  footer: {
    width: '100%',
    borderTop: `2px solid ${PRIMARY_SILVER_COLOR[300]}`,
    marginTop: '25px',
    paddingTop: '10px',
  },
  note: {
    width: '100%',
    marginTop: '30px',
  },
  grid: {
    display: 'grid',
    gap: '25px',
  },
  gridTop: {
    gridTemplateColumns: '1fr 115px',
  },
  gridBottom: {
    gridTemplateColumns: 'repeat(3, 115px) 1fr',
    gap: '0 25px',
    gridTemplateAreas: '"a b c ." "d d d d"',
  },
  spaces: {
    margin: '40px 0 10px 0',
  },
  colorPicker: {
    display: 'block',
    width: '100%',
    height: '28px',
  },
  info: {
    display: 'inline-flex',
    gap: '10px',
    margin: '0',
  },
  mt20: {
    marginTop: '20px',
  },
  mt30: {
    marginTop: '30px',
  },
  subtitle: {
    fontWeight: 600,
    marginRight: '5px',
  },
  sharingRatio: {
    padding: '15px 0',
    borderTop: `2px solid ${PRIMARY_SILVER_COLOR[300]}`,
    borderBottom: `2px solid ${PRIMARY_SILVER_COLOR[300]}`,
  },
  sharingRatioResult: {
    color: PRIMARY_COLORS.PRIMARY_GREEN,
    backgroundColor: PRIMARY_COLORS.PRIMARY_LIGHT_GREEN,
    marginTop: '5px',
    padding: '10px 4px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  hint: {
    display: 'flex',
    gap: '5px',
    fontSize: '13px',
    color: PRIMARY_SILVER_COLOR[500],
  },
};

export const actionBar: Record<string, CSSProperties> = {
  wrapper: { display: 'flex', gap: '16px' },
  compare: {
    color: SECONDARY_COLORS.DARK_GREY,
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',
    fontFamily: 'Calibre',
    marginRight: '5px',
  },
};
