import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Divider } from '@mui/material';

import { Button, MenuByClick } from '@components';
import { PRIMARY_COLORS } from '@constants/colors';
import { usePlanOfRecord } from '@hooks/usePlanDetails';
import { useEditPlan } from '@hooks/usePlans';
import { Order } from '@utils/quickSort';
import { Settings } from '@icons/index';

import { SaveAsSideBar } from '../save-as';
import { EditPlanSideBar } from '../edit-plan';
import { RegeneratePlan } from '../regenerate-plan';
import { DownloadPlanDetailsPdf } from '../download-pdf';
import { PlanDetailsResponse, Plan } from '../../types';
import { findPlanOfRecordName, PlanOfRecordModal } from '../occupancy-plans';
import { actionBar as styles } from './styles';
import { handleReverse } from './helpers';

export type ActionBarProps = {
  order: Order;
  planDetails: PlanDetailsResponse;
  projectId: string;
  planList: Plan[];
};

const MenuItems = ({ items }: any) =>
  items.map(({ callback, label, config }: any) => (
    <div key={label}>
      <MenuItem sx={{ color: config?.color }} onClick={callback} disabled={!!config?.disabled}>
        {label}
      </MenuItem>
      {config?.dividerAfter && <Divider sx={{ width: '80%', margin: 'auto' }} />}
    </div>
  ));

export const ActionBar = ({ planDetails, projectId, planList, order }: ActionBarProps) => {
  const navigate = useNavigate();
  const updatePlan = useEditPlan(projectId);
  const updatePlanOfRecord = usePlanOfRecord(projectId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveAsModal, setSaveAsModal] = useState(false);
  const [editPlanModal, setEditPlanModal] = useState(false);

  useEffect(() => {
    if (!updatePlanOfRecord.isLoading) {
      setIsModalOpen(false);
    }
  }, [updatePlanOfRecord.isLoading]);

  const planOfRecord = findPlanOfRecordName(planList);

  const toggleSaveAs = () => setSaveAsModal((state) => !state);
  const toggleEditPlan = () => setEditPlanModal((state) => !state);

  const changePlanOfRecord = async () => {
    try {
      await updatePlanOfRecord.mutateAsync({
        value: !planDetails.is_plan_of_record,
        planId: planDetails.id,
      });
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const planOfRecordHandler = () => {
    // other plan is change of records
    if (planOfRecord && planOfRecord.id !== planDetails.id) {
      setIsModalOpen(true);
    } else {
      changePlanOfRecord();
    }
  };

  const handleDeletePlan = async () => {
    try {
      await updatePlan.mutateAsync({ planId: planDetails.id, data: { is_deleted: true } });
      navigate(`/v2/projects/${projectId}/plans`);
    } catch (e) {
      console.error(e);
    }
  };

  const compareOptions = () =>
    planList
      .filter((plan: Plan) => plan.id !== planDetails.id)
      .map((plan: Plan) => ({
        label: plan.name,
        callback: () => navigate(`/v2/projects/${projectId}/compare/${planDetails.id}/${plan.id}`),
      }));

  const settingOptions = () => {
    const planOfRecordLabel = planDetails.is_plan_of_record
      ? 'Unset as Plan of Record'
      : 'Set as Plan of Record';

    return [
      {
        label: 'Edit Details',
        callback: toggleEditPlan,
        config: { disabled: planDetails.is_plan_of_record },
      },
      {
        label: planOfRecordLabel,
        callback: planOfRecordHandler,
        config: { dividerAfter: true },
      },
      {
        label: 'Delete',
        callback: handleDeletePlan,
        config: { color: PRIMARY_COLORS.PRIMARY_RED, disabled: planDetails.is_plan_of_record },
      },
    ];
  };

  const compareOptionsList = compareOptions();

  return (
    <div style={styles.wrapper}>
      <Button type="secondary" onClick={toggleSaveAs}>
        Save As...
      </Button>

      <MenuByClick
        id="compare-menu"
        menuItems={<MenuItems items={compareOptionsList} />}
        type="square"
        withArrow
        disabled={!compareOptionsList.length}
      >
        <span style={styles.compare}>Compare with </span>
      </MenuByClick>

      <DownloadPlanDetailsPdf
        planDetails={{
          ...planDetails,
          buildings: planDetails.buildings.map((building) => ({
            ...building,
            floors: handleReverse(building.floors, order),
          })),
        }}
      />

      <RegeneratePlan planDetails={planDetails} />

      <MenuByClick
        id="plan-details-menu"
        menuItems={<MenuItems items={settingOptions()} />}
        type="square"
      >
        <Settings />
      </MenuByClick>
      {planOfRecord && isModalOpen && (
        <PlanOfRecordModal
          onClose={() => setIsModalOpen(false)}
          isOpen
          planName={planOfRecord.name}
          onSubmit={changePlanOfRecord}
          isDisabled={updatePlanOfRecord.isLoading}
        />
      )}
      {saveAsModal && (
        <SaveAsSideBar
          isOpen={saveAsModal}
          toggleSideBar={toggleSaveAs}
          planId={planDetails.id}
          projectId={projectId}
        />
      )}
      {editPlanModal && (
        <EditPlanSideBar
          isOpen={editPlanModal}
          toggleSideBar={toggleEditPlan}
          data={planDetails}
          projectId={projectId}
        />
      )}
    </div>
  );
};
