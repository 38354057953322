import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@components';
import { useRegeneratePlan } from '@hooks/usePlans';

import { ReneratePlanDialog, ReneratePlanDialogProps } from './RegeneratePlanDialog';
import { GenerateNewPlanSideBar } from '../generate-new-plan';
import { STRATEGIES_LIST, PlanDetailsResponse } from '../../types';

type VisibilityItem = 'regenerateModal' | 'generateNewSideBar';

export type RegeneratePlanProps = {
  planDetails: PlanDetailsResponse;
};

export const RegeneratePlan = ({ planDetails }: RegeneratePlanProps) => {
  const { planId } = useParams();

  const regeneratePlan = useRegeneratePlan(Number(planId));

  const [visibilityItems, setVisibilityItems] = useState<Record<VisibilityItem, boolean>>({
    regenerateModal: false,
    generateNewSideBar: false,
  });
  const [selectedStrategy, setSelectedStrategy] = useState(1);

  useEffect(() => {
    if (planDetails && planDetails.strategy) {
      // find strategy that was used during plan creation
      // in future better to check by value
      const usedStrategy = STRATEGIES_LIST.find(
        (strategy) => strategy.key === planDetails.strategy,
      );
      if (usedStrategy) {
        setSelectedStrategy(usedStrategy.value);
      }
    }
  }, [planDetails]);

  const toggleVisibility = (key: VisibilityItem) =>
    setVisibilityItems((state) => ({ ...state, [key]: !state[key] }));

  const handleStrategy = (value: number) => setSelectedStrategy(Number(value));

  const handleOverrideCurrentPlan = async () => {
    try {
      await regeneratePlan.mutateAsync(selectedStrategy);
      toggleVisibility('regenerateModal');
    } catch (e) {
      console.error(e);
    }
  };

  const prepareProps = () => {
    let props: ReneratePlanDialogProps = {
      isOpen: visibilityItems.regenerateModal,
      onClose: () => toggleVisibility('regenerateModal'),
      onGenerateNewPlan: () => toggleVisibility('generateNewSideBar'),
      onOverrideCurrenPlan: handleOverrideCurrentPlan,
      isLoading: regeneratePlan.isLoading,
      isEditable: !planDetails.is_plan_of_record,
    };

    if (planDetails?.solution?.adjacencies && planDetails.solution.adjacencies.length) {
      props = { ...props, selectedStrategy, onChangeStrategy: handleStrategy };
    }

    return props;
  };

  return (
    <>
      {/* disabling button here while loading required adjacencies, instead can prolong spinner on whole screen */}
      <Button
        disabled={!planDetails || !planDetails.solution}
        onClick={() => toggleVisibility('regenerateModal')}
      >
        Regenerate Plan
      </Button>
      <ReneratePlanDialog {...prepareProps()} />
      {visibilityItems.generateNewSideBar && (
        <GenerateNewPlanSideBar
          sideBarStatus={visibilityItems.generateNewSideBar}
          toggleSideBar={() => toggleVisibility('generateNewSideBar')}
          predefinedStrategy={selectedStrategy}
          sourcePlan={planDetails.id}
        />
      )}
    </>
  );
};
