import { useState } from 'react';
import { Button } from '@components';

import { GenerateNewPlanSideBar } from './GenerateNewPlanSideBar';

const GeneratePlan = () => {
  const [isOpenSidebar, setSidebarStatus] = useState(false);

  const toggleSideBar = () => setSidebarStatus((value) => !value);
  return (
    <div style={{ display: 'flex', gap: '16px' }}>
      <Button onClick={toggleSideBar}>Generate New Plan</Button>

      {isOpenSidebar && (
        <GenerateNewPlanSideBar sideBarStatus={isOpenSidebar} toggleSideBar={toggleSideBar} />
      )}
    </div>
  );
};

export default GeneratePlan;
