import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  DialogContentText,
} from '@mui/material';
import { Close } from '@icons/index';
import { IconButton, Button } from '@components';

import { StrategyCheckbox } from './StrategyCheckbox';
import { STRATEGIES_LIST } from '../../../../types';

export type GeneratePlanDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  strategies?: { [key: number]: boolean };
  onChangeStrategy?: (key: number, value: boolean) => void;
  isDisabledSubmit?: boolean;
};

export const GeneratePlanDialog = ({
  isOpen,
  onClose,
  onSubmit,
  strategies,
  onChangeStrategy,
  isDisabledSubmit = false,
}: GeneratePlanDialogProps) => {
  const renderStrategies = () => (
    <Container sx={{ paddingTop: '30px', maxWidth: '350px' }}>
      {STRATEGIES_LIST.map(({ value: key, label, description }) => (
        <StrategyCheckbox
          key={label}
          label={label}
          description={description}
          value={strategies![key]}
          onChange={(value) => onChangeStrategy!(key, value)}
        />
      ))}
    </Container>
  );

  const renderDescription = () =>
    strategies && strategies && onChangeStrategy
      ? 'Choose from the available algorithm models to generate initial plans based on your specific requirements and preferences.'
      : 'The initial plan will be focused to optimise space utilisation.';

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Generate Occupancy Plan(s)
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Smart Occupancy Generation Tool is ready to create a baseline plan using the input data
          you provided. Once the plan is generated you will be able to modify and adjust it.{' '}
          {renderDescription()}
        </DialogContentText>
        {strategies && onChangeStrategy && renderStrategies()}
      </DialogContent>
      <DialogActions>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={isDisabledSubmit}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
