/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import { Breadcrumbs, OverflowTooltip } from '@components';
import { useHeaderData } from '../../context/headerData';

export const TitleSection = () => {
  const {
    data: { title, breadcrumb, ActionBar, props = {} },
  } = useHeaderData();
  const { TitleMarker, ...rest } = props;

  if (!title) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '98px', gap: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          gap: '5px',
          overflow: 'hidden',
        }}
      >
        {breadcrumb && <Breadcrumbs list={breadcrumb} />}
        <Typography variant="h1" sx={{ display: 'flex', alignItems: 'center', cursor: 'default' }}>
          <OverflowTooltip>{title}</OverflowTooltip>
          {TitleMarker && <TitleMarker />}
        </Typography>
      </Box>
      <Box sx={{ alignSelf: 'end', marginBottom: '15px', flexShrink: '0' }}>
        {ActionBar && <ActionBar {...rest} />}
      </Box>
    </Box>
  );
};
