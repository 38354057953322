const Admin_Home = '/Admin';
const Admin_User_Setup = '/Admin/Users';
const Admin_Client_Setup = '/Admin/Clients';
const Admin_Client_User_Mapping_Setup = '/Admin/ClientUserMapping';
const PS_Home = '/PortfolioStrategy';
const DM_Home = '/DataManagement';
const DM_Upload = '/DataManagement/Upload';
const DM_DataCleanup = '/DataManagement/DataCleanup';
const PS_Labor_Forecast = '/PortfolioStrategy/LaborForecast';
const PS_Strategy_Designer = '/PortfolioStrategy/StrategyDesigner';
const PS_Headcount_Forecast = '/PortfolioStrategy/HeadcountForecast';
const PS_Strategy_Summary = '/PortfolioStrategy/StrategyDesigner/StrategySummary';
const PS_Opportunity_Detail = '/PortfolioStrategy/StrategyDesigner/OpportunityDetail';
const OP_Home = '/SeatAllocation';
const DM_PS_Upload = '/DataManagement/PS';
const DM_OP_Upload = '/DataManagement/OM';
const OP_PlanGenerator = '/SeatAllocation/PlanGenerator';
const OP_PlanDetails = '/SeatAllocation/PlanDetails';
const OP_PlanSummary = '/SeatAllocation/PlanSummary';
const UploadSuccess = '/UploadSuccess';

const PROJECTS = '/v2/projects';
const PLAN_LIST = '/v2/projects/:projectId/plans';
const PLAN = '/v2/projects/:projectId/plans/:planId';
const COMPARE_PLANS = '/v2/projects/:projectId/compare/:planIdA/:planIdB';
const DATA_UPLOAD = '/v2/projects/:projectId/data-upload';

export {
  OP_Home,
  OP_PlanGenerator,
  OP_PlanDetails,
  OP_PlanSummary,
  PS_Home,
  DM_Home,
  DM_Upload,
  DM_PS_Upload,
  DM_OP_Upload,
  DM_DataCleanup,
  PS_Labor_Forecast,
  PS_Strategy_Summary,
  PS_Strategy_Designer,
  PS_Headcount_Forecast,
  PS_Opportunity_Detail,
  Admin_Home,
  Admin_User_Setup,
  Admin_Client_Setup,
  Admin_Client_User_Mapping_Setup,
  UploadSuccess,
  PROJECTS,
  PLAN_LIST,
  PLAN,
  COMPARE_PLANS,
  DATA_UPLOAD,
};
