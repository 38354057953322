import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroupMUI from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

export type RadioGroupProps = {
  onChange: (value: any) => void;
  value: any;
  options: { label: string; value: any; description?: string }[];
};

export const RadioGroup = ({ options, onChange, value }: RadioGroupProps) => {
  const handleChange = (e: any) => {
    const { value } = e.target;
    if (typeof value === 'number') {
      onChange(Number(value));
    } else {
      onChange(value);
    }
  };

  return (
    <RadioGroupMUI onChange={handleChange} value={value}>
      {options.map((option) => (
        <FormControlLabel
          key={option.label}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
          labelPlacement="end"
        />
      ))}
    </RadioGroupMUI>
  );
};
