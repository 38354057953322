export const NotFound = () => (
  <svg
    width="300"
    height="307"
    viewBox="0 0 300 307"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8446_37844)">
      <path
        d="M286.723 169.093C289.528 149.224 287.188 128.578 279.774 108.103C266.807 72.2946 240.46 40.0612 206.188 23.7997C192.413 17.2622 177.398 13.4766 162.175 13.2923C134.994 12.9565 112.085 24.6951 92.9844 43.5045C78.0526 58.2056 64.0645 74.6581 45.7197 85.1062C28.351 94.9948 12.5724 108.59 7.91189 129.539C3.78787 148.078 11.3313 167.276 23.6646 180.957C31.7123 189.884 42.3326 195.882 50.4514 204.717C61.3303 216.554 59.9405 227.338 60.8455 242.52C62.7394 274.26 90.1273 296.137 118.465 303.642C153.798 312.998 186.893 299.574 213.415 275.563C230.551 260.046 248.172 244.555 262.334 226.074C275.773 208.542 283.879 189.219 286.716 169.1L286.723 169.093Z"
        fill="#80BBAD"
      />
      <path
        opacity="0.7"
        d="M286.723 169.093C289.528 149.224 287.188 128.578 279.774 108.103C266.807 72.2946 240.46 40.0612 206.188 23.7997C192.413 17.2622 177.398 13.4766 162.175 13.2923C134.994 12.9565 112.085 24.6951 92.9844 43.5045C78.0526 58.2056 64.0645 74.6581 45.7197 85.1062C28.351 94.9948 12.5724 108.59 7.91189 129.539C3.78787 148.078 11.3313 167.276 23.6646 180.957C31.7123 189.884 42.3326 195.882 50.4514 204.717C61.3303 216.554 59.9405 227.338 60.8455 242.52C62.7394 274.26 90.1273 296.137 118.465 303.642C153.798 312.998 186.893 299.574 213.415 275.563C230.551 260.046 248.172 244.555 262.334 226.074C275.773 208.542 283.879 189.219 286.716 169.1L286.723 169.093Z"
        fill="white"
      />
      <path
        d="M128.969 294.116C128.969 294.116 125.569 299.686 124.593 300.548C123.624 301.417 121.193 303.267 120.217 304.261C119.247 305.249 120.463 306.486 121.555 306.612C122.648 306.737 135.892 306.612 135.892 306.612C135.892 306.612 137.353 306.361 137.469 305.624C137.592 304.88 138.2 302.655 137.347 300.673C136.493 298.692 136.493 296.466 136.493 296.466L136.739 295.847L136.493 294.359L128.963 294.109L128.969 294.116Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.563 306.612C122.655 306.737 135.9 306.612 135.9 306.612C135.9 306.612 137.361 306.361 137.477 305.624C137.555 305.15 137.833 304.057 137.794 302.819C135.79 303.425 133.76 304.261 133.76 304.261H120.225C119.255 305.249 120.471 306.486 121.563 306.612Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.379 303.004L133.441 306.348"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.475 301.483C130.404 301.483 130.327 301.47 130.255 301.437C127.734 300.298 126.093 301.075 126.021 301.108C125.756 301.239 125.427 301.127 125.298 300.851C125.168 300.581 125.272 300.252 125.543 300.114C125.627 300.067 127.683 299.06 130.701 300.423C130.973 300.548 131.102 300.877 130.979 301.154C130.889 301.358 130.688 301.483 130.482 301.483H130.475Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.94 300.054C130.869 300.054 130.791 300.041 130.72 300.008C128.199 298.869 126.557 299.646 126.486 299.679C126.215 299.811 125.892 299.699 125.762 299.422C125.633 299.152 125.737 298.823 126.008 298.685C126.092 298.639 128.148 297.632 131.166 298.994C131.438 299.119 131.567 299.449 131.444 299.725C131.354 299.929 131.153 300.054 130.946 300.054H130.94Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.413 298.619C131.342 298.619 131.264 298.606 131.193 298.573C128.672 297.434 127.03 298.211 126.959 298.244C126.694 298.376 126.364 298.264 126.235 297.987C126.106 297.717 126.209 297.388 126.481 297.25C126.565 297.204 128.62 296.196 131.639 297.559C131.91 297.684 132.04 298.013 131.917 298.29C131.826 298.494 131.626 298.619 131.419 298.619H131.413Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M175.606 294.116C175.606 294.116 179.006 299.686 179.982 300.548C180.952 301.417 183.382 303.267 184.358 304.261C185.328 305.249 184.112 306.486 183.02 306.612C181.928 306.737 168.683 306.612 168.683 306.612C168.683 306.612 167.222 306.361 167.106 305.624C166.983 304.88 166.375 302.655 167.228 300.673C168.082 298.692 168.082 296.466 168.082 296.466L167.836 295.847L168.082 294.359L175.612 294.109L175.606 294.116Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.02 306.612C181.928 306.737 168.683 306.612 168.683 306.612C168.683 306.612 167.222 306.361 167.106 305.624C167.028 305.15 166.75 304.057 166.789 302.819C168.793 303.425 170.822 304.261 170.822 304.261H184.358C185.328 305.249 184.112 306.486 183.02 306.612Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.195 303.004L171.133 306.348"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.105 301.483C174.177 301.483 174.254 301.47 174.325 301.437C176.846 300.298 178.488 301.075 178.559 301.108C178.824 301.239 179.154 301.127 179.283 300.851C179.412 300.581 179.309 300.252 179.038 300.114C178.953 300.067 176.898 299.06 173.879 300.423C173.608 300.548 173.478 300.877 173.601 301.154C173.692 301.358 173.892 301.483 174.099 301.483H174.105Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.633 300.054C173.704 300.054 173.781 300.041 173.853 300.008C176.374 298.869 178.015 299.646 178.087 299.679C178.358 299.811 178.681 299.699 178.81 299.422C178.94 299.152 178.836 298.823 178.565 298.685C178.481 298.639 176.425 297.632 173.407 298.994C173.135 299.119 173.006 299.449 173.129 299.725C173.219 299.929 173.42 300.054 173.626 300.054H173.633Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.168 298.619C173.239 298.619 173.317 298.606 173.388 298.573C175.909 297.434 177.551 298.211 177.622 298.244C177.887 298.376 178.216 298.264 178.346 297.987C178.475 297.717 178.371 297.388 178.1 297.25C178.016 297.204 175.96 296.196 172.942 297.559C172.67 297.684 172.541 298.013 172.664 298.29C172.754 298.494 172.955 298.619 173.162 298.619H173.168Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.941 290.581C177.631 289.626 175.75 286.281 175.75 286.281C175.75 286.281 176.222 284.208 176.222 282.298C176.222 280.389 174.108 270.988 174.108 270.988L174.005 271.113C173.656 267.485 173.21 264.463 173.106 264.062C172.938 263.39 168.485 216.482 168.485 216.482L166.998 204.546L152.299 205.277L137.6 204.546L136.113 216.482C136.113 216.482 131.659 263.384 131.491 264.062C131.375 264.549 130.735 268.92 130.392 273.529C129.888 276.281 129.061 280.975 129.061 282.298C129.061 283.997 129.429 285.814 129.513 286.202C129.507 286.255 129.5 286.301 129.487 286.354C129.222 286.828 127.626 289.705 127.341 290.581C127.031 291.535 127.774 296.032 127.774 296.032C127.774 296.032 127.8 296.032 127.839 296.032L127.813 296.15L130.547 296.249C131.155 296.308 131.814 296.381 132.5 296.466C135.001 296.782 136.876 297.743 136.876 297.743L140.004 292.009L138.912 289.461L140.166 281.976L140.056 281.857L147.341 251.955L152.299 228.596L157.257 251.955L165.595 284.899L166.358 289.461L165.266 292.009L168.394 297.743C168.394 297.743 170.269 296.789 172.77 296.466C175.272 296.144 177.502 296.025 177.502 296.025C177.502 296.025 178.245 291.529 177.935 290.574L177.941 290.581Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.771 206.554H142.102V208.72H161.771V206.554Z"
        fill="#575757"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.359 206.554H163.559V208.72H167.359V206.554Z"
        fill="#575757"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.316 206.554H137.297V208.72H140.316V206.554Z"
        fill="#575757"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.622 206.778H149.703V208.602H154.622V206.778Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.477 166.736C130.477 166.736 121.414 198.831 121.02 199.635C120.626 200.438 122.991 200.036 125.222 200.438C127.452 200.839 130.606 199.101 131 198.43C131.395 197.758 134.678 187.33 134.678 187.33L130.477 166.736Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.883 177.138L130.508 176.21"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.854 184.459L131.32 178.212"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M130.668 194.21C131.101 194.519 131.385 194.736 131.385 194.736Z" fill="white" />
      <path
        d="M130.668 194.21C131.101 194.519 131.385 194.736 131.385 194.736"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.973 192.755C123.973 192.755 126.442 191.853 126.972 191.853C127.276 191.853 128.685 192.801 129.835 193.611"
        fill="white"
      />
      <path
        d="M123.973 192.755C123.973 192.755 126.442 191.853 126.972 191.853C127.276 191.853 128.685 192.801 129.835 193.611"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.491 164.86C172.491 164.86 182.866 193.077 183.124 194.15C183.389 195.224 183.648 195.889 183.518 197.093C183.389 198.298 181.547 199.101 181.153 199.101C180.758 199.101 177.869 197.232 176.686 196.692C175.503 196.158 171.961 194.42 171.961 194.42L170.125 188.403L172.491 164.867V164.86Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.074 174.018L173.242 173.333"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.598 183.946L172.789 175.144"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.951 188.982L172.711 191.675"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.311 158.711L130.477 166.736L136.908 201.511C136.908 201.511 136.385 204.724 136.779 205.389C137.173 206.06 137.833 206.455 137.833 206.455H166.849C166.849 206.455 168.821 204.585 168.821 204.184V202.446L172.499 164.867L157.806 158.902L145.318 158.718L145.311 158.711Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.641 160.298L150.587 165.894L147.949 199.951L152.073 206.6L155.622 199.365L151.957 165.894L154.252 160.877L150.929 157.151L148.641 160.298Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.085 155.748L144.742 159.481L147.838 164.61L150.127 159.014L145.085 155.748Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.188 159.132L154.017 165.077L157.798 158.895L156.079 155.281L152.188 159.132Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.594 198.561L161.07 194.42L164.613 195.085L161.594 198.561Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.877 185.993C151.877 185.993 149.776 185.723 148.593 185.322C147.41 184.92 146.621 185.454 147.546 186.658C148.464 187.863 151.747 188.396 152.142 188.396C152.142 188.396 152.801 191.873 154.637 192.544C156.473 193.216 157.526 193.216 157.526 193.216C157.526 193.216 158.444 194.552 159.627 194.684C160.81 194.815 163.564 196.823 163.564 196.823L164.876 193.749L161.986 191.339L159.097 186.257L156.337 185.322L153.971 184.519L151.87 185.987L151.877 185.993Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.224 189.601L158.041 190.806C158.041 190.806 158.171 191.339 158.171 191.879C158.171 192.419 157.253 192.412 156.594 192.412C155.934 192.412 155.152 192.281 154.622 191.879C154.098 191.478 151.998 189.608 151.998 189.608L154.228 185.598L152.256 186.935C152.256 186.935 151.338 188.673 150.55 189.476C149.761 190.279 149.496 189.344 149.496 189.344C149.496 189.344 150.285 186.803 150.808 186.132C151.332 185.46 153.174 183.86 153.174 183.86C153.174 183.86 153.833 183.729 154.751 183.86C155.669 183.992 157.511 185.065 158.294 185.598C159.082 186.132 159.735 187.205 159.871 187.738C160 188.271 160 188.673 160 188.673"
        fill="white"
      />
      <path
        d="M159.224 189.601L158.041 190.806C158.041 190.806 158.171 191.339 158.171 191.879C158.171 192.419 157.253 192.412 156.594 192.412C155.934 192.412 155.152 192.281 154.622 191.879C154.098 191.478 151.998 189.608 151.998 189.608L154.228 185.598L152.256 186.935C152.256 186.935 151.338 188.673 150.55 189.476C149.761 190.279 149.496 189.344 149.496 189.344C149.496 189.344 150.285 186.803 150.808 186.132C151.332 185.46 153.174 183.86 153.174 183.86C153.174 183.86 153.833 183.729 154.751 183.86C155.669 183.992 157.511 185.065 158.294 185.598C159.082 186.132 159.735 187.205 159.871 187.738C160 188.271 160 188.673 160 188.673"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.811 192.281C155.811 192.281 155.416 191.346 155.681 190.674C155.946 190.003 157.653 187.462 157.653 187.462"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.628 191.879C154.628 191.879 154.415 191.636 154.182 191.3C153.898 190.885 154.137 190.358 154.137 189.917C154.137 189.114 156.07 185.862 156.07 185.862"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.501 191.208C180.501 191.208 183.785 196.29 183.655 197.627C183.526 198.963 182.737 201.906 181.555 201.906C180.372 201.906 175.123 201.234 171.445 200.569C167.767 199.898 165.272 199.635 165.272 199.635L161.594 198.561L164.089 192.412L166.481 192.919C166.481 192.919 168.239 192.465 169.952 192.333C171.658 192.202 177.359 190.806 177.359 190.806"
        fill="white"
      />
      <path
        d="M180.501 191.208C180.501 191.208 183.785 196.29 183.655 197.627C183.526 198.963 182.737 201.906 181.555 201.906C180.372 201.906 175.123 201.234 171.445 200.569C167.767 199.898 165.272 199.635 165.272 199.635L161.594 198.561L164.089 192.412L166.481 192.919C166.481 192.919 168.239 192.465 169.952 192.333C171.658 192.202 177.359 190.806 177.359 190.806"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.491 193.525C166.491 193.525 166.666 195.842 165.438 197.804"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.724 195.355C122.724 195.355 120.494 200.839 120.358 201.241C120.229 201.643 120.753 204.184 122.853 203.651C124.954 203.117 136.117 201.913 137.559 201.643C139 201.373 141.237 200.971 141.237 200.971H144.126C145.438 200.971 149.117 200.971 149.905 200.701C150.694 200.431 152.271 199.898 153.06 199.635C153.848 199.365 155.555 198.298 155.684 197.897C155.813 197.495 155.29 197.093 153.977 197.225C152.665 197.357 148.593 197.495 148.593 197.495C148.593 197.495 152.271 196.027 153.454 195.487C154.637 194.954 154.242 194.414 154.242 194.414C154.242 194.414 152.93 194.414 152.012 194.815C151.094 195.217 142.032 196.422 142.032 196.422L140.978 195.487C140.978 195.487 141.502 194.15 141.502 193.077C141.502 192.004 140.978 191.208 140.319 191.471C139.66 191.741 139.401 193.341 139.272 193.881C139.143 194.414 138.483 196.553 138.483 196.553L138.218 197.758C138.218 197.758 132.834 196.685 131.257 196.02C129.679 195.349 126.396 194.414 125.872 194.552C125.348 194.684 124.295 196.158 124.036 196.823"
        fill="white"
      />
      <path
        d="M122.724 195.355C122.724 195.355 120.494 200.839 120.358 201.241C120.229 201.643 120.753 204.184 122.853 203.651C124.954 203.117 136.117 201.913 137.559 201.643C139 201.373 141.237 200.971 141.237 200.971H144.126C145.438 200.971 149.117 200.971 149.905 200.701C150.694 200.431 152.271 199.898 153.06 199.635C153.848 199.365 155.555 198.298 155.684 197.897C155.813 197.495 155.29 197.093 153.977 197.225C152.665 197.357 148.593 197.495 148.593 197.495C148.593 197.495 152.271 196.027 153.454 195.487C154.637 194.954 154.242 194.414 154.242 194.414C154.242 194.414 152.93 194.414 152.012 194.815C151.094 195.217 142.032 196.422 142.032 196.422L140.978 195.487C140.978 195.487 141.502 194.15 141.502 193.077C141.502 192.004 140.978 191.208 140.319 191.471C139.66 191.741 139.401 193.341 139.272 193.881C139.143 194.414 138.483 196.553 138.483 196.553L138.218 197.758C138.218 197.758 132.834 196.685 131.257 196.02C129.679 195.349 126.396 194.414 125.872 194.552C125.348 194.684 124.295 196.158 124.036 196.823"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.458 197.271C134.458 197.271 133.585 199.233 134.633 200.484"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.333 190.227L140.327 198.502C140.476 198.805 140.78 199.003 141.11 199.003L155.686 199.154C156.306 199.154 156.739 198.529 156.52 197.936L153.346 189.509C153.217 189.16 152.887 188.93 152.525 188.93H137.128C136.475 188.93 136.048 189.628 136.339 190.227H136.333Z"
        fill="#80BBAD"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.979 197.225C152.667 197.357 148.595 197.495 148.595 197.495C148.595 197.495 152.273 196.027 153.456 195.487C154.638 194.954 154.244 194.414 154.244 194.414C154.244 194.414 152.932 194.414 152.014 194.815C151.096 195.217 142.034 196.422 142.034 196.422L140.98 195.487C140.98 195.487 141.504 194.15 141.504 193.077C141.504 192.004 140.98 191.208 140.321 191.471C139.661 191.741 139.403 193.341 139.274 193.881C139.144 194.414 138.485 196.553 138.485 196.553L138.22 197.758C138.22 197.758 137.651 197.646 136.83 197.469C136.63 198.193 136.533 199.167 136.901 200.3C137.082 200.846 137.25 201.287 137.418 201.662C137.47 201.656 137.522 201.643 137.561 201.636C139.002 201.366 141.239 200.964 141.239 200.964H144.128C145.44 200.964 149.118 200.964 149.907 200.695C150.695 200.425 152.273 199.891 153.061 199.628C153.85 199.358 155.556 198.292 155.686 197.89C155.815 197.488 155.291 197.087 153.979 197.218V197.225Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.189 198.107C155.189 198.107 152.467 199.431 152.073 199.431C151.679 199.431 147.012 199.957 147.012 199.957"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.814 296.15C127.814 296.15 127.852 295.992 127.943 295.768C127.891 295.854 127.833 295.94 127.781 296.025L127.82 296.144L127.814 296.15Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.068 143.476C143.068 143.476 142.383 140.56 141.348 142.423C140.314 144.293 141.465 147.209 141.924 147.677C142.383 148.144 143.301 146.623 143.301 146.623"
        fill="white"
      />
      <path
        d="M143.068 143.476C143.068 143.476 142.383 140.56 141.348 142.423C140.314 144.293 141.465 147.209 141.924 147.677C142.383 148.144 143.301 146.623 143.301 146.623"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.033 143.476C159.033 143.476 159.719 140.56 160.753 142.423C161.787 144.293 160.637 147.209 160.178 147.677C159.719 148.144 158.801 146.623 158.801 146.623"
        fill="white"
      />
      <path
        d="M159.033 143.476C159.033 143.476 159.719 140.56 160.753 142.423C161.787 144.293 160.637 147.209 160.178 147.677C159.719 148.144 158.801 146.623 158.801 146.623"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.999 132.693C151.075 130.796 144.966 132.357 143.603 136.978C142.588 140.415 142.743 144.945 142.743 148.803C142.743 150.718 145.186 153.2 145.186 153.2L145.374 156.835L151.198 160.469L156.078 157.026L156.266 153.582C156.266 153.582 158.896 151.285 159.271 149.566C159.646 147.848 158.948 138.071 158.269 136.175C157.849 134.99 156.899 133.608 155.005 132.693H154.999Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M149.253 137.387C149.253 137.387 147.307 135.872 145.129 137.973Z" fill="white" />
      <path
        d="M149.253 137.387C149.253 137.387 147.307 135.872 145.129 137.973"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M153.035 137.545C153.035 137.545 154.981 135.912 157.159 137.663Z" fill="white" />
      <path
        d="M153.035 137.545C153.035 137.545 154.981 135.912 157.159 137.663"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M152.233 142.305C152.233 142.305 152.116 143.707 150.746 144.056Z" fill="white" />
      <path
        d="M152.233 142.305C152.233 142.305 152.116 143.707 150.746 144.056"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M148.227 145.577C148.227 145.577 150.289 146.393 153.837 145.577Z" fill="white" />
      <path
        d="M148.227 145.577C148.227 145.577 150.289 146.393 153.837 145.577"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.172 153.602C152.578 153.813 151.918 154.037 151.317 154.208C149.714 154.675 146.508 153.154 146.508 153.154"
        fill="white"
      />
      <path
        d="M153.172 153.602C152.578 153.813 151.918 154.037 151.317 154.208C149.714 154.675 146.508 153.154 146.508 153.154"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M154.98 152.924C154.98 152.924 154.663 153.049 154.172 153.233Z" fill="white" />
      <path
        d="M154.98 152.924C154.98 152.924 154.663 153.049 154.172 153.233"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.389 139.243C155.389 139.605 155.176 139.902 154.917 139.902C154.659 139.902 154.445 139.605 154.445 139.243C154.445 138.881 154.659 138.585 154.917 138.585C155.176 138.585 155.389 138.881 155.389 139.243Z"
        fill="#263238"
      />
      <path
        d="M147.456 139.902C147.717 139.902 147.928 139.607 147.928 139.243C147.928 138.88 147.717 138.585 147.456 138.585C147.196 138.585 146.984 138.88 146.984 139.243C146.984 139.607 147.196 139.902 147.456 139.902Z"
        fill="#263238"
      />
      <path
        d="M152.745 138.223C152.745 138.223 152.273 139.243 152.215 140.02C152.157 140.797 152.331 141.218 152.331 141.218"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.174 132.824C147.174 132.824 146.702 131.303 148.822 130.105C150.942 128.907 151.569 129.144 153.845 128.268C156.12 127.393 156.592 127.946 156.353 128.907C156.12 129.868 155.176 130.744 155.176 130.744C155.176 130.744 156.902 129.223 157.529 129.546C158.156 129.868 157.607 132.179 157.451 132.502C157.296 132.824 158.861 132.265 159.649 134.18C160.431 136.096 159.255 142.647 159.255 142.647C159.255 142.647 158.628 140.093 158.473 139.131C158.318 138.17 157.755 134.727 156.353 134.18C154.316 133.384 152.119 133.061 150.315 133.219C148.512 133.377 147.18 132.818 147.18 132.818L147.174 132.824Z"
        fill="#263238"
      />
      <path
        d="M147.173 132.265C147.173 132.265 145.369 131.468 144.115 132.587C142.861 133.706 143.798 134.187 143.643 134.661C143.488 135.142 142.622 134.74 141.917 136.978C141.213 139.217 142.861 141.212 142.861 141.212L143.094 142.173C143.094 142.173 143.566 139.375 143.566 138.736C143.566 138.098 144.742 137.38 144.975 136.577C145.208 135.78 145.679 133.779 147.483 132.982C147.483 132.982 147.56 132.423 147.166 132.265H147.173Z"
        fill="#263238"
      />
      <path d="M296.445 305.953H300.001" stroke="#263238" strokeMiterlimit="10" />
      <path d="M0 305.953H293.859" stroke="#263238" strokeMiterlimit="10" />
      <path
        d="M14.9774 49.2603L5.37891 59.0364L14.9774 68.8126L24.576 59.0364L14.9774 49.2603Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.893 154.342L56.4492 159.887L61.8929 165.431L67.3367 159.887L61.893 154.342Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.752 89.9042L288.309 95.4486L293.752 100.993L299.196 95.4486L293.752 89.9042Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M242.197 116.916L238.641 120.538L242.197 124.16L245.753 120.538L242.197 116.916Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.23962 87.0435L0.683594 90.6653L4.23962 94.2871L7.79564 90.6653L4.23962 87.0435Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.548 19.4003L254.992 23.0222L258.548 26.644L262.104 23.0222L258.548 19.4003Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8446_37844">
        <rect width="300" height="307" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
