import moment from 'moment';

import { Table, Column } from '@components';
import { useDownloadFile } from '@hooks/useUploadFiles';

import { UploadedFile } from 'types';

export type UploadedFilesDataProps = {
  data: UploadedFile[];
};

export const UploadedFilesData = ({ data }: UploadedFilesDataProps) => {
  const { downloadFile } = useDownloadFile();
  const columns: Column<UploadedFile> = {
    name: 'File Name',
    created_at: 'Date Uploaded',
    relative_to: 'Relevant to',
    extension: 'Type',
  };

  const dateModifiedRenderer = (value: string) => moment(value).format('D MMM YYYY, HH:mm');

  const relativeToRenderer = (value: string) => (value === 'spaces' ? 'Space' : 'Team');

  const extensionRenderer = (value: string) => (value === 'xlsx' ? 'Microsoft Excel' : 'CSV');

  const handleDownloadFile = async (id: number, row: UploadedFile) => {
    try {
      await downloadFile(id, row.name);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Table
      data={data}
      columns={columns}
      config={{
        columnRenderer: {
          created_at: dateModifiedRenderer,
          relative_to: relativeToRenderer,
          extension: extensionRenderer,
        },
        sortBy: {
          name: true,
          created_at: true,
        },
        defaultSortByColumn: 'name',
        actions: [
          {
            label: 'Download File',
            callback: (id, row) => handleDownloadFile(Number(id), row),
          },
        ],
      }}
    />
  );
};
