import { TableSortLabel } from '@mui/material';
import { Order } from '../../utils/quickSort';

interface Props {
  columnName: string;
  enabled: boolean;
  active: boolean;
  order: Order;
  onChange: (x: string, o: Order) => void;
  children: string;
}

const SortWrapper = ({ columnName, enabled, active, order, onChange, children }: Props) => {
  if (!enabled) return children;

  const handleClick = () => {
    const isDesc = active && order === 'desc';
    onChange(columnName, isDesc ? 'asc' : 'desc');
  };

  return (
    <TableSortLabel active={active} direction={active ? order : 'asc'} onClick={handleClick}>
      {children}
    </TableSortLabel>
  );
};

export { SortWrapper };
