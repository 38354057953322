import { ChangeEvent, FC, useState } from 'react';
import { Typography, FormHelperText } from '@mui/material';
import { SideBar, Button, TextInput, ConfirmationModal } from '@components';
import { sumHelper } from './helpers';
import { editFloorLocks } from '../../schemas';
import { StackViewState, FloorPartialLock } from '../../types';
import { sidebar as styles } from './styles';

type StaticDataType = {
  floorName: string;
  totalPlaces: FloorPartialLock;
  freePlaces: FloorPartialLock;
  maxValue: FloorPartialLock;
};

interface Props {
  onClose: () => void;
  data: StackViewState;
  floorId: string;
  onSave: (fId: string, v: FloorPartialLock) => void;
}

interface ContentProps {
  initialData: FloorPartialLock;
  staticData: StaticDataType;
  onSave: (v: FloorPartialLock) => void;
  onChange: (key: keyof FloorPartialLock, value: string) => void;
}

export const PartialFloorLock: FC<Props> = ({ onClose, data, floorId, onSave }: Props) => {
  const [modified, setModified] = useState(new Set());
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { buildingIdx, floorIdx } = data.buildingData[floorId];
  const floorData = data.buildings[buildingIdx].floors[floorIdx];

  const total = sumHelper(floorData.resources);
  const allocated = sumHelper(floorData.allocated_resources);
  const freePlaces = {
    workstations: (total[1] || 0) - (allocated[1] || 0),
    offices: (total[2] || 0) - (allocated[2] || 0),
  };

  const allocatedTeamAtFloor = sumHelper(floorData.allocated_resources.filter((item) => item.team));
  const lockedEmptySpace = sumHelper(
    floorData.allocated_resources.filter((item) => item.locked && !item.team),
  );

  const initialFormData: FloorPartialLock = {
    workstations: lockedEmptySpace.total ? lockedEmptySpace[1] || 0 : freePlaces.workstations,
    offices: lockedEmptySpace.total ? lockedEmptySpace[2] || 0 : freePlaces.offices,
  };

  const staticData: StaticDataType = {
    floorName: floorData.name,
    totalPlaces: {
      workstations: total[1] || 0,
      offices: total[2] || 0,
    },
    maxValue: {
      workstations: (total[1] || 0) - (allocatedTeamAtFloor[1] || 0),
      offices: (total[2] || 0) - (allocatedTeamAtFloor[2] || 0),
    },
    freePlaces,
  };

  const handleSave = (state: FloorPartialLock) => onSave(floorId, state);

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);
  const handleShowConfirmationDialog = () => {
    if (modified.size) {
      toggleConfirmationDialog();
    } else {
      onClose();
    }
  };
  const handleModifyingCheck = (key: keyof FloorPartialLock, value: string) => {
    const isModified = initialFormData[key] !== +value;
    setModified((state) => {
      const isPresent = state.has(key);
      if (isModified) {
        return isPresent ? new Set(state) : new Set(state.add(key));
      }
      state.delete(key);
      return new Set(state);
    });
  };

  return (
    <>
      <SideBar isOpen onClose={handleShowConfirmationDialog}>
        <SidebarContent
          initialData={initialFormData}
          staticData={staticData}
          onSave={handleSave}
          onChange={handleModifyingCheck}
        />
      </SideBar>
      {isOpen && (
        <ConfirmationModal
          isOpen
          onCancel={toggleConfirmationDialog}
          onLeave={onClose}
          title="You have unsaved changes"
          message="Changes will be lost if you leave without saving."
          successText="Return to editing"
          errorText="Discard changes and leave"
        />
      )}
    </>
  );
};

const SidebarContent = ({ initialData, onSave, staticData, onChange }: ContentProps) => {
  const [formData, setFormData] = useState<FloorPartialLock>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { floorName, totalPlaces, freePlaces, maxValue } = staticData;

  const handleSave = () => {
    if (!Object.keys(errors).length) {
      onSave(formData);
    }
  };

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: FloorPartialLock) => ({ ...data, [id]: value }));
      onChange(id as keyof FloorPartialLock, value);
      await editFloorLocks.validateAt(
        id,
        { ...formData, [id]: value },
        {
          context: {
            max: maxValue[id as keyof FloorPartialLock],
            message:
              'You can only lock as empty space that is not currently allocated to any team (Free Floor Capacity). Please adjust accordingly',
          },
        },
      );
      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  return (
    <div style={styles.wrapper}>
      <Button onClick={handleSave}>Save</Button>
      <div style={styles.header}>
        <Typography variant="h1" component="h1">
          Lock Space Empty
        </Typography>

        <Typography variant="body1" component="h6">
          <span style={styles.subtitle}>{floorName}</span>
        </Typography>

        <Typography variant="body2">
          <span style={styles.subtitle}>Total Floor Capacity:</span>
          <br />
          <p style={styles.info}>
            <span>Total Seats: {totalPlaces.workstations + totalPlaces.offices}</span>
            <span>Workstations: {totalPlaces.workstations}</span>
            <span>Offices: {totalPlaces.offices}</span>
          </p>
        </Typography>
        <Typography variant="body2" style={styles.mt20}>
          <span style={styles.subtitle}>Free Floor Capacity:</span>
          <br />
          <p style={styles.info}>
            <span>Total Seats: {freePlaces.workstations + freePlaces.offices}</span>
            <span>Workstations: {freePlaces.workstations}</span>
            <span>Offices: {freePlaces.offices}</span>
          </p>
        </Typography>
      </div>
      <div style={{ ...styles.grid, ...styles.gridBottom, ...styles.mt30 }}>
        <TextInput
          id="workstations"
          label="Locked Workstations"
          value={formData.workstations}
          onChange={handleFormChange}
          error={!!errors.workstations}
          type="number"
          InputProps={{ inputProps: { min: 0, max: maxValue.workstations } }}
          sx={{ gridArea: 'a' }}
          labelSx={{ overflow: 'visible' }}
        />
        <TextInput
          id="offices"
          label="Locked Offices"
          value={formData.offices}
          onChange={handleFormChange}
          error={!!errors.offices}
          type="number"
          InputProps={{ inputProps: { min: 0, max: maxValue.offices } }}
          sx={{ gridArea: 'b' }}
        />
        <FormHelperText
          error={!!errors.workstations || !!errors.offices || !!errors.collaborativeSpace}
          sx={{ gridArea: 'd' }}
        >
          {errors.workstations || errors.offices || errors.collaborativeSpace}
        </FormHelperText>
      </div>
    </div>
  );
};
