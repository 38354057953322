export const PRIMARY_SILVER_COLOR = {
  100: '#F5F7F7',
  150: '#E3E5E5',
  200: '#E5E9EB',
  250: '#D3D6D6',
  300: '#CAD1D3',
  400: '#BBC7CD',
  500: '#778F9C',
  600: '#85868C',
  700: '#5B5C61',
  900: '#222222',
  1000: '#000000',
};

export const SECONDARY_COLORS = {
  DARK_GREY: '#435254',
  VIOLET: '#A388BF',
};

export const PRIMARY_COLORS = {
  PRIMARY_GREEN: '#003F2D',
  PRIMARY_SILVER: PRIMARY_SILVER_COLOR[500],
  PRIMARY_BLACK: '#222222',
  PRIMARY_WHITE: '#FFFFFF',
  PRIMARY_DARK_GREEN: '#012A2D',
  PRIMARY_LIGHT_GREEN: '#e6f1ef',
  PRIMARY_ACCENT_GREEN: '#17E88F',
  PRIMARY_RED: '#E8445C',
};
