import {
  getQuery, baseUrl, postQuery, delQuery,
} from '.';

const getSummaryState = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/summary?customerId=${customerId}`;
  return getQuery(url);
};

const getDataStatus = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/portfolio/${customerId}?check_upload_status=True`;
  return getQuery(url);
};

const getFullDataSummary = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/portfolio/${customerId}/results-summary`;
  return getQuery(url);
};

const getCurrentState = (params) => {
  const { customerId, scenarioId, versionId } = params;
  const url = `${baseUrl}portfolio-strategy/api/state?customerId=${customerId}&scenarioId=${scenarioId}${versionId === null ? '' : `&versionId=${versionId}`}`;
  return getQuery(url);
};

const postCommitPlan = ({
  customerId,
  scenarioId,
  versionName,
  versionDesc,
}) => {
  const url = `${baseUrl}portfolio-strategy/api/target-state/confirm?customerId=${customerId}`;
  const config = {
    data: {
      scenarioId,
      versionName,
      versionDesc,
    },
  };
  return postQuery(url, config);
};

const getAllVersions = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/version?customerId=${customerId}`;
  return getQuery(url);
};

const postFutureState = (data, customerId) => {
  const config = {
    data,
  };
  const url = `${baseUrl}portfolio-strategy/api/target-state?customerId=${customerId}`;
  return postQuery(
    url,
    config,
  );
};
const getCostEstimatesFile = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/filedata/${customerId}?schemaId=cost_estimate_results&scenarioId=1`;
  return getQuery(url);
};
const getRentPredictionsFile = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/filedata/${customerId}?schemaId=rent_prediction_results`;
  return getQuery(url);
};

const postHeadCountForecast = (customerId, data) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast/calculate?customerId=${customerId}`;
  return postQuery(url, { data });
};

const getBusinessUnits = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/business-units?customerId=${customerId}`;
  return getQuery(url);
};

const getHeadCountForecasts = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast?customerId=${customerId}`;
  return getQuery(url);
};

const deleteHeadCountForecast = (customerId, forecastId) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast/${forecastId}?customerId=${customerId}`;
  return delQuery(url);
};

const postStoreHeadcountForecast = (customerId, data) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast?customerId=${customerId}`;
  return postQuery(url, { data });
};

const getCheckStatus = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast/check-status?customerId=${customerId}`;
  return getQuery(url);
};

const recalculateCurrentState = (customerId) => {
  const url = `${baseUrl}portfolio-strategy/api/headcount-forecast/recalculate-os`;
  return postQuery(url, {
    data: {
      customerId,
    },
  });
};
const getVersionData = (customerId, versionId) => {
  const url = `${baseUrl}portfolio-strategy/api/version/${versionId}?customerId=${customerId}`;
  return getQuery(url);
};

export {
  getCheckStatus,
  getVersionData,
  getBusinessUnits,
  postCommitPlan,
  postFutureState,
  postHeadCountForecast,
  getFullDataSummary,
  getSummaryState,
  getDataStatus,
  getAllVersions,
  getCurrentState,
  getCostEstimatesFile,
  getRentPredictionsFile,
  getHeadCountForecasts,
  postStoreHeadcountForecast,
  recalculateCurrentState,
  deleteHeadCountForecast,
};
