/* eslint-disable eqeqeq */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent, Typography } from '@mui/material';
import { ValidationError } from 'yup';
import {
  SideBar,
  Button,
  TextInput,
  Select,
  Link,
  PageSpinner,
  ConfirmationModal,
} from '@components';
import { useClient } from '@hooks/useClientData';
import { useCreateProject } from '@hooks/useProject';
import { handleTrimObjValue } from '@service/helpers';
import { CreateProjectForm, CustomerOption, SelectOption } from '../../types';
import { createProjectSchema } from '../../schemas';
import styles from './CreateProjectSideBar.module.css';
import file1 from '../../TEMP_FILES/Space Data - Template.xlsx';
import file2 from '../../TEMP_FILES/Teams Data - Template.xlsx';

interface Props {
  sideBarStatus: boolean;
  toggleSideBar: () => void;
}

interface ContentProps {
  initialData: CreateProjectForm;
  onChange: (key: keyof CreateProjectForm, value: string) => void;
  options?: SelectOption<number>[];
}

const CreateProjectSideBar: FC<Props> = ({
  sideBarStatus,
  toggleSideBar,
}: Props) => {
  const [modified, setModified] = useState(new Set());
  const [options, setOptions] = useState<SelectOption<number>[]>();
  const [isOpenConfModal, setIsOpen] = useState<boolean>(false);
  const { loadClient } = useClient();
  
  const getOptions = async () => {
    try {
      const result = await loadClient();
      const customers: SelectOption<number>[] = result.customers.map(
        ({ customerId, customerName }: CustomerOption) => ({
          label: customerName,
          value: customerId,
        }));
      setOptions(customers);
    } catch (e) {
      console.error(e);
    }
  };

  const initialFormData: CreateProjectForm = {
    name: '',
    customerId: undefined,
    description: '',
  };

  const toggleConfirmationDialog = () => setIsOpen((state) => !state);
  const handleShowConfirmationDialog = () => {
    if (modified.size) {
      toggleConfirmationDialog();
    } else {
      toggleSideBar();
    }
  };
  const handleModifyingCheck = (key: keyof CreateProjectForm, value: string | number) => {
    const isModified = initialFormData[key] != value;
    setModified((state) => {
      const isPresent = state.has(key);
      if (isModified) {
        return isPresent ? new Set(state) : new Set(state.add(key));
      }
      state.delete(key);
      return new Set(state);
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <>
      <SideBar isOpen={sideBarStatus} onClose={handleShowConfirmationDialog}>
        <SidebarContent
          initialData={initialFormData}
          onChange={handleModifyingCheck}
          options={options}
        />
      </SideBar>
      {isOpenConfModal && (
        <ConfirmationModal
          isOpen
          onCancel={toggleConfirmationDialog}
          onLeave={toggleSideBar}
          title="You have unsaved changes"
          message="Changes will be lost if you leave without saving."
          successText="Return to editing"
          errorText="Discard changes and leave"
        />
      )}
    </>
  );
};

const SidebarContent = ({ initialData, onChange, options }: ContentProps) => {
  const navigate = useNavigate();
  const createProject = useCreateProject();
  const [formData, setFormData] = useState<CreateProjectForm>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: CreateProjectForm) => ({ ...data, [id]: value }));
      onChange(id as keyof CreateProjectForm, value);
      await createProjectSchema.validateAt(id, { ...formData, [id]: value });
      setErrors((error) => ({ ...error, [id]: '' }));
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;
    setFormData((data: CreateProjectForm) => ({ ...data, customerId: value as number }));
    onChange('customerId' as keyof CreateProjectForm, value as string);
    setErrors((error) => ({ ...error, customerId: '' }));
  };

  const handleError = (validationErrors: ValidationError[]) => {
    const errors = validationErrors.reduce((acc: Record<string, string>, curr: ValidationError) => {
      acc[curr.path!] = curr.message;
      return acc;
    }, {});
    setErrors(errors);
  };

  const handleFormValidation = async (): Promise<CreateProjectForm | undefined> => {
    let dataToResponse;
    try {
      dataToResponse = await createProjectSchema.validate(formData, { abortEarly: false });
      setErrors({});
    } catch (e: any) {
      handleError(e.inner);
    }
    return dataToResponse;
  };

  const handleSubmit = async () => {
    const dataToRequest: CreateProjectForm | undefined = await handleFormValidation();
    if (dataToRequest) {
      try {
        const response = await createProject.mutateAsync(handleTrimObjValue(dataToRequest));
        const createdProjectId = response.data.id;
        navigate(`/v2/projects/${createdProjectId}/data-upload`);
      } catch (e: any) {
        if (e.response?.data?.error === 'Input data validation error') {
          handleError([{ path: 'name', message: e.response.data.details }] as ValidationError[]);
        }
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={handleSubmit}>Create</Button>
      <Typography variant="h1" component="h1">
        Create a Project
      </Typography>
      <TextInput
        id="name"
        required
        label="Project name"
        value={formData.name || ''}
        onChange={handleFormChange}
        error={!!errors.name}
        helperText={errors.name}
      />
      <Select
        id="customerId"
        required
        label="Client"
        options={options || []}
        value={formData.customerId || ''}
        onChange={handleSelectChange}
        error={!!errors.customerId}
        helperText={errors.customerId}
      />
      <TextInput
        id="description"
        label="Description"
        multiline
        minRows={6}
        value={formData.description || ''}
        onChange={handleFormChange}
        error={!!errors.description}
        helperText={errors.description}
      />
      <div className={styles.textBlock}>
        <Typography variant="body1" component="p">
          On the next step you’ll need to upload Source Data. If you don’t have your files ready
          yet, here’s the template for you to download:
        </Typography>
        <Link href={file1} download="Space Data - Template.xlsx">
          Space Template
        </Link>
        <Typography variant="body1" component="p">
          and
        </Typography>
        <Link href={file2} download="Teams Data - Template.xlsx">
          Team Template
        </Link>
      </div>
      <PageSpinner isOpen={createProject.isLoading || !options} />
    </div>
  );
};

export default CreateProjectSideBar;
