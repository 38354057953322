/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  existingData: [],
  newData: [],
};
const getMappingReview = createSelector((state) => state, (state) => state.mappingReview);

const mappingReviewSlice = createSlice({
  name: 'mappingReview',
  initialState,
  reducers: {
    addExistingData: (state, action) => { state.existingData.push(action.payload); },
    addBulkExistingData: (state, action) => { state.existingData.push(...action.payload); },
    addNewData: (state, action) => { state.newData.push(action.payload); },
    addRemaningNewData: (state, action) => {
      const { payload } = action;
      state.newData.push(...payload);
    },
    addRemainingExistingData: (state, { payload }) => { state.existingData.push(...payload); },
    resetAllData: (state) => {
      state.existingData = initialState.existingData;
      state.newData = initialState.newData;
    },
    resetExistingData: (state) => {
      state.existingData = initialState.existingData;
    },
    resetNewData: (state) => {
      state.newData = initialState.newData;
    },
  },
});

export {
  getMappingReview,
};

export const {
  addExistingData,
  addBulkExistingData,
  addNewData,
  resetNewData,
  resetAllData,
  resetExistingData,
  addRemaningNewData,
  addRemainingExistingData,
} = mappingReviewSlice.actions;

export default mappingReviewSlice.reducer;
