import { useQuery } from 'react-query';
import { useApi } from './useApi';
import { useAlert } from '../context/AlertContext';
import { AwsGenerateUrl, UploadedFile, DownloadLink } from '../types';

const url = (path: string) => `/occupancy-planning/api/${path}`;

type UploadFileToS3Args = {
  customer_id: number;
  relative_to: 'spaces' | 'teams';
  filename: string;
  file: Blob;
};

type MapUploadedFileWithLinkArgs = {
  name: string;
  path: string;
  relative_to: 'spaces' | 'teams';
  extension: string;
  project_id: number;
};

const getExtension = (fileName: string) => fileName.split('.').pop();

export const useUploadFile = (project_id: number) => {
  const api = useApi();
  const alert = useAlert();

  const fetchLink = async ({
    customer_id,
    filename,
  }: {
    customer_id: number;
    filename: string;
  }) => {
    const response = await api.post<AwsGenerateUrl>(url('file-load-url'), {
      customer_id,
      filename,
    });
    return response?.data;
  };

  const mapUploadDedFile = async (data: MapUploadedFileWithLinkArgs) =>
    api.post(url('uploaded-files'), data);

  const uploadFileToS3 = async ({
    customer_id,
    filename,
    file,
    relative_to,
  }: UploadFileToS3Args) => {
    try {
      // get S3 link for upload
      const awsData = await fetchLink({ customer_id, filename });

      // prepare data for upload
      const formData = new FormData();
      Object.entries(awsData.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('file', file);
      await api.post(awsData.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
      });

      await mapUploadDedFile({
        extension: getExtension(filename)!,
        name: filename,
        path: awsData.fields.key,
        project_id,
        relative_to,
      });
    } catch (e: any) {
      console.error(e);
      alert('error', e, e?.response?.data?.status);
    }
  };

  return {
    uploadFileToS3,
  };
};

export const useUploadedFiles = (project_id: number) => {
  const api = useApi();
  const alert = useAlert();

  const fetchUploadedFiles = async () => {
    const response = await api.get<UploadedFile[]>(url('uploaded-files'), {
      params: {
        project_id,
      },
    });
    return response?.data;
  };

  return {
    uploadedFiles: useQuery<UploadedFile[]>(['uploaded-files', project_id], fetchUploadedFiles, {
      enabled: !!project_id,
      onError: (e: any) => alert('error', e, e?.response?.data?.status),
    }),
  };
};

export const useDownloadFile = () => {
  const api = useApi();
  const alert = useAlert();

  const fetchDownloadLink = async (id: number) => {
    const response = await api.get<DownloadLink>(`${url('uploaded-files')}/${id}`);
    return response?.data?.download_link;
  };

  const fetchFile = async (link: string) => {
    const response = await api.get<Blob>(link, {
      responseType: 'blob',
      headers: {
        Authorization: null,
      },
    });

    return response?.data;
  };

  const downloadFile = async (id: number, fileName: string) => {
    try {
      const link = await fetchDownloadLink(id);
      const blob = await fetchFile(link);

      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      alert('success', 'File successfully downloaded');
    } catch (e: any) {
      console.error(e);
      alert('error', e, e?.response?.data?.status);
    }
  };

  return {
    downloadFile,
  };
};
