import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { Table, Toggle, Column, PageSpinner, ItemsNumber } from '@components';
import { useProjects } from '@hooks/useProject';
import { useHeaderData } from '@hooks/useHeaderData';
import { PRIMARY_COLORS } from '@constants/colors';

import { EditProjectSideBar } from '../edit-project';
import { CreateProject } from '../sidebar-create-new-project';

import { Status } from './Status';
import { Project, ProjectStatus, STATUS_COLORS } from '../../types';

const ProjectListWithHeader = () => {
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('customer_name');
  const isArchived = searchParams.get('isArchived');
  const activeFilters = filters ? { customer_name: filters.split(',') } : null;

  useHeaderData({
    title: 'Occupancy Projects',
    ActionBar: CreateProject,
  });

  const {
    fetchQuery: { isLoading, isFetching, data },
    editProject,
  } = useProjects(!!isArchived);

  return (
    <>
      <ProjectList
        data={data}
        activeFilters={activeFilters}
        isArchived={!!isArchived}
        onChange={editProject}
      />
      <PageSpinner isOpen={isLoading || isFetching} />
    </>
  );
};

const ProjectList = ({
  data,
  activeFilters,
  onChange,
  isArchived,
}: {
  data?: Project[];
  activeFilters: any;
  isArchived: boolean;
  onChange: any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEditProjectModal, setShowModal] = useState<number | string | undefined>();

  const toggleEditProjectModal = (id?: number | string) => setShowModal(id);

  const handleArchiveProject = async (id?: number | string) => {
    try {
      await onChange.mutate({ id: +id!, is_archived: !isArchived });
    } catch (e) {
      console.error(e);
    }
  };

  const handleShowArchived = () => {
    if (searchParams.has('isArchived')) {
      searchParams.delete('isArchived');
      setSearchParams(searchParams);
    } else {
      searchParams.set('isArchived', 'true');
      setSearchParams(searchParams);
    }
  };
  if (data) {
    return (
      <>
        <div
          style={{
            marginBottom: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ItemsNumber number={data.length} label="Project" plural="Projects" />
          <Toggle
            onChange={handleShowArchived}
            value={!!isArchived}
            label="Show Archived Projects"
            labelPlacement="start"
          />
        </div>
        <Table
          data={data}
          columns={columns}
          emptyMsg="There are no projects to display yet."
          config={{
            columnRenderer: {
              status: StatusRenderer,
              updated_at: dateModifiedRenderer,
              headcount: emptyCellsModifier,
              seats: emptyCellsModifier,
              description: emptyCellsModifier,
            },
            styles: {
              name: { width: '20%' },
              customer_name: { width: '20%' },
              headcount: { width: '5%' },
              seats: { width: '5%' },
              status: { maxWidth: 'max-content' },
              updated_at: { maxWidth: 'max-content' },
              updated_by: { maxWidth: 'max-content' },
              description: { width: '20%' },
            },
            activeFilters,
            filterBy: {
              customer_name: true,
            },
            sortBy: {
              customer_name: true,
              updated_at: true,
            },
            defaultSortByColumn: 'updated_at',
            columnWrapper: {
              ...(isArchived
                ? {}
                : {
                    name: NameLink,
                    customer_name: NameLink,
                    headcount: NameLink,
                    seats: NameLink,
                    status: NameLink,
                    updated_at: NameLink,
                    updated_by: NameLink,
                    description: NameLink,
                  }),
            },
            actions: [
              {
                label: 'Edit Details',
                callback: toggleEditProjectModal,
                config: { dividerAfter: true, disabled: !!isArchived },
              },
              {
                label: isArchived ? 'Restore' : 'Archive',
                callback: handleArchiveProject,
                config: { color: isArchived ? 'inherit' : PRIMARY_COLORS.PRIMARY_RED },
              },
            ],
          }}
        />
        {showEditProjectModal && (
          <EditProjectSideBar
            isOpen={!!showEditProjectModal}
            toggleSideBar={() => toggleEditProjectModal()}
            data={data}
            id={showEditProjectModal as number}
            editProject={onChange}
          />
        )}
      </>
    );
  }
  return null;
};

export { ProjectListWithHeader as ProjectList };

const columns: Column<Project> = {
  name: 'Project Title',
  customer_name: 'Client',
  headcount: 'Headcount',
  seats: 'Seats',
  status: 'Status',
  updated_at: 'Date Modified',
  updated_by: 'Modified By',
  description: 'Description',
};

const StatusRenderer = ({ label, name }: ProjectStatus) => {
  const bgColor = STATUS_COLORS[name] || PRIMARY_COLORS.PRIMARY_SILVER;
  const color =
    name === 'available_n_plans' ? PRIMARY_COLORS.PRIMARY_BLACK : PRIMARY_COLORS.PRIMARY_WHITE;
  return (
    <Status color={color} backgroundColor={bgColor}>
      {label}
    </Status>
  );
};

const NameLink = ({ row, children }: { row: Project; children: any }) => (
  <Link to={handleLink(row)}>{children}</Link>
);

const dateModifiedRenderer = (value: string) => moment(value).format('D MMM YYYY, HH:mm');
const emptyCellsModifier = (value: string) => value || '--';

const handleLink = (row: Project) =>
  ['plan_of_record', 'available_n_plans'].includes(row.status.name)
    ? `/v2/projects/${row.id}/plans`
    : `/v2/projects/${row.id}/data-upload`;
