import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { PageSpinner } from '@components';
import { useTeamsForAdjacencies } from '@hooks/useAdjacencies';

import { AdjacenciesForm, Adjacency } from '../project/data-upload/adjacency-form';
import { StackViewState } from '../../types';

export type AdjacencyScoresProps = {
  data: StackViewState;
  onDataEdit: (v: StackViewState | null) => void;
  isEdiatable: boolean;
};

export const AdjacencyScores = ({ data, onDataEdit, isEdiatable }: AdjacencyScoresProps) => {
  const { projectId } = useParams();
  const { teamsForAdjacencies } = useTeamsForAdjacencies(projectId!);

  if (teamsForAdjacencies.isLoading) {
    return <PageSpinner isOpen />;
  }

  const updateAdjacencies = (adjacencies: Adjacency[]) => {
    const updatedData: StackViewState = {
      ...data,
      solution: {
        ...data.solution,
        adjacencies,
      },
    };
    onDataEdit(updatedData);
  };

  return (
    <Box sx={{ maxWidth: '720px' }}>
      <AdjacenciesForm
        teams={teamsForAdjacencies?.data || []}
        adjacencies={data?.solution?.adjacencies}
        saveAdjacencies={updateAdjacencies}
        isEditable={isEdiatable}
      />
    </Box>
  );
};
