import { Document, Page, View, Text } from '@react-pdf/renderer';

import { sumHelper, groupByTeam } from '../../plan-details/helpers';
import { displayFreeFloors } from '../../occupancy-plans/helpers';
import { Team, PlanDetailsResponse, Floor, TeamData } from '../../../types';

import { styles } from './styles';

export type StackViewPdfProps = {
  planDetails: PlanDetailsResponse;
};

interface ItemProps {
  '1'?: number;
  '2'?: number;
  '3'?: number;
  total: number;
  locked: boolean;
  team: string;
}

type TeamBoxProps = {
  name: string;
  color: string;
  seats: number;
  totalSeats: number;
};

const TeamBox = ({ name, color, seats, totalSeats }: TeamBoxProps) => (
  <View style={styles.team}>
    <View style={{ ...styles.teamColor, backgroundColor: color }} />
    <View style={styles.teamInfo}>
      <Text style={styles.teamName}>{name}</Text>
      <Text style={styles.teamSize}>
        {totalSeats ? `${seats}/${totalSeats} seats` : `${seats} seats`}
      </Text>
    </View>
  </View>
);

type FloorPlateProps = {
  name: string;
  floor: Floor;
  teams: TeamData;
  allocatedResource: Team[];
  shouldRenderTeamsLabel: boolean;
};

const Status = () => <Text style={styles.status}>(Plan of Record)</Text>;

const FloorPlate = ({
  name,
  floor,
  teams,
  allocatedResource,
  shouldRenderTeamsLabel = false,
}: FloorPlateProps) => {
  const allocatedTotal = sumHelper(floor.allocated_resources);
  const resourcesTotal = sumHelper(floor.resources);
  const dataGroupedByTeam: Record<string, ItemProps> = groupByTeam(floor.allocated_resources);

  const renderFloorFilling = () => {
    if (Object.keys(dataGroupedByTeam).length) {
      return Object.values(dataGroupedByTeam).map((item: ItemProps, idx: number) => {
        if (item.team) {
          return (
            <View
              key={`${item.team}-${idx}`}
              style={{
                ...styles.teamOnFloor,
                width: `${((item.total || 0) / resourcesTotal.total) * 100}%`,
                backgroundColor: teams[item.team]?.color,
              }}
            >
              <Text style={styles.teamAmount}>{item.total}</Text>
            </View>
          );
        }

        return (
          <View
            key={`${item.team}-${idx}`}
            style={{
              ...styles.teamOnFloor,
              ...styles.emptyFloor,
              ...styles.lockedEmpty,
              width: `${((item.total || 0) / resourcesTotal.total) * 100}%`,
            }}
          >
            <Text style={styles.whiteEmptyText}>{item.total}</Text>
          </View>
        );
      });
    }

    return (
      <View style={{ ...styles.teamOnFloor, ...styles.emptyFloor }}>
        <Text style={styles.emptyFloorText}>empty floor</Text>
      </View>
    );
  };

  const renderTeams = () => {
    if (Object.keys(dataGroupedByTeam).length) {
      return Object.values(dataGroupedByTeam).map((item: ItemProps, idx: number) => {
        if (item.team) {
          const { color, name, resources } = teams[item.team];
          const totalTeamAmount = resources.total || 0;
          return (
            <TeamBox
              key={`${item.team}-${idx}`}
              name={name}
              color={color}
              seats={item.total}
              totalSeats={totalTeamAmount}
            />
          );
        }

        return (
          <TeamBox
            key={item.team}
            name="Locked empty"
            color="#778F9C"
            seats={item.total}
            totalSeats={0}
          />
        );
      });
    }

    return null;
  };

  return (
    <View wrap={false} style={styles.floor}>
      <View style={styles.floorInfo}>
        <View style={styles.floorMetaTop}>
          <View style={styles.floorMetaLeft}>
            <Text style={styles.floorNumber}>{floor.name}</Text>
            <Text>{name}</Text>
          </View>
          <View style={styles.floorMetaRight}>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.['2'] || 0}/${
              resourcesTotal['2'] || 0
            } offices`}</Text>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.['1'] || 0}/${
              resourcesTotal['1'] || 0
            } workstations`}</Text>
            <Text style={styles.floorMetaRightItem}>{`${allocatedTotal?.total || 0}/${
              resourcesTotal.total || 0
            } seats`}</Text>
            {shouldRenderTeamsLabel && <Text style={styles.teamsLabel}>teams</Text>}
          </View>
        </View>
        <View style={styles.floorPlate}>
          <View style={styles.floorFilling}>{renderFloorFilling()}</View>
          {renderTeams()}
        </View>
      </View>
    </View>
  );
};

const UnAllocatedTeamsSection = ({
  unallocatedTeams,
  teams,
}: {
  unallocatedTeams: Team[];
  teams: TeamData;
}) => {
  if (unallocatedTeams.length) {
    let unallocatedNumber = 0;
    let totalNumber = 0;
    unallocatedTeams.forEach((team) => {
      unallocatedNumber += team.unallocated.total;
      totalNumber += teams[team.id].resources.total;
    });
    return (
      <View wrap={false} style={{ ...styles.floorPlate, ...styles.unAllocatedRow }}>
        <View style={styles.unAllocatedInfo}>
          <View style={styles.population}>
            <Text style={styles.populationLabel}>unallocated demand: </Text>
            <Text>{`${unallocatedNumber}/${totalNumber}`}</Text>
          </View>
          <Text style={styles.unallocatedTeams}>unallocated teams:</Text>
        </View>
        {unallocatedTeams.map((team) => (
          <TeamBox
            key={team.id}
            name={teams[team.id].name}
            color={teams[team.id].color}
            seats={team.unallocated.total}
            totalSeats={teams[team.id].resources.total}
          />
        ))}
      </View>
    );
  }

  return null;
};

export const StackViewPdf = ({ planDetails }: StackViewPdfProps) => {
  const getAllocatedSeatsRow = (details: {
    allocated: number;
    total: number;
    offices: number;
    workstations: number;
  }) =>
    `${details.allocated}/${details.total} (${details.workstations} workstations, ${details.offices} offices)`;

  return (
    <Document>
      <Page orientation="landscape" wrap style={styles.container}>
        <Text style={styles.title}>
          {planDetails?.name} {planDetails?.is_plan_of_record && <Status />}
        </Text>

        <View style={styles.metaInfoRow}>
          <Text style={styles.metaInfoItem}>
            <Text style={styles.metaInfoLabel}>Adjacency score:</Text>{' '}
            {planDetails?.adj_value ? `${planDetails.adj_value} of 5` : 'N/A'}
          </Text>
          <Text style={styles.metaInfoItem}>
            <Text style={styles.metaInfoLabel}>Free floors:</Text>{' '}
            {displayFreeFloors(planDetails?.free_floors)}
          </Text>
        </View>

        <View style={styles.metaInfoRow}>
          <Text style={styles.metaInfoItem}>
            <Text style={styles.metaInfoLabel}>Allocated seats:</Text>{' '}
            {getAllocatedSeatsRow(planDetails.details)}
          </Text>
        </View>

        {planDetails.buildings.map((building, buildingIndex) =>
          building.floors.map((floor, floorIndex) => (
            <FloorPlate
              key={floor.id}
              name={building.name}
              floor={floor}
              teams={planDetails.teamData}
              allocatedResource={planDetails.allocated}
              shouldRenderTeamsLabel={buildingIndex + floorIndex === 0}
            />
          )),
        )}

        <UnAllocatedTeamsSection
          unallocatedTeams={planDetails.unallocated}
          teams={planDetails.teamData}
        />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
