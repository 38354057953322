export class Queue<T> {
  private static instance: Queue<any>;

  private elements: Record<string, T>;

  private head: number;

  private tail: number;

  constructor() {
    this.elements = {};
    this.head = 0;
    this.tail = 0;
  }

  public enqueue(element: T): void {
    this.elements[this.tail] = element;
  }

  public dequeue(): void {
    delete this.elements[this.head];
    this.head++;
  }

  public peek(): T {
    this.tail++;
    return this.elements[this.head];
  }

  public static getInstance() {
    if (!Queue.instance) {
      Queue.instance = new Queue();
    }

    return Queue.instance;
  }

  public isEmpty(): boolean {
    return Object.keys(this.elements).length === 0;
  }
}
