import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Close } from '@icons/index';
import { IconButton, Button } from '@components';

export type PlanOfRecordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  planName: string;
  isDisabled: boolean;
};

export const PlanOfRecordModal = ({
  isOpen,
  onClose,
  onSubmit,
  planName,
  isDisabled = false,
}: PlanOfRecordModalProps) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>
      Change Plan of Record
      <IconButton aria-label="close" onClick={onClose}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        The plan of record is already selected for this project and it is
        <span style={{ fontWeight: 600 }}> {planName}</span>. Do you want to change the plan of
        record to this one?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button type="secondary" onClick={onClose} disabled={isDisabled}>
        Cancel
      </Button>
      <Button onClick={onSubmit} disabled={isDisabled}>
        Change
      </Button>
    </DialogActions>
  </Dialog>
);
