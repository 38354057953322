/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { v4 as uuid4 } from 'uuid';
import { parsedErrorMessage } from '../../service';
import { postMappingAcion } from '../../service/appService';

const matchedRecordsAdapter = createEntityAdapter();
const unmatchedRecordsAdapter = createEntityAdapter();
const newRecordsAdapter = createEntityAdapter();
const initialState = {
  showSpinner: false,
  // columns is return from dedup but not inused currently, useful to sort for savedata?
  columns: [],
  unmatched_records: unmatchedRecordsAdapter.getInitialState(),
  new_records: newRecordsAdapter.getInitialState(),
  matched_records: matchedRecordsAdapter.getInitialState(),
};

const postMappedData = createAsyncThunk(
  'mappingData/getStatus',
  async (payload, thunkApi) => {
    const {
      schemaId,
      customerId,
      columns,
      data,
    } = payload;
    try {
      const response = await postMappingAcion({ columns, data }, schemaId, customerId);
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(parsedErrorMessage(e));
    }
  },
);

const addExistingData = (state, action) => state.existing_data.push(action.payload);

const mappingDataSlice = createSlice({
  name: 'mappingData',
  initialState,
  reducers: {
    addExistingData,
    notAMatch(state, action) {
      const { id, new_data } = action.payload;
      newRecordsAdapter.addOne(state.new_records, { id, new_data });
      unmatchedRecordsAdapter.removeOne(state.unmatched_records, id);
    },
    resetPendingTable: (state) => {
      unmatchedRecordsAdapter.removeAll(state.unmatched_records);
      matchedRecordsAdapter.removeAll(state.matched_records);
      newRecordsAdapter.removeAll(state.new_records);
    },
    removeAllUnmatched: (state) => { unmatchedRecordsAdapter.removeAll(state.unmatched_records); },
    removeMatch: (state, action) => { matchedRecordsAdapter.removeOne(state.matched_records, action.payload); },
    removeUnmatch: (state, action) => { unmatchedRecordsAdapter.removeOne(state.unmatched_records, action.payload); },
    removeNew: (state, action) => { newRecordsAdapter.removeOne(state.new_records, action.payload); },
    // eslint-disable-next-line no-param-reassign
    setSpinner: (state, action) => { state.showSpinner = action.payload; },
  },
  extraReducers: (builder) => {
    builder.addCase(postMappedData.fulfilled, (state, action) => {
      const {
        matched_records,
        new_records,
        unmatched_records,
        columns,
      } = action.payload;
      state.showSpinner = false;
      if (columns) {
        const mod_new_records = new_records.map((new_record) => ({
          new_data: new_record,
          id: uuid4(),
        }));
        state.columns = columns;
        matchedRecordsAdapter.setMany(state.matched_records, matched_records);
        newRecordsAdapter.setMany(state.new_records, mod_new_records);
        unmatchedRecordsAdapter.setMany(state.unmatched_records, unmatched_records);
      }
    });
    builder.addCase(postMappedData.pending, (state) => {
      state.showSpinner = true;
    });
    builder.addCase(postMappedData.rejected, (state) => {
      state.showSpinner = false;
    });
  },
});

const getShowSpinner = createSelector((state) => state, (state) => state.mappingData.showSpinner);

const { selectAll: selectAllUnmatched } = unmatchedRecordsAdapter.getSelectors((state) => state.mappingData.unmatched_records);
const { selectAll: selectAllMatched } = matchedRecordsAdapter.getSelectors((state) => state.mappingData.matched_records);
const { selectAll: selectAllNew } = newRecordsAdapter.getSelectors((state) => state.mappingData.new_records);
export {
  selectAllUnmatched,
  selectAllMatched,
  selectAllNew,
  postMappedData,
  getShowSpinner,
};

export const {
  notAMatch,
  removeMatch,
  removeNew,
  removeUnmatch,
  removeAllUnmatched,
  resetPendingTable,
  setSpinner,
} = mappingDataSlice.actions;

export default mappingDataSlice.reducer;
