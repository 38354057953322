/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import { useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useQueryClient, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useHeaderData } from '@hooks/useHeaderData';
import { useProject } from '@hooks/useProject';
import { usePlanDetails } from '@hooks/usePlanDetails';
import { PRIMARY_COLORS, SECONDARY_COLORS } from '@constants/colors';
import { OverflowTooltip, PageSpinner } from '@components';
import { ActionBar } from './ActionBar';
import { Building } from '../plan-details/Building';
import { KeyValue } from '../occupancy-plans/PlanCard';
import { HeaderData } from '../../context/headerData';
import { Status } from '../project/Status';
import {
  Project,
  BreadcrumbOptions,
  PlanDetailsResponse,
  Building as BuildingType,
  TeamData,
} from '../../types';
import { displayFreeFloors } from '../occupancy-plans/helpers';
import { plan as styles } from './styles';

interface Props {
  data: UseQueryResult<PlanDetailsResponse, any>;
  isDisplayTitle?: boolean;
  teams?: TeamData;
}

export const ComparePlans = () => {
  const { projectId, planIdA, planIdB } = useParams();
  const { data: projectData, isLoading } = useProject(projectId!, true);
  const { planDetails: planA } = usePlanDetails(planIdA!);
  const { planDetails: planB } = usePlanDetails(planIdB!);
  const queryClient = useQueryClient();

  useHeaderData(handlePrepareHeaderData(projectId!, projectData!, planA.data!, planB.data!));

  useEffect(() => {
    if (planA.data && projectData) {
      queryClient.setQueryData(['planDetails', Number(planIdA)], (data: any) => {
        if (data) {
          const updatedTeamData = { ...data.teamData };
          for (const item of projectData.teams!) {
            if (item.color) {
              updatedTeamData[item.id].color = item.color;
            }
          }
          return {
            ...data,
            teamData: updatedTeamData,
          };
        }
        return data;
      });
    }
  }, [planA.status, projectData]);

  if (isLoading || planA.isLoading || planB.isLoading) {
    return <PageSpinner isOpen />;
  }

  return (
    <div style={styles.wrapper}>
      <PlanBlock data={planA} teams={planA?.data?.teamData} isDisplayTitle />
      <PlanBlock data={planB} teams={planA?.data?.teamData} />
    </div>
  );
};

const PlanBlock = ({ data, teams, isDisplayTitle }: Props) => {
  const { data: plan, isLoading } = data;

  if (isLoading || !teams) {
    return <PageSpinner isOpen />;
  }

  if (plan) {
    return (
      // due to component reusing, have to wrap by DragDropContext
      <DragDropContext onDragEnd={() => {}}>
        <section style={styles.planBlock}>
          <Typography variant="h2" style={styles.title}>
            <OverflowTooltip>{plan.name}</OverflowTooltip>
            {plan.is_plan_of_record && <PlanOfRecordLabel />}
          </Typography>
          <div style={styles.buildings}>
            {(plan?.buildings || []).map((props: BuildingType) => (
              <Building key={props.name} teams={teams!} isEditable={false} {...props} />
            ))}
          </div>
          <div style={styles.subtitle}>
            {isDisplayTitle && <Typography variant="h2">Key Summary Metrics</Typography>}
          </div>
          <div style={styles.summary}>
            <div style={styles.grid}>
              <KeyValue
                label="Allocated Seats"
                value={`${plan.seats.allocated} of ${plan.seats.total}`}
              />
              <KeyValue
                label="Unallocated Demand"
                value={`${plan.population.unallocated} of ${plan.population.total}`}
              />
              <KeyValue
                label="Workstations"
                value={`${plan.workstations.allocated} of ${plan.workstations.total}`}
              />
              <KeyValue
                label="Offices"
                value={`${plan.private_offices.allocated} of ${plan.private_offices.total}`}
              />
              <KeyValue
                label="Modified Date"
                value={moment(plan.updated_at).format('D MMM YYYY, HH:mm')}
              />
              <KeyValue label="Modified By" value={plan.updated_by} />
            </div>
            <span style={styles.line} />
            <div style={styles.grid}>
              <KeyValue
                label="Adjacency Score"
                value={plan.adj_value ? `${plan.adj_value} of 5` : 'N/A'}
              />
              <KeyValue label="Free Floors" value={displayFreeFloors(plan.free_floors)} />
            </div>
          </div>
        </section>
      </DragDropContext>
    );
  }
  return null;
};

const handlePrepareHeaderData = (
  projectId: string,
  projectData: Project,
  planA: PlanDetailsResponse,
  planB: PlanDetailsResponse,
) => {
  if (!(projectData && projectId)) return undefined;

  const { customer_name, name } = projectData;

  const breadcrumbList: BreadcrumbOptions[] = [
    { label: 'Occupancy Projects', link: '/v2/projects' },
    {
      label: customer_name || '',
      link: `/v2/projects?customer_name=${customer_name!}`,
    },
    { label: name, link: `/v2/projects/${projectId}/plans` },
  ];

  return {
    title: 'Compare Plans',
    breadcrumb: breadcrumbList,
    ActionBar,
    props: {
      planA,
      planB,
    },
  } as HeaderData;
};

const PlanOfRecordLabel = () => (
  <Status
    color={PRIMARY_COLORS.PRIMARY_WHITE}
    backgroundColor={SECONDARY_COLORS.VIOLET}
    sx={styles.planOfRecordLabel}
  >
    Plan of Record
  </Status>
);
