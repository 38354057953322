import { CSSProperties, ChangeEvent, FC, useState } from 'react';
import { Typography } from '@mui/material';
import { SideBar, Button, TextInput, PageSpinner } from '@components';
import { useDuplicatePlan } from '@hooks/usePlans';
import { usePlanDetails } from '@hooks/usePlanDetails';
import { handleTrimObjValue } from '@service/helpers';
import { BaseDataState, PlanSolution, DuplicatePlan, Strategy, STRATEGIES_LIST } from '../../types';
import { savePlanSchema } from '../../schemas';

interface Props {
  isOpen: boolean;
  toggleSideBar: () => void;
  planId: number;
  projectId: string;
}

interface StaticData {
  project_id: number;
  strategy_id: number;
  solution: PlanSolution;
}

interface ContentProps {
  initialData: BaseDataState;
  staticData: StaticData;
}

export const SaveAsSideBar: FC<Props> = ({ isOpen, toggleSideBar, planId, projectId }: Props) => {
  const { planDetails } = usePlanDetails(String(planId));

  const initialData = {
    name: `${planDetails?.data?.name || ''} [copy]`,
    description: planDetails?.data?.description || '',
  } as BaseDataState;

  const staticData = {
    project_id: +projectId,
    strategy_id:
      STRATEGIES_LIST.find((item: Strategy) => item.key === planDetails?.data?.strategy)?.value ||
      1,
    solution: planDetails?.data?.solution,
  } as StaticData;

  return (
    <SideBar isOpen={isOpen} onClose={toggleSideBar}>
      {!planDetails.data ? (
        <PageSpinner isOpen />
      ) : (
        <SideBarContent initialData={initialData} staticData={staticData} />
      )}
    </SideBar>
  );
};

const SideBarContent = ({ initialData, staticData }: ContentProps) => {
  const duplicatePlan = useDuplicatePlan();
  const [formData, setFormData] = useState<BaseDataState>(initialData);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: BaseDataState) => ({ ...data, [id]: value }));
      await savePlanSchema.validateAt(id, { ...formData, [id]: value });
      setErrors((error) => {
        const newState = { ...error };
        delete newState[id];
        return newState;
      });
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleSubmit = async () => {
    if (!Object.keys(errors).length) {
      try {
        const response = await duplicatePlan.mutateAsync(
          handleTrimObjValue({
            ...formData,
            ...staticData,
          } as DuplicatePlan));
        if (response.data) {
          window.location.replace(`/v2/projects/${staticData.project_id}/plans/${response.data.id}`);
        }
      } catch (e: any) {
        if (e.response?.data?.error === 'Input data validation error') {
          setErrors((error) => ({ ...error, name: e.response.data.details }));
        }
      }
    }
  };

  return (
    <div style={styles}>
      <Button onClick={handleSubmit}>Save</Button>
      <Typography variant="h1" component="h1">
        Save Plan As
      </Typography>
      <TextInput
        id="name"
        required
        label="Plan name"
        value={formData.name || ''}
        onChange={handleFormChange}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextInput
        id="description"
        label="Description"
        multiline
        minRows={6}
        value={formData.description || ''}
        onChange={handleFormChange}
        error={!!errors.description}
        helperText={errors.description}
      />
      <PageSpinner isOpen={duplicatePlan.isLoading} />
    </div>
  );
};

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
  width: '100%',
};
