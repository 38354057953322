import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@components';
import { Save } from '@icons/index';

import { StackViewPdf } from './StackViewPdf';
import { PlanDetailsResponse } from '../../../types';

export type DownloadPdfProps = {
  planDetails: PlanDetailsResponse;
};

export const DownloadPlanDetailsPdf = ({ planDetails }: DownloadPdfProps) => {
  if (!planDetails || !planDetails.name || !planDetails.buildings || !planDetails.allocated) {
    return (
      <Button type="secondary" endIcon={<Save />} disabled>
        PDF
      </Button>
    );
  }
  return (
    <PDFDownloadLink
      document={<StackViewPdf key={Date.now()} planDetails={planDetails} />}
      fileName={`${planDetails.name}.pdf`}
    >
      {({ loading }) => (
        <Button type="secondary" endIcon={<Save />} disabled={loading}>
          PDF
        </Button>
      )}
    </PDFDownloadLink>
  );
};
