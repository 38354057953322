import { Table, Column } from '@components';

import { ProjectFloor } from 'types';

export type SpaceDataProps = {
  data: ProjectFloor[];
};

export const SpaceData = ({ data }: SpaceDataProps) => {
  const safeEmptyRenderer = (value: any) => value || '--';

  const columns: Column<ProjectFloor> = {
    building_name: 'Building Name',
    floor_name: 'Floor',
    area_in_sqft: 'Area is SQFT',
    total_capacity_count: 'Total Seats per Floor',
    workstations: 'Workstations',
    private_offices: 'Offices',
    shared_offices: 'Collaborative Seats',
  };

  return (
    <Table
      data={data}
      columns={columns}
      config={{
        defaultColumnRenderer: safeEmptyRenderer,
        sortBy: {
          building_name: true,
        },
      }}
    />
  );
};
