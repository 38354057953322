import { Select, FilledIconButton } from '@components';

import { Adjacency, Team, Column } from './types';

import './RowStyles.css';

const descriptiveScoreMap = {
  1.0: 'Critical Preference',
  0.75: 'High Preference',
  0.5: 'Moderate Preference',
  0.25: 'Low Preference',
};

export type AdjacencyInputRowProps = {
  keyIndex: string; // ugly but accessing 'key' here returns 'undefined' by React rules
  adj: Adjacency;
  onChange: (value: string, column: Column) => void;
  onRemove: () => void;
  firstColumnTeams: Team[];
  secondColumnTeams: Team[];
  isEditable?: boolean;
};

export const AdjacencyInputRow = ({
  keyIndex,
  adj,
  onChange,
  onRemove,
  firstColumnTeams,
  secondColumnTeams,
  isEditable = true,
}: AdjacencyInputRowProps) => {
  const adjacencyOptions = Object.entries(descriptiveScoreMap).map(([value, label]) => ({
    label,
    value: Number(value),
  }));

  return (
    <div key={`adj-${keyIndex}`} className="adjacency-input-row">
      <div className="dropdown-selection">
        <Select
          value={adj.from_team_id}
          id={`main-team-select-${keyIndex}`}
          label="Team"
          onChange={(e: any) => onChange(e.target.value, 'first')}
          options={firstColumnTeams}
          disabled={!isEditable}
        />
      </div>

      <div className="dropdown-selection">
        <Select
          id={`second-team-select-${keyIndex}`}
          value={adj.to_team_id}
          label="Adjacent Team"
          onChange={(e: any) => onChange(e.target.value, 'second')}
          options={secondColumnTeams}
          disabled={!adj.from_team_id || !isEditable}
        />
      </div>

      <div className="dropdown-selection">
        <Select
          id={`score-team-select-${keyIndex}`}
          value={adj.adj_value || ''}
          label="Preference"
          onChange={(e: any) => onChange(e.target.value, 'value')}
          options={adjacencyOptions}
          disabled={!adj.to_team_id || !isEditable}
        />
      </div>

      {isEditable && (
        <div className="add-row-button">
          <FilledIconButton onClick={onRemove}>-</FilledIconButton>
        </div>
      )}
    </div>
  );
};
