import axios from 'axios';

const sanitizeVariable = (text) => (text ? text.replace(/(\r\n|\n|\r)/gm, '') : '');

const apiKey = process.env.REACT_APP_TGM_BACKEND_API_KEY;
const baseUrl = process.env.REACT_APP_TGM_BACKEND_BASE_API_URL;
const app_version = sanitizeVariable(process.env.REACT_APP_APPLICATION_VERSION);
const flatfileKey = sanitizeVariable(process.env.REACT_APP_FLATFILE_SECRET);
const flatfileID = sanitizeVariable(process.env.REACT_APP_FLATFILE_ACCESS_KEY_ID);

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey,
};

const DEBUG = process.env.NODE_ENV === 'development';

const getQuery = (url, params = {}) =>
  axios.get(url, {
    ...params,
    headers: params.headers || headers,
  });

const delQuery = (url) =>
  axios.delete(url, {
    headers: {
      ...headers,
    },
  });

const putQuery = (url, params = {}) =>
  axios({
    url,
    method: 'put',
    ...params,
    headers: params.headers || headers,
  });

const postQuery = (url, params = {}) =>
  axios({
    url,
    method: 'post',
    ...params,
    headers: params.headers || headers,
  });
const mockRequest = (isSuccess) =>
  new Promise((resolve, reject) => {
    if (isSuccess) {
      setTimeout(
        () =>
          resolve({
            status: 'Success',
            message: 'Successfully updated data!!',
          }),
        1500,
      );
    } else {
      setTimeout(() => reject(new Error('error')), 1500);
    }
  });

const parsedErrorMessage = (e) => {
  const error = {};
  if (e.response) {
    // The request was made and the server responded with a status code
    const { data } = e.response;
    if (data) {
      error.message = data.message;
    }
  } else if (e.request) {
    // The request was made but no response was received
    error.message = e.message;
  } else {
    // Something happened in setting up the request that triggered an Error
    error.message = e.message || 'Something went wrong.';
  }
  return error.message;
};

export {
  parsedErrorMessage,
  mockRequest,
  getQuery,
  putQuery,
  postQuery,
  delQuery,
  baseUrl,
  app_version,
  headers,
  flatfileID,
  flatfileKey,
  DEBUG,
};
