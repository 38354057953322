import { useState } from 'react';
import {
  Box,
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';

import { PRIMARY_COLORS, PRIMARY_SILVER_COLOR } from '@constants/colors';
import { WithPermission } from '@components/WithPermission';
import { useClientData } from '../../context/ClientData';
import { useAuth } from '../../context/AuthContext';
import { TitleSection } from './TitleSection';
import { Roles } from '../../types';
import './index.css';

export const TopPanel = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { client } = useClientData();
  const { logout } = useAuth();
  const userInitials = `${client!.firstName[0]}${client!.lastName[0]}`.toUpperCase();

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: PRIMARY_SILVER_COLOR[200],
        padding: '15px 44px 15px 48px',
        height: '192px',
      }}
      elevation={0}
    >
      <Toolbar disableGutters sx={{ padding: '0px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Box className="app-logo" />
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/v2/projects"
              sx={{
                mr: 2,
                mt: '2px',
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                textDecoration: 'none',
                color: PRIMARY_COLORS.PRIMARY_GREEN,
                lineHeight: '14px',
                fontSize: '14px',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              Occupancy
              <br />
              Planner
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 0, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Box sx={{ color: PRIMARY_COLORS.PRIMARY_GREEN, fontSize: '16px', fontWeight: 600 }}>
              {client!.firstName} {client!.lastName}
            </Box>
            <Box sx={{ color: PRIMARY_COLORS.PRIMARY_SILVER, fontSize: '13px', fontWeight: 400 }}>
              {client!.userRolesList[0]}
            </Box>
          </Box>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Avatar>{userInitials}</Avatar>
          </IconButton>
          <Menu
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <WithPermission permission={Roles.SUPER}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  window.location.replace('/Admin/Users');
                }}
              >
                Switch to v0.3
              </MenuItem>
            </WithPermission>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <TitleSection />
    </AppBar>
  );
};
