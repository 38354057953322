import { useClientData } from '../../context/ClientData';
import { Roles } from '../../types';

interface Props {
  permission: Roles;
  children: JSX.Element;
}

export const WithPermission = ({ permission, children }: Props) => {
  const { checkPermission } = useClientData();
  return checkPermission(permission) ? children : null;
};
