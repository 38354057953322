import { Box, Link, BottomNavigation } from '@mui/material';

import { PRIMARY_COLORS } from '@constants/colors';
import { app_version } from '@service/index';
import { CopyToClipboardIcon } from './CopyToClipboardIcon';

export const BottomPanel = () => (
  <BottomNavigation
    sx={{
      backgroundColor: PRIMARY_COLORS.PRIMARY_WHITE,
      width: '100%',
      bottom: 0,
      height: '48px',
      boxSizing: 'border-box',
      padding: '16px 40px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: PRIMARY_COLORS.PRIMARY_SILVER,
        fontSize: '13px',
        width: '100%',
        '& a': {
          color: PRIMARY_COLORS.PRIMARY_SILVER,
          textDecoration: 'none',
          marginRight: '5px',
          marginLeft: '5px',
        },
      }}
    >
      <Box sx={{ fontFamily: 'Roboto', display: 'flex', alignItems: 'center' }}>
        Copyright &copy; {new Date().getFullYear()} CBRE Inc.
        <Box sx={{ marginLeft: '6px', marginRight: '6px' }}>|</Box>
        Version {app_version} <CopyToClipboardIcon textToCopy={app_version} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          sx={{ fontFamily: 'Roboto', fontSize: '13px' }}
          target="_blank"
          href="https://www.cbre.com/about/privacy-policy"
        >
          Privacy Policy
        </Link>
        <Box sx={{ marginLeft: '6px', marginRight: '6px' }}>|</Box>
        <Link
          sx={{ fontFamily: 'Roboto', fontSize: '13px' }}
          target="_blank"
          href="https://www.cbre.com/about/disclaimer-terms-of-use"
        >
          Terms & Conditions
        </Link>
        <Box sx={{ marginLeft: '6px', marginRight: '6px' }}>|</Box>
        <Link
          sx={{ fontFamily: 'Roboto', fontSize: '13px' }}
          href="mailto:DNT-US-TGMProductFeedback@cbre.com"
        >
          Contact Us
        </Link>
      </Box>
    </Box>
  </BottomNavigation>
);
