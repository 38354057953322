import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useHeaderData } from '@hooks/useHeaderData';
import { useGeneratePlan, usePlans } from '@hooks/usePlans';
import { useProject } from '@hooks/useProject';
import { useUploadFile } from '@hooks/useUploadFiles';

import { Button, PageSpinner, Link } from '@components';
import { Save } from '@icons/index';
import { SiteUpload } from './SiteUpload_REUSED';
import { GeneratePlanDialog, GenerateInProgressDialog } from './dialogs';
import { BreadcrumbOptions, STRATEGIES_LIST } from '../../../types';
import templateFilesZip from '../../../TEMP_FILES/Template Files.zip';

enum Dialog {
  generate = 'generate',
  generateWithAdjacencies = 'generateWithAdjacencies',
  generateInProgress = 'generateInProgress',
}

const prepareDefaultStrategies = (userAddedAdjacencies = false) => {
  const strategiesState: Record<number, boolean> = {};

  if (userAddedAdjacencies) {
    STRATEGIES_LIST.forEach(({ value }) => {
      strategiesState[value] = true;
    });
  } else {
    strategiesState[STRATEGIES_LIST[0].value] = true;
  }

  return strategiesState;
};

export const DataUploadHeaderAction = () => (
  <div style={{ display: 'flex', gap: '16px' }}>
    <Link href={templateFilesZip} download="Template Files.zip">
      <Button type="secondary" endIcon={<Save />}>
        Download Templates
      </Button>
    </Link>
  </div>
);

export const DataUpload = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [dialogs, setDialogs] = useState({
    [Dialog.generate]: false,
    [Dialog.generateWithAdjacencies]: false,
    [Dialog.generateInProgress]: false,
  });
  // all strategies should be selected by default
  const [strategies, setStrategies] = useState<Record<number, boolean>>({});
  const generatePlan = useGeneratePlan();
  const { data, isLoading: isProjectLoading } = useProject(projectId!);
  const { planList, isLoading: isLoadingPlansList } = usePlans(projectId!);
  const { uploadFileToS3 } = useUploadFile(Number(projectId));

  useEffect(() => {
    if (generatePlan.isSuccess) {
      navigate(`/v2/projects/${projectId}/plans`);
    }
  }, [generatePlan.isSuccess]);

  const breadcrumbList: BreadcrumbOptions[] = [
    { label: 'Occupancy Projects', link: '/v2/projects' },
    {
      label: data?.customer_name || '',
      link: `/v2/projects${data ? `?customer_name=${data?.customer_name}` : ''}`,
    },
  ];

  useHeaderData({
    title: data?.name || '',
    breadcrumb: breadcrumbList,
    ActionBar: DataUploadHeaderAction,
  });

  const changeStrategy = (key: number, value: boolean) => {
    setStrategies({ ...strategies, [key]: value });
  };

  const openGenerateDialog = (userAddedAdjacencies: boolean) => {
    setStrategies(prepareDefaultStrategies(userAddedAdjacencies));
    if (userAddedAdjacencies) {
      changeDialogVisibility(Dialog.generateWithAdjacencies, true);
    } else {
      changeDialogVisibility(Dialog.generate, true);
    }
  };

  const changeDialogVisibility = (key: Dialog, value: boolean) => {
    setDialogs({ ...dialogs, [key]: value });
  };

  const renderDialog = () => {
    if (dialogs.generate) {
      return (
        <GeneratePlanDialog
          isOpen={dialogs.generate}
          onClose={() => changeDialogVisibility(Dialog.generate, false)}
          onSubmit={openGeneratePlanProgress}
        />
      );
    }
    if (dialogs.generateWithAdjacencies) {
      return (
        <GeneratePlanDialog
          isOpen={dialogs.generateWithAdjacencies}
          onClose={() => changeDialogVisibility(Dialog.generateWithAdjacencies, false)}
          strategies={strategies}
          onChangeStrategy={changeStrategy}
          onSubmit={openGeneratePlanProgress}
          isDisabledSubmit={!mapStrategiesForSubmit().length}
        />
      );
    }

    if (dialogs.generateInProgress) {
      return (
        <GenerateInProgressDialog
          isOpen={dialogs.generateInProgress}
          onClose={() => setDialogs((state) => ({ ...state, [Dialog.generateInProgress]: false }))}
          isLoading={generatePlan.isLoading}
          isSuccess={generatePlan.isSuccess}
          isError={generatePlan.isError}
          onRedirect={() => {
            navigate(`/v2/projects/${projectId}/plans`);
          }}
        />
      );
    }
  };

  const mapStrategiesForSubmit = (): number[] =>
    Object.entries(strategies)
      .filter(([key, value]) => value === true)
      .map(([key]) => Number(key));

  const openGeneratePlanProgress = async () => {
    setDialogs({
      [Dialog.generate]: false,
      [Dialog.generateWithAdjacencies]: false,
      [Dialog.generateInProgress]: true,
    });

    try {
      await generatePlan.mutateAsync({
        project_id: Number(projectId),
        strategies: mapStrategiesForSubmit(),
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (planList?.length) {
    navigate('/v2/projects');
  }

  if (isProjectLoading || isLoadingPlansList) {
    return <PageSpinner isOpen />;
  }

  return (
    <>
      <SiteUpload
        customerId={data?.customer_id}
        projectId={data?.id}
        projectName={data?.name}
        onGeneratePlans={openGenerateDialog}
        onUploadFile={(filename, file, schemaName) =>
          uploadFileToS3({
            customer_id: data?.customer_id!,
            relative_to: schemaName,
            filename,
            file,
          })
        }
      />
      {renderDialog()}
    </>
  );
};
