import axios from 'axios';
import {
  baseUrl,
  delQuery,
  flatfileID,
  flatfileKey,
  getQuery,
  putQuery,
  headers,
  postQuery,
} from './index';

const getAppVersion = () => {
  const url = `${baseUrl}common/api/appversion`;
  return getQuery(url);
};

const getUserByEmail = () => {
  const url = `${baseUrl}user-mgmt/api/user/details`;

  return axios
    .get(url, {
      headers,
    })
    .then((res) => res)
    .catch((error) => error.response);
};

const getOpportunityDetail = (config) => {
  const { customerId, opportunityId } = config;
  const url = `${baseUrl}portfolio-strategy/api/opportunity/${opportunityId}?&customerId=${customerId}`;
  const method = 'get';
  return axios({
    method,
    url,
    headers,
  });
};

const getStateParams = () => {
  const url = `${baseUrl}common/api/options`;
  const method = 'get';
  return axios({
    method,
    url,
    headers,
  }).then((res) => res);
};

const getFieldsInfo = () => {
  const url = `${baseUrl}schema/api/schema`;
  const method = 'get';

  return axios({
    method,
    url,
    headers,
  }).then((rawData) => {
    const { data } = rawData;

    if (data.length > 0) {
      // loop through fields
      // return data.map((field) => {
      //   const {
      //     label,
      //     key,
      //     validations,
      //   } = field;

      //   return {
      //     label,
      //     key,
      //     validators: validations,
      //   };
      // });
      return data;
    }
    return data;
  });
};
const postOriginalFile = (data, config, filename) => {
  console.log(config);
  const { key, policy, signature, AWSAccessKeyId } = config.fields;
  const { url } = config;
  const body = new FormData();
  body.append('key', key);
  body.append('AWSAccessKeyId', AWSAccessKeyId);
  body.append('x-amz-security-token', config.fields['x-amz-security-token']);
  body.append('policy', policy);
  body.append('signature', signature);
  body.append('file', data, filename);
  return axios.post(url, body);
  // return axios({
  //   url,
  //   method: 'post',
  //   headers: {
  //     ...headers,
  //     'Content-Type': 'multipart/form-data',
  //   },
  //   data: body,
  // });
};
const postUploadConfig = (data) => {
  const url = `${baseUrl}common/api/file-upload-url?`;
  return axios({
    method: 'post',
    url,
    headers,
    data,
  }).then((response) => response.data);
};

const getFileMetadata = (batchId) => {
  const url = `https://api.us.flatfile.io/rest/batch/${batchId}`;
  const flatfileHeaders = {
    // eslint-disable-next-line max-len
    'x-api-key': `${flatfileID}+${flatfileKey}`,
  };

  return axios({
    url,
    method: 'get',
    headers: flatfileHeaders,
  });
};
const getRecordsByBatchId = (batchId) => {
  const url = `https://api.us.flatfile.io/rest/batch/${batchId}/rows`;

  const flatfileHeaders = {
    // eslint-disable-next-line max-len
    'x-api-key': `${flatfileID}+${flatfileKey}`,
  };

  return axios({
    url,
    method: 'get',
    headers: flatfileHeaders,
  });
};

const getOriginalFile = (batchId, filename, type = 'original') => {
  const url = `https://api.us.flatfile.io/batch/${batchId}/export.csv?type=${type}`;
  const method = 'get';
  const flatfileHeaders = {
    // eslint-disable-next-line max-len
    'x-api-key': `${flatfileID}+${flatfileKey}`,
    'Content-Disposition': `attachment; filename=${filename}`,
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };
  const responseType = 'blob';

  return axios({
    url,
    method,
    headers: flatfileHeaders,
    responseType,
  }).then((res) => {
    const blob = new Blob([res.data]);
    // postOriginalFile(blob);
    // const url2 = window.URL.createObjectURL(blob);
    // const link = document.getElementById('myLink');
    // link.href = url2;
    // link.setAttribute('download', `${Date.now()}.xlsx`);
    // link.click();
    return blob;
  });
};

const onFileUpload = async (data, batchId) => {
  // get config for s3 url
  const config = await postUploadConfig(data);
  // get blob content from flatfile
  const blob = await getOriginalFile(batchId, data.filename);
  return postOriginalFile(blob, config, data.filename);
};

const postMappingAcion = (data, schemaId, customerId) => {
  const url = `${baseUrl}common/api/integration-reasoning?schemaId=${schemaId}&customerId=${customerId}`;
  const method = 'post';

  return axios({
    url,
    method,
    headers,
    data,
  }).then((res) => res);
};

const addNewRecords = (data, schemaId, customerId) => {
  const url = `${baseUrl}common/api/data?schemaId=${schemaId}&customerId=${customerId}`;
  const method = 'post';

  return axios({
    url,
    method,
    headers,
    data,
  }).then((res) => res);
};

const updateExistingRecords = (data, schemaId, customerId) => {
  const url = `${baseUrl}common/api/data?schemaId=${schemaId}&customerId=${customerId}`;
  const method = 'put';

  return axios({
    url,
    method,
    headers,
    data,
  }).then((res) => res);
};

const saveData = (data) => {
  const url = `${baseUrl}occupancy-mgmt/api/data`;
  const method = 'post';

  return axios({
    url,
    method,
    headers,
    data,
  }).then((res) => console.log(res));
};

const deleteCustomerData = (customerId) =>
  delQuery(`${baseUrl}portfolio-strategy/api/cleanup/${customerId}`).then((res) => res);

const getFlatfileToken = (config) => postQuery(`${baseUrl}common/api/flatfile-jwt`, config);

const getAllUsers = () => getQuery(`${baseUrl}user-mgmt/api/user`);
const getAllCustomer = () => getQuery(`${baseUrl}user-mgmt/api/customer`);
const getAllRoles = () => getQuery(`${baseUrl}user-mgmt/api/role`);
const putUser = (id, data) => putQuery(`${baseUrl}user-mgmt/api/user/${id}`, { data });
const putCustomer = (id, data) => putQuery(`${baseUrl}user-mgmt/api/customer/${id}`, { data });
const deleteUser = (id) => delQuery(`${baseUrl}user-mgmt/api/user/${id}`);
const deleteCustomer = (id) => delQuery(`${baseUrl}user-mgmt/api/customer/${id}`);
const postUser = (data) => postQuery(`${baseUrl}user-mgmt/api/user`, { data });
const postCustomer = (data) => postQuery(`${baseUrl}user-mgmt/api/customer`, { data });
const postRevokeToken = () => postQuery(`${baseUrl}user-mgmt/api/user/revoke-token`);
export {
  putUser,
  postUser,
  postCustomer,
  deleteCustomer,
  postRevokeToken,
  deleteUser,
  saveData,
  getAllUsers,
  putCustomer,
  getAllRoles,
  getAllCustomer,
  onFileUpload,
  getFieldsInfo,
  getAppVersion,
  addNewRecords,
  getStateParams,
  getUserByEmail,
  getOriginalFile,
  postOriginalFile,
  getFileMetadata,
  getFlatfileToken,
  getRecordsByBatchId,
  postMappingAcion,
  getOpportunityDetail,
  updateExistingRecords,
  deleteCustomerData,
};
