import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAlert } from '../context/AlertContext';
import { useApi } from './useApi';
import { CreateProjectForm, Project, UniversalProject } from '../types';

const URL = '/occupancy-planning/api/project';

export const useProjects = (archived: boolean) => {
  const api = useApi();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const fetchProjects = async () => {
    const response = await api.get<Project[]>(`${URL}${archived ? '?is_archived=true' : ''}`);
    return response?.data;
  };

  const fetchQuery = useQuery<Project[]>(['projects', String(archived)], fetchProjects, {
    keepPreviousData: true,
    onError: (e: any) => {
      alert('error', e, e?.response?.data?.status);
    },
  });

  const editProjectRequest = async (data: Partial<Project>) => {
    const { id, ...payload } = data;
    return api.patch(`${URL}/${id}`, payload);
  };

  const editProject = useMutation(editProjectRequest, {
    onSuccess: (res, payload) => {
      const isEdit = !!payload.name;
      queryClient.setQueryData(['projects', String(archived)], (oldData: any) => {
        if (oldData) {
          const data = oldData.filter((item: Project) => item.id !== payload.id);
          if (isEdit) {
            return [ res.data, ...data ];
          }
          return data;
        }
        fetchQuery.refetch();
        return oldData;
      })

      alert(
        'success',
        isEdit
          ? `Project ${payload.name} successfully edited`
          : `Project successfully ${payload.is_archived ? 'archived' : 'restored'}`,
      );
    },
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        return;
      }
      alert('error', e, e?.response?.data?.status);
    },
  });

  return {
    fetchQuery,
    editProject,
  };
};

export const useProject = <Condition extends boolean>(id: string, related?: Condition) => {
  const api = useApi();
  const alert = useAlert();

  const fetchProject = async () => {
    const response = await api.get<UniversalProject<Condition>>(
      `${URL}/${id}${related ? '?related=true' : ''}`,
    );
    return response?.data;
  };

  return useQuery<UniversalProject<Condition>>(['project', id], fetchProject, {
    enabled: !!id,
    onError: (e: any) => alert('error', e, e?.response?.data?.status),
  });
};

export const useCreateProject = () => {
  const api = useApi();
  const alert = useAlert();

  const createProjectRequest = async ({ name, customerId, description }: CreateProjectForm) =>
    api.post(URL, {
      name,
      customer_id: customerId,
      description: description || '',
    });

  return useMutation(createProjectRequest, {
    onSuccess: (_, payload) => alert('success', `Project ${payload.name} successfully created`),
    onError: (e: any) => {
      if (e?.response?.status === 400) {
        return;
      }
      alert('error', e, e?.response?.data?.status);
    },
  });
};

export const useUpdateTeamColor = (id: string) => {
  const api = useApi();
  const alert = useAlert();

  const updateTeamColor = async ({ color, teamId }: { color: string, teamId: string }) => api.patch(`${URL}/${id}?team=${teamId}`, { color });

  return useMutation(updateTeamColor, {
    onError: (e: any) => alert('error', e, e?.response?.data?.status)
  });
};
