import { useState } from 'react';
import { ColorResult } from '@uiw/color-convert';
import { Box, InputLabel, SxProps } from '@mui/material';
import Sketch from '@uiw/react-color-sketch';
import { Info } from '@icons/Info';
import { Menu } from '../Menu';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.css';

interface Props {
  id: string;
  label?: string;
  baseColorList: string[];
  value: string;
  onChange: (v: string) => void;
  type?: keyof ColorResult;
  sx?: SxProps;
  labelTooltip?: string;
}

export const ColorPicker = ({
  id,
  value,
  baseColorList,
  label,
  onChange,
  labelTooltip,
  sx = {},
  type = 'hex',
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleChange = (value: ColorResult) => onChange(value[type] as string);
  return (
    <>
      <Box sx={{ width: '100%', ...sx }} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <InputLabel htmlFor={id}>
          {label}
          {labelTooltip && (
            <Tooltip tooltip={labelTooltip}>
              <span style={{ margin: '3px 0 0 3px' }}>
                <Info size={12} />
              </span>
            </Tooltip>
          )}
        </InputLabel>
        <div className={styles.color}>
          <span style={{ backgroundColor: value }} />
        </div>
      </Box>
      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          PopoverClasses={{ paper: styles.menuWrapper }}
        >
          <Sketch color={value} disableAlpha presetColors={baseColorList} onChange={handleChange} />
        </Menu>
      )}
    </>
  );
};
