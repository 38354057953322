/* eslint-disable no-restricted-syntax */
import { ChangeEvent, ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseMutateAsyncFunction } from 'react-query';
import { AxiosResponse } from 'axios';

import { Typography } from '@mui/material';
import { PageSpinner, Search, Tabs } from '@components';
import { usePlanDetails } from '@hooks/usePlanDetails';
import { usePlans } from '@hooks/usePlans';
import { useHeaderData } from '@hooks/useHeaderData';
import { useDebounce } from '@hooks/useDebounce';
import { useProject } from '@hooks/useProject';
import { useQueue } from '@hooks/useQueue';
import { PRIMARY_COLORS, SECONDARY_COLORS } from '@constants/colors';
import { Order } from '@utils/quickSort';

import { HeaderData } from '../../context/headerData';
import { useAlert } from '../../context/AlertContext';
import { StackView } from './StackView';
import { AdjacencyScores } from './AdjacencyScores';
import { ActionBar } from './ActionBar';
import { dataNormalize } from './helpers';
import { Status } from '../project/Status';
import { BreadcrumbOptions, Plan, PlanDetailsResponse, Project, StackViewState } from '../../types';

import { planDetails as styles } from './styles';

enum TabList {
  VIEW = 'Stack View',
  ADJACENCY = 'Adjacency',
}

const PlanDetailsWithHeader = () => {
  const { projectId, planId } = useParams();
  const { data: projectData, isLoading: isProjectLoading } = useProject(projectId!, true);
  const {
    planDetails: { data: planDetails, isLoading, isFetching },
    updatePlan,
  } = usePlanDetails(planId!);
  const { planList, isFetching: isFetchingPlanList } = usePlans(projectId!);
  const [data, setData] = useState<StackViewState>();

  const [order, setOrder] = useState<Order>('asc');
  const handleOrder = () => setOrder((state) => (state === 'asc' ? 'desc' : 'asc'));

  useHeaderData(
    handlePrepareHeaderData(order, projectId, projectData, planDetails, data, planList),
  );

  useEffect(() => {
    if (!isLoading && planDetails && projectData) {
      const { details, name, ...stackViewData } = planDetails;
      for (const item of projectData.teams!) {
        if (item.color) {
          stackViewData.teamData[item.id].color = item.color;
        }
      }
      setData(stackViewData);
    }
  }, [isLoading, planDetails, projectData]);

  if (isLoading || isFetching || isProjectLoading || isFetchingPlanList) {
    return <PageSpinner isOpen />;
  }

  return (
    <PlanDetails
      planDetails={{ ...planDetails!, ...data }}
      onUpdateData={updatePlan}
      onSetLocalData={setData}
      order={order}
      onChangeOrder={handleOrder}
    />
  );
};

const PlanDetails = ({
  planDetails,
  onUpdateData,
  onSetLocalData,
  order,
  onChangeOrder,
}: {
  planDetails: PlanDetailsResponse;
  order: Order;
  onChangeOrder: () => void;
  onUpdateData: UseMutateAsyncFunction<AxiosResponse<any, any>, unknown, any, unknown>;
  onSetLocalData: React.Dispatch<React.SetStateAction<StackViewState | undefined>>;
}) => {
  const alert = useAlert();
  const { details, name, ...stackViewData } = planDetails;
  const { enqueue, details: responseDetails } = useQueue<StackViewState>(
    onUpdateData,
    dataNormalize,
  );
  const { enqueue: enqueueWithAlert } = useQueue<StackViewState>(
    onUpdateData,
    dataNormalize,
    () => {
      alert('success', 'Adjacencies successfully saved');
    },
  );
  const [activeTab, setTab] = useState<number>(0);
  const [activeFilter, setFilter] = useState<string>('');
  const debouncedFilter = useDebounce<string>(activeFilter, 700);
  const allocated = responseDetails?.results
    ? responseDetails.results.seats.allocated
    : details?.allocated;
  const total = responseDetails?.results ? responseDetails.results.seats.total : details?.total;

  const handleDataEdit = (updatedData: StackViewState | null) => {
    if (updatedData) {
      onSetLocalData(updatedData);
      enqueue(updatedData);
    }
  };

  const handleAdjacenciesEdit = (updatedData: StackViewState | null) => {
    if (updatedData) {
      onSetLocalData(updatedData);
      enqueueWithAlert(updatedData);
    }
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);

  const TabMap: Record<TabList, ReactElement> = {
    [TabList.VIEW]: (
      <StackView
        data={stackViewData || {}}
        onDataEdit={handleDataEdit}
        filter={debouncedFilter}
        order={order}
        onChangeOrder={onChangeOrder}
      />
    ),
    [TabList.ADJACENCY]: (
      <AdjacencyScores
        data={stackViewData}
        onDataEdit={handleAdjacenciesEdit}
        isEdiatable={!stackViewData.is_plan_of_record}
      />
    ),
  };

  return (
    <>
      <div style={styles.wrapper}>
        <Tabs active={activeTab} tabs={Object.values(TabList)} onChange={setTab} />
        <div style={styles.tabBlock}>
          <Typography sx={styles.w100} variant="body2">{`${allocated || 0} / ${
            total || 0
          } seats are allocated`}</Typography>
          {activeTab === 0 && (
            <Search
              placeholder="Search Team"
              name="search team"
              onChange={handleFilter}
              value={activeFilter}
            />
          )}
        </div>
      </div>
      <div style={styles.content}>{Object.values(TabMap)[activeTab]}</div>
    </>
  );
};

export { PlanDetailsWithHeader as PlanDetails };

const handlePrepareHeaderData = (
  order: Order,
  projectId?: string,
  projectData?: Project,
  planDetails?: PlanDetailsResponse,
  data?: StackViewState,
  planList?: Plan[],
) => {
  if (!(projectData && planDetails && projectId && planList && data)) return undefined;

  const { customer_name, name: projectName } = projectData;
  const { details, name, is_plan_of_record } = planDetails;

  const breadcrumbList: BreadcrumbOptions[] = [
    { label: 'Occupancy Projects', link: '/v2/projects' },
    {
      label: customer_name || '',
      link: `/v2/projects?customer_name=${customer_name!}`,
    },
    { label: projectName, link: `/v2/projects/${projectId}/plans` },
  ];

  const TitleMarker = () =>
    is_plan_of_record ? (
      <Status
        color={PRIMARY_COLORS.PRIMARY_WHITE}
        backgroundColor={SECONDARY_COLORS.VIOLET}
        sx={styles.planOfRecordLabel}
      >
        Plan of Record
      </Status>
    ) : null;

  return {
    title: name,
    breadcrumb: breadcrumbList,
    ActionBar,
    props: {
      order,
      TitleMarker,
      planDetails: {
        details,
        name,
        ...data,
      },
      projectId,
      planList,
    },
  } as HeaderData;
};
