export const Settings = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5.18412V3.00012H4V5.18412C2.838 5.59712 2 6.69512 2 8.00012C2 9.30512 2.838 10.4031 4 10.8161V21.0001H6V10.8161C7.162 10.4031 8 9.30512 8 8.00012C8 6.69512 7.162 5.59712 6 5.18412ZM13 13.1841V3.00012H11V13.1841C9.838 13.5971 9 14.6951 9 16.0001C9 17.3051 9.838 18.4031 11 18.8161V21.0001H13V18.8161C14.162 18.4031 15 17.3051 15 16.0001C15 14.6951 14.162 13.5971 13 13.1841ZM20 3.00012V5.18412C21.162 5.59712 22 6.69512 22 8.00012C22 9.30512 21.162 10.4031 20 10.8161V21.0001H18V10.8161C16.838 10.4031 16 9.30512 16 8.00012C16 6.69512 16.838 5.59712 18 5.18412V3.00012H20ZM18 8.00012C18 8.55212 18.447 9.00012 19 9.00012C19.553 9.00012 20 8.55212 20 8.00012C20 7.44712 19.553 7.00012 19 7.00012C18.447 7.00012 18 7.44712 18 8.00012ZM11 16.0001C11 16.5521 11.447 17.0001 12 17.0001C12.553 17.0001 13 16.5521 13 16.0001C13 15.4471 12.553 15.0001 12 15.0001C11.447 15.0001 11 15.4471 11 16.0001ZM4 8.00012C4 8.55212 4.447 9.00012 5 9.00012C5.553 9.00012 6 8.55212 6 8.00012C6 7.44712 5.553 7.00012 5 7.00012C4.447 7.00012 4 7.44712 4 8.00012Z"
      fill="#435254"
    />
  </svg>
);
