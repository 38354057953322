import { CSSProperties } from 'react';

export const styles: Record<string, CSSProperties> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px',
    width: '100%',
  },
  contentGrid: {
    display: 'grid',
    gap: '25px',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateAreas: '"a a a a" "b b c c" "d e f f"',
  },
};
