import { Suspense, lazy } from 'react';
import { Outlet, Routes, Route, Navigate } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import { ProtectedRoute, PageSpinner } from '@components';

import {
  Admin_Home,
  Admin_User_Setup,
  Admin_Client_Setup,
  Admin_Client_User_Mapping_Setup,
  PS_Strategy_Designer,
  PS_Strategy_Summary,
  PS_Headcount_Forecast,
  PS_Labor_Forecast,
  PS_Opportunity_Detail,
  PS_Home,
  DM_Home,
  OP_Home,
  DM_DataCleanup,
  DM_OP_Upload,
  DM_PS_Upload,
  OP_PlanGenerator,
  OP_PlanDetails,
  OP_PlanSummary,
  UploadSuccess,
  PROJECTS,
  PLAN_LIST,
  PLAN,
  COMPARE_PLANS,
  DATA_UPLOAD,
} from './routePaths';

import PageNotFound from './containers/PageNotFound';
import { PageSkeleton, ProjectList, OccupancyPlanList, DataUpload, PlanDetails, ComparePlans } from './modules';
import { Roles } from './types';

const Root = lazy(() => import('./Root'));
const SiteUpload = lazy(() => import('./containers/SeatAllocation/SiteUpload/SiteUpload'));
const PSListener = lazy(() => import('./components/PSListener/PSListener'));
const DMListener = lazy(() => import('./components/DMListener/DMListener'));
const PlanGenerator = lazy(() => import('./containers/SeatAllocation/PlanGenerator/PlanGenerator'));
const PlanDetailsv03 = lazy(() => import('./containers/SeatAllocation/PlanDetails/PlanDetails'));
const PlanSummary = lazy(() => import('./containers/SeatAllocation/PlanSummary/PlanSummary'));
const UserManagement = lazy(() => import('./containers/Admin/UserManagement/UserManagement'));
const ClientManagement = lazy(() => import('./containers/Admin/ClientManagement/ClientManagement'));
const UserClientManagement = lazy(
  () => import('./containers/Admin/UserClientManagement/UserClientManagement'),
);
const FutureState = lazy(() => import('./containers/PortfolioStrategy/FutureState/FutureState'));
const CompletionPage = lazy(() => import('./containers/CompletionPage/CompletionPage'));
const HeadcountForecast = lazy(
  () => import('./containers/PortfolioStrategy/HeadcountForecast/HeadcountForecast'),
);
const LaborForecast = lazy(
  () => import('./containers/PortfolioStrategy/LaborForecast/LaborForecast'),
);
const PortfolioState = lazy(
  () => import('./containers/PortfolioStrategy/PortfolioState/PortfolioState'),
);
const StrategySummary = lazy(
  () => import('./containers/PortfolioStrategy/StrategySummary/StrategySummary'),
);
const SiteHome = lazy(() => import('./containers/SeatAllocation/SiteHome/SiteHome'));
const DataManagementUpload = lazy(
  () => import('./containers/DataManagement/DataManagementUpload/DataManagementUpload'),
);
const OpportunityDetail = lazy(
  () => import('./containers/PortfolioStrategy/OpportunityDetail/OpportunityDetail'),
);
const DataManagementCleanup = lazy(
  () => import('./containers/DataManagement/DataManagementCleanup/DataManagementCleanup'),
);

export const AppRoutes = () => (
  <Suspense fallback={<PageSpinner isOpen />}>
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute permission={Roles.SUPER}>
            <Root />
          </ProtectedRoute>
        }
      >
        <Route
          path={DM_Home}
          element={
            <>
              <Outlet />
              <DMListener />
            </>
          }
        >
          <Route path={DM_Home} element={<DataManagementUpload />} />
          <Route path={DM_OP_Upload} element={<SiteUpload />} />
          <Route path={DM_PS_Upload} element={<DataManagementUpload />} />
          <Route path={DM_DataCleanup} element={<DataManagementCleanup />} />
        </Route>
        <Route
          path={PS_Home}
          element={
            <>
              <Outlet />
              <PSListener />
            </>
          }
        >
          <Route path={PS_Home} element={<PortfolioState />} />
          <Route path={PS_Strategy_Designer} element={<FutureState />} />
          <Route path={PS_Headcount_Forecast} element={<HeadcountForecast />} />
          <Route path={PS_Labor_Forecast} element={<LaborForecast />} />
          <Route path={PS_Strategy_Summary} element={<StrategySummary />} />
          <Route path={PS_Opportunity_Detail} element={<OpportunityDetail />} />
        </Route>
        <Route path={Admin_Home} element={<Outlet />}>
          <Route path={Admin_Home} element={<UserManagement />} />
          <Route path={Admin_User_Setup} element={<UserManagement />} />
          <Route path={Admin_Client_Setup} element={<ClientManagement />} />
          <Route path={Admin_Client_User_Mapping_Setup} element={<UserClientManagement />} />
        </Route>
        <Route path={OP_Home} element={<Outlet />}>
          <Route path={OP_Home} element={<SiteHome />} />
          <Route path={OP_PlanGenerator} element={<PlanGenerator />} />
          <Route path={OP_PlanDetails} element={<PlanDetailsv03 />} />
          <Route path={OP_PlanSummary} element={<PlanSummary />} />
        </Route>
        <Route path={UploadSuccess} element={<CompletionPage />} />
      </Route>

      <Route
        path="/v2"
        element={
          <ProtectedRoute>
            <PageSkeleton />
          </ProtectedRoute>
        }
      >
        <Route path={PROJECTS} element={<ProjectList />} />
        <Route path={PLAN_LIST} element={<OccupancyPlanList />} />
        <Route path={PLAN} element={<PlanDetails />} />
        <Route path={COMPARE_PLANS} element={<ComparePlans />} />
        <Route path={DATA_UPLOAD} element={<DataUpload />} />
      </Route>

      <Route path="/Login" element={<LoginCallback loadingElement={<PageSpinner isOpen />} />} />
      <Route path="/Logout" element={<PageSpinner isOpen />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  </Suspense>
);
