import { Table, Column } from '@components';

import { ProjectTeam } from 'types';

export type TeamsDataProps = {
  data: ProjectTeam[];
};

export const TeamsData = ({ data }: TeamsDataProps) => {
  const safeEmptyRenderer = (value: any) => value || '--';

  const columns: Column<ProjectTeam> = {
    name: 'Team Name',
    total_seat_count: 'Total Team Count',
    current_headcount: 'Current HC',
    future_headcount: 'Future HC',
    shared_ratio: 'Shared Ratio',
    workstations: 'Workstations',
    private_offices: 'Offices',
    shared_offices: 'Collaborative Seats',
  };

  return (
    <Table
      data={data}
      columns={columns}
      config={{
        columnRenderer: {
          shared_ratio: handleSharingRatio,
        },
        defaultColumnRenderer: safeEmptyRenderer,
        sortBy: {
          name: true,
        },
        defaultSortByColumn: 'name',
      }}
    />
  );
};

const handleSharingRatio = (value: string) => (!value || value === 'N/A') ? '1.0' : value;