import { useEffect, useState } from 'react';

import { FilledIconButton } from '@components';
import { permutations } from '@utils/math';
import { Plus } from '@icons/index';

import { AdjacencyInputRow } from './AdjacencyInputRowRefactored';
import { Adjacency, Column, Team } from './types';

import './FormStyles.css';

const EMPTY_ADJ: Adjacency = {
  from_team_id: '',
  to_team_id: '',
  adj_value: 0,
};

const initializeAdjacencies = (adjacencies?: Adjacency[]) => {
  if (adjacencies && adjacencies.length) {
    return adjacencies;
  }

  return [{ ...EMPTY_ADJ }];
};

export type AdjacenciesFormProps = {
  saveAdjacencies: (adjacencies: Adjacency[]) => void;
  teams: Team[];
  adjacencies?: Adjacency[];
  isEditable?: boolean;
};

export const AdjacenciesForm = ({
  saveAdjacencies,
  teams,
  adjacencies,
  isEditable = true,
}: AdjacenciesFormProps) => {
  const [localAdjacencies, setLocalAdjacencies] = useState<Adjacency[]>(
    initializeAdjacencies(adjacencies),
  );

  useEffect(() => {
    setLocalAdjacencies(initializeAdjacencies(adjacencies));
  }, [adjacencies]);

  const addAdjacency = () => setLocalAdjacencies([...localAdjacencies, { ...EMPTY_ADJ }]);

  const getMaxCombinations = () => {
    if (teams && teams.length > 0) {
      return permutations(teams.length, 2);
    }

    return 0;
  };

  const removeAdjacency = (indexToDelete: number) => {
    // check if we remove empty line
    const updatedAdjacencies = localAdjacencies.filter((_adj, index) => index !== indexToDelete);

    // if it already had value
    if (localAdjacencies[indexToDelete].adj_value) {
      handleSave(updatedAdjacencies);
    }

    // check if it was the only adjacency
    if (updatedAdjacencies.length === 0) {
      setLocalAdjacencies([{ ...EMPTY_ADJ }]);
    } else {
      setLocalAdjacencies(updatedAdjacencies);
    }
  };

  const onChange = (row: number, value: string | number, column: Column) => {
    const updatedAdjacencies = [...localAdjacencies];

    if (column === 'first') {
      updatedAdjacencies[row].from_team_id = value as string;
      // If an adjacent team is already selected, clear it and the score.
      if (updatedAdjacencies[row].from_team_id) {
        updatedAdjacencies[row].to_team_id = '';
        updatedAdjacencies[row].adj_value = 0;
      }
    } else if (column === 'second') {
      updatedAdjacencies[row].to_team_id = value as string;
    } else if (column === 'value') {
      updatedAdjacencies[row].adj_value = value as number;
    }

    if (updatedAdjacencies[row].adj_value) {
      handleSave(updatedAdjacencies);
    } else {
      setLocalAdjacencies(updatedAdjacencies);
    }
  };

  const filterTeamsForFirstColumn = (rowIndex: number) => {
    const maxNumberOfRepeats = teams.length - 1;

    const selectedTeamCounts: any = {};
    localAdjacencies
      .filter((adj, index) => index !== rowIndex)
      .forEach((adj) => {
        selectedTeamCounts[adj.from_team_id] = (selectedTeamCounts[adj.from_team_id] || 0) + 1;
      });

    return teams.filter((team) => (selectedTeamCounts[team.value] || 0) < maxNumberOfRepeats);
  };

  const filterTeamsForSecondColumn = (rowIndex: number) => {
    const adjacenciesSet = localAdjacencies
      .filter((adj, index) => index !== rowIndex)
      .map((adj) => `${adj.from_team_id}_${adj.to_team_id}`);

    return teams
      .filter((team) => team)
      .filter((team) => team.value !== localAdjacencies[rowIndex].from_team_id)
      .filter(
        (team) =>
          !adjacenciesSet.includes(`${localAdjacencies[rowIndex].from_team_id}_${team.value}`),
      );
  };

  const handleSave = (adjaststencies: Adjacency[]) => {
    saveAdjacencies(adjaststencies.filter((adj) => adj.adj_value));
  };

  return (
    <div>
      <h2>Adjacencies</h2>
      <div>
        {localAdjacencies.map((adj, index) => {
          const key = `adjacency-row=${index}`;
          return (
            <AdjacencyInputRow
              adj={adj}
              firstColumnTeams={filterTeamsForFirstColumn(index)}
              secondColumnTeams={filterTeamsForSecondColumn(index)}
              onChange={(value: string, column: Column) => onChange(index, value, column)}
              onRemove={() => removeAdjacency(index)}
              keyIndex={key}
              key={key}
              isEditable={isEditable}
            />
          );
        })}
        <div className="adjacency-input-row">
          {isEditable && (
            <FilledIconButton
              disabled={localAdjacencies.length >= getMaxCombinations()}
              onClick={addAdjacency}
            >
              <Plus />
            </FilledIconButton>
          )}
        </div>
      </div>
    </div>
  );
};
