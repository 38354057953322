import { ChangeEvent, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ValidationError } from 'yup';
import { SideBar, Button, TextInput, PageSpinner, RadioGroup } from '@components';
import { handleTrimObjValue } from '@service/helpers';
import { useGenerateNewPlan } from '../../hooks/usePlans';
import { useAdjacencies } from '../../hooks/useAdjacencies';
import { GenerateNewPlanForm, STRATEGIES_LIST } from '../../types';
import { generateNewPlanSchema } from '../../schemas';
import styles from '../sidebar-create-new-project/CreateProjectSideBar.module.css';

export interface GenerateNewPlanSideBarProps {
  sideBarStatus: boolean;
  toggleSideBar: () => void;
  predefinedStrategy?: number;
  sourcePlan?: number;
}

export const GenerateNewPlanSideBar = ({
  sideBarStatus,
  toggleSideBar,
  predefinedStrategy,
  sourcePlan,
}: GenerateNewPlanSideBarProps) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const generateNewPlan = useGenerateNewPlan(Number(projectId));
  const { adjacencies } = useAdjacencies(projectId!);
  const [formData, setFormData] = useState<GenerateNewPlanForm>({
    strategies: [predefinedStrategy || 1],
    description: '',
    name: '',
    plan_id: sourcePlan || undefined,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleFormChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const { id, value } = e.target;
      setFormData((data: GenerateNewPlanForm) => ({ ...data, [id]: value }));
      await generateNewPlanSchema.validateAt(id, { ...formData, [id]: value });
      setErrors((error) => ({ ...error, [id]: '' }));
    } catch (e: any) {
      setErrors((error) => ({ ...error, [e.path]: e.message }));
    }
  };

  const handleRadioGroupChange = (value: any) => {
    setFormData((data: GenerateNewPlanForm) => ({ ...data, strategies: [value] }));
  };

  const handleError = (validationErrors: ValidationError[]) => {
    const errors = validationErrors.reduce((acc: Record<string, string>, curr: ValidationError) => {
      acc[curr.path!] = curr.message;
      return acc;
    }, {});
    setErrors(errors);
  };

  const handleFormValidation = async (): Promise<GenerateNewPlanForm | undefined> => {
    let dataToResponse;
    try {
      dataToResponse = await generateNewPlanSchema.validate(formData, { abortEarly: false });
      setErrors({});
    } catch (e: any) {
      handleError(e.inner);
    }
    return dataToResponse;
  };

  const handleSubmit = async () => {
    const dataToRequest: GenerateNewPlanForm | undefined = await handleFormValidation();
    if (dataToRequest) {
      try {
        const response = await generateNewPlan.mutateAsync(handleTrimObjValue(dataToRequest));
        const createdPlanId = response.data.ids[0];
        navigate(`/v2/projects/${projectId}/plans/${createdPlanId}`);
      } catch (e: any) {
        if (e.response?.data?.error === 'Input data validation error') {
          handleError([{ path: 'name', message: e.response.data.details }] as ValidationError[]);
        }
      }
    }
  };

  return (
    <SideBar isOpen={sideBarStatus} onClose={toggleSideBar} sx={{ zIndex: 1700 }}>
      <div className={styles.wrapper}>
        <Button onClick={handleSubmit}>Generate</Button>
        <Typography variant="h1" component="h1">
          Generate New Plan
        </Typography>
        <TextInput
          id="name"
          required
          label="Plan Name"
          value={formData.name || ''}
          onChange={handleFormChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextInput
          id="description"
          label="Description"
          multiline
          minRows={6}
          value={formData.description || ''}
          onChange={handleFormChange}
          error={!!errors.description}
          helperText={errors.description}
        />
        {!predefinedStrategy && adjacencies && adjacencies.data && adjacencies.data!.length > 0 && (
          <div className={styles.textBlock}>
            <Typography variant="body1" component="p">
              Smart Occupancy Generation Tool is ready to generate new plan using the input data you
              provided. Once the plan is generated you will be able to modify and adjust it.
            </Typography>
            <br />
            <Typography variant="body1" component="p">
              Choose from the available algorithm models to generate new plan based on your specific
              requirements and preferences:
            </Typography>

            <RadioGroup
              value={formData.strategies[0]}
              onChange={handleRadioGroupChange}
              options={STRATEGIES_LIST}
            />
          </div>
        )}
        <PageSpinner
          isOpen={generateNewPlan.isLoading || (!predefinedStrategy && adjacencies.isFetching)}
        />
      </div>
    </SideBar>
  );
};
