import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MenuItem, Typography, IconButton } from '@mui/material';
import { MenuByClick, Tooltip } from '@components';
import { PRIMARY_SILVER_COLOR } from '@constants/colors';
import { MenuDots, Locked, Unlocked, Edit, Message } from '@icons/index';
import { sumHelper, groupByTeam } from './helpers';
import { TeamData, Floor as FloorType, FloorNote } from '../../types';
import { floorPlate as styles } from './styles';

type Action = 'clear' | 'edit' | 'lock';

interface OptionProps {
  config: Record<string, boolean>;
  action: Action;
  label: string;
}

interface MenuItemProps {
  items: OptionProps[];
  disabled: boolean;
  onClick: (action: Action, props: boolean) => void;
  isEmpty: boolean;
}

interface ItemProps {
  '1'?: number;
  '2'?: number;
  '3'?: number;
  total: number;
  locked: boolean;
  team: string;
}

interface TipProps {
  handleLock: (id: string, value: boolean) => void;
  handleAllocateTeam: (tID: string) => void;
  item: ItemProps;
  name: string;
  isEditable: boolean;
  teamNote?: string;
}

interface Props {
  data: FloorType;
  name: string;
  notes?: FloorNote;
  teams: TeamData;
  isDraggingOver: boolean;
  onLockTeam?: (fID: string, tID: string, v: boolean) => void;
  onClear?: (fID: string) => void;
  onTeamAllocate?: (fID: string, tID: string) => void;
  onFloorAction?: (data: { action: string; id: string }) => void;
  isEditable: boolean;
}

export const FloorPlate = ({
  data: { name: floorName, allocated_resources, id, resources },
  name,
  notes,
  teams,
  isDraggingOver,
  onLockTeam,
  onClear,
  onTeamAllocate,
  onFloorAction,
  isEditable,
}: Props) => {
  const allocatedTotal = sumHelper(allocated_resources);
  const resourcesTotal = sumHelper(resources);
  const dataGroupedByTeam: Record<string, ItemProps> = groupByTeam(allocated_resources);
  const hasLockedTeam: boolean = Object.values(dataGroupedByTeam).some(
    (item: ItemProps) => item.locked,
  );
  const wholeFloorLocked = !!(dataGroupedByTeam?.null?.total === resourcesTotal?.total);
  const handleAllocateTeam = (teamId: string) => onTeamAllocate!(id, teamId);
  const toggleLockTeam = (teamId: string, value: boolean) => onLockTeam!(id, teamId, value);
  const handleFloorAction = (action: Action) => {
    if (action === 'clear') {
      onClear!(id);
    } else {
      onFloorAction!({ action, id });
    }
  };

  return (
    <div>
      <div style={styles.header}>
        <div style={styles.nameBlock}>
          <Typography variant="body1" sx={styles.title}>
            {floorName}
            {notes?.message && (
              <Tooltip tooltip={notes.message}>
                <span style={styles.note}>
                  <Message />
                </span>
              </Tooltip>
            )}
          </Typography>
          <Typography variant="body2" sx={styles.name}>
            {name}
          </Typography>
        </div>
        <div style={styles.resources}>
          <Typography variant="body2">{`${allocatedTotal?.['2'] || 0}/${
            resourcesTotal['2'] || 0
          } offices`}</Typography>
          <Typography variant="body2">{`${allocatedTotal?.['1'] || 0}/${
            resourcesTotal['1'] || 0
          } workstations`}</Typography>
          <Typography variant="body2">{`${allocatedTotal?.total || 0}/${
            resourcesTotal.total || 0
          } seats`}</Typography>
          {isEditable && (
            <MenuByClick
              id={`floor-plate-${id}`}
              menuItems={
                <MenuItems
                  isEmpty={!allocated_resources.length}
                  onClick={handleFloorAction}
                  disabled={hasLockedTeam}
                  items={options}
                />
              }
            >
              <MenuDots />
            </MenuByClick>
          )}
        </div>
      </div>
      <div style={{ ...styles.progressWrapper, height: isDraggingOver ? '70px' : '48px' }}>
        {Object.keys(dataGroupedByTeam).length ? (
          Object.values(dataGroupedByTeam).map((item: ItemProps, idx: number) => (
            <Draggable
              key={item.team}
              draggableId={`${id}-floor-${item.team}`}
              index={idx}
              isDragDisabled={item.team ? !isEditable : true}
            >
              {(provider, state) => (
                <div
                  key={`${id}-floor-${item.team}`}
                  ref={provider.innerRef}
                  {...provider.draggableProps}
                  {...provider.dragHandleProps}
                  style={{
                    width: `${((item.total || 0) / resourcesTotal.total) * 100}%`,
                    boxShadow: state.isDragging ? '0px 0px 15px 0px rgba(0,0,0,0.7)' : 'none',
                    ...(item.team ? {} : { order: 1, marginLeft: 'auto' }),
                    ...provider.draggableProps.style,
                  }}
                >
                  <span
                    style={{
                      backgroundColor: item.team ? teams[item.team]?.color : 'transparent',
                      ...styles.progress,
                      ...(item.team ? {} : styles.empty),
                      ...(!item.team && item.locked ? styles.lockedEmpty : {}),
                    }}
                  >
                    <Tooltip
                      tooltip={
                        <Tip
                          handleLock={toggleLockTeam}
                          handleAllocateTeam={handleAllocateTeam}
                          item={item}
                          name={teams[item.team]?.name}
                          isEditable={item.team ? isEditable : false}
                          teamNote={notes?.teams.find((el) => el.team_id === item.team)?.message}
                        />
                      }
                      position="top"
                    >
                      <span style={{ ...styles.teamSeats, fontWeight: item.team ? 400 : 600 }}>
                        {item.locked && (
                          <Locked width="14" height="14" color={item.team ? 'white' : '#778F9C'} />
                        )}
                        {wholeFloorLocked ? 'EMPTY FLOOR' : item.total}
                      </span>
                    </Tooltip>
                  </span>
                </div>
              )}
            </Draggable>
          ))
        ) : (
          <span style={{ ...styles.progress, ...styles.w100, ...styles.empty }}>EMPTY FLOOR</span>
        )}
      </div>
    </div>
  );
};

const Tip = ({ handleLock, handleAllocateTeam, item, name, isEditable, teamNote }: TipProps) => {
  const toggleLock = () => handleLock(item.team, !item.locked);
  const invokeTeamAllocate = () => handleAllocateTeam(item.team);
  return (
    <div style={styles.tipWrapper}>
      <div style={styles.tipHeader}>
        <div style={styles.tipTitle}>{name}</div>
        {isEditable && (
          <div style={styles.tipActions}>
            {item.locked ? (
              <span onClick={toggleLock}>
                <Locked width="16" height="16" />
              </span>
            ) : (
              <span onClick={toggleLock}>
                <Unlocked width="16" height="16" />
              </span>
            )}
            <IconButton
              disabled={item.locked}
              onClick={invokeTeamAllocate}
              sx={{ padding: 0, margin: '-3px 0 0 5px' }}
            >
              <Edit color={item.locked ? PRIMARY_SILVER_COLOR[500] : 'white'} />
            </IconButton>
          </div>
        )}
      </div>
      <div>{item.total || 0} seats</div>
      <div>{item['1'] || 0} workstations</div>
      <div>{item['2'] || 0} offices</div>
      {teamNote && <p style={styles.noteMessage}>Note: {teamNote}</p>}
    </div>
  );
};

const MenuItems = ({ items, onClick, disabled, isEmpty }: MenuItemProps): any =>
  items.map(({ config, label, action }: OptionProps) => {
    const { clearWithLock, displayIfEmpty, displayIf, disabledIfLocked } = config;
    const handleClick = () => onClick(action, !!clearWithLock);

    if (displayIf && ((displayIfEmpty && !isEmpty) || (!displayIfEmpty && isEmpty))) {
      return null;
    }

    return (
      <MenuItem key={label} disabled={disabledIfLocked && disabled} onClick={handleClick}>
        {label}
      </MenuItem>
    );
  });

const options: OptionProps[] = [
  {
    label: 'Clear Floor',
    action: 'clear',
    config: {
      displayIf: true,
      disabledIfLocked: true,
      displayIfEmpty: false,
    },
  },
  {
    label: 'Lock Empty Space',
    action: 'lock',
    config: {},
  },
  {
    label: 'Edit Floor...',
    action: 'edit',
    config: {
      disabledIfLocked: true,
    },
  },
];
