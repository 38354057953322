import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Box, Paper, ThemeProvider } from '@mui/material';

import { PRIMARY_COLORS } from '@constants/colors';
import { HeaderDataProvider } from '../../context/headerData';
import { theme } from '../../schemas';

import { TopPanel } from './TopPanel';
import { BottomPanel } from './BottomPanel';

export const PageSkeleton = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === 'v2') {
      navigate('/v2/projects');
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <HeaderDataProvider>
          <TopPanel />
          <Paper
            sx={{
              padding: '40px 44px 48px 48px',
              backgroundColor: PRIMARY_COLORS.PRIMARY_WHITE,
              flex: 1,
            }}
          >
            <Outlet />
          </Paper>
        </HeaderDataProvider>
        <BottomPanel />
      </Box>
    </ThemeProvider>
  );
};
