import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Security } from '@okta/okta-react';
import { PageSpinner } from './components';
import { ClientDataProvider } from './context/ClientData';
import { AuthProvider } from './context/AuthContext';
import { AlertProvider } from './context/AlertContext';
import { AxiosProvider } from './context/AxoiosContext';
import store from './store/configure-store';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes';
import './Fonts.css';
import './index.css';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID?.trim(),
  scopes: ['openid', 'email'],
  // logoutUrl: `${process.env.REACT_APP_OKTA_ISSUER_URL}/v1/logout`, //is it necessary???
  redirectUri: `${window.location.origin}/Login`,
  postLogoutRedirectUri: `${window.location.origin}/Logout`,
});

const App = () => (
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const AppWithRouter = () => {
  const navigete = useNavigate();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const restoreOriginalUrl = () => navigete(toRelativeUrl('/v2/projects', window.location.origin));

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUrl}>
      <AlertProvider>
        <AxiosProvider>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ClientDataProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </ClientDataProvider>
            </Provider>
          </QueryClientProvider>
        </AxiosProvider>
      </AlertProvider>
    </Security>
  );
};
