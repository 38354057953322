import { ReactElement, useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { Filter } from '@icons/index';
import { MenuByClick } from '../Menu';
import { Checkbox } from '../Checkbox';

interface Props {
  columnName: string;
  handleView: (x: string, v: string) => string;
  selectedItems?: Array<string | number>;
  data: any[];
  enabled: boolean;
  onChange: (x: string, v: Array<string | number>) => void;
  children: ReactElement;
}

interface ItemProp {
  value: string | number;
  label: string;
}

interface MenuItemProps {
  items: ItemProp[];
  onClick: (item: string | number, value: boolean) => void;
  selectedItems: Array<string | number>;
}

const FilterWrapper = ({
  columnName,
  handleView,
  selectedItems = [],
  data,
  enabled,
  onChange,
  children,
}: Props) => {
  const filterItems: ItemProp[] | null = useMemo(() => {
    if (!enabled) return null;
    const uniqueItems = [...new Set(data.map((item) => item[columnName]))];
    return uniqueItems.map((i) => ({ value: i, label: handleView(columnName, i) }));
  }, [data]);

  if (!enabled) return children;

  const handleClick = (item: string | number, value: boolean) => {
    const filteredArray = selectedItems;
    if (value) {
      filteredArray.push(item);
    } else {
      const idx = filteredArray.findIndex((i: string | number) => i === item);
      filteredArray.splice(idx, 1);
    }
    onChange(columnName, filteredArray);
  };

  return (
    <div>
      {children}
      <MenuByClick
        btnSx={{ padding: '8px 8px 7px 8px' }}
        id={columnName}
        menuItems={
          <MenuItems onClick={handleClick} items={filterItems!} selectedItems={selectedItems} />
        }
      >
        <Filter />
      </MenuByClick>
    </div>
  );
};

export { FilterWrapper };

const MenuItems = ({ items, onClick, selectedItems }: MenuItemProps) =>
  items.map(({ value, label }: ItemProp) => {
    const handleClick = () => onClick(value, !checked);
    const checked: boolean = selectedItems.includes(value);
    return (
      <MenuItem key={value} onClick={handleClick}>
        <Checkbox checked={checked} />
        {label}
      </MenuItem>
    );
  });
