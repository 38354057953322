/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parsedErrorMessage } from '../../service';
import { getCurrentState, postFutureState } from '../../service/portfolioService';

const initialState = {
  is_plan_committed: false,
  is_loading: false,
  errorMessage: null,
  Current_State_Summary: {},
  State_Parameters: {
    Attendance_Policy: {
      Minimum_Visits_Per_Week: '',
    },
    Business_Units: [],
    Decision_Criteria: {
      Cost_Savings_In_Percent: '',
      Discount_Rate_In_Percent: '',
      Employee_Experience_In_Percent: '',
    },
    Design_Experience: {
      Flexible_Space: '',
      Is_Seat_Sharing_Allowed: '',
      SQFT_Per_Seat: '',
    },
  },
  Future_State_Parameters: {
    Attendance_Policy: {
      Minimum_Visits_Per_Week: '',
    },
    Business_Units: [],
    Decision_Criteria: {
      Cost_Savings_In_Percent: '',
      Discount_Rate_In_Percent: '',
      Employee_Experience_In_Percent: '',
    },
    Design_Experience: {
      Flexible_Space: '',
      Is_Seat_Sharing_Allowed: '',
      SQFT_Per_Seat: '',
    },
  },
  Cost_Per_Head_Impacts: {},
  Opportunity_List: [],
  Scenario_Id: '',
};

const name = 'opportunityState';
const getCurrentStateThunk = createAsyncThunk(
  `${name}/getStatus`,
  async (payload) => {
    const response = await getCurrentState(payload);
    return response.data;
  },
);

export const isLoadingSelector = (state) => state.opportunityState.is_loading;
export const isPlanCommittedSelector = (state) => state.opportunityState.is_plan_committed;
export const currentPlanSummarySelector = (state) => state.opportunityState.Current_State_Summary;

const postFutureStateThunk = createAsyncThunk(
  `${name}/postStatus`,
  async (payload, thunkApi) => {
    const {
      data,
      customerId,
    } = payload;
    const {
      Attendance_Policy,
      Design_Experience,
      Business_Units,
      Decision_Criteria,
    } = data;
    const modData = {
      Attendance_Policy,
      Design_Experience,
      Business_Units,
      Decision_Criteria,
    };
    try {
      const response = await postFutureState(modData, customerId);
      return response.data;
    } catch (e) {
      if (e?.response?.status === 504) {
        thunkApi.dispatch(postFutureStateThunk({ data, customerId }));
      }

      return thunkApi.rejectWithValue({
        message: parsedErrorMessage(e),
        statusCode: e?.response?.status,
      });
    }
  },
);

const opportunityStateSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateFutureState: (state, action) => {
      const { payload } = action;
      state.Future_State_Parameters = {
        ...state.Future_State_Parameters,
        ...payload,
      };
    },
    updateCurrentStateSummary: (state, action) => {
      const { payload } = action;
      state.Current_State_Summary = {
        ...payload,
      };
    },
    updatePortfolioResult: (state, action) => {
      const { cost_per_head_impacts, opportunity_list, ps_inputs } = action.payload;
      state.Opportunity_List = opportunity_list;
      state.Cost_Per_Head_Impacts = cost_per_head_impacts;
      state.State_Parameters = {
        ...state.State_Parameters,
        Decision_Criteria: ps_inputs.Decision_Criteria,
        Design_Experience: ps_inputs.Design_Experience,
        Attendance_Policy: ps_inputs.Attendance_Policy,
      };
      state.Future_State_Parameters = {
        ...state.Future_State_Parameters,
        Decision_Criteria: ps_inputs.Decision_Criteria,
        Design_Experience: ps_inputs.Design_Experience,
        Attendance_Policy: ps_inputs.Attendance_Policy,
      };
    },
    togglePlanCommited: (state, action) => {
      state.is_plan_committed = action?.payload;
    },
    resetOpportunityState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
    toggle_is_loading: (state, action) => {
      state.is_loading = action?.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentStateThunk.pending, (state) => {
      state.is_loading = true;
    });
    builder.addCase(getCurrentStateThunk.rejected, (state) => {
      state.is_loading = false;
    });
    builder.addCase(getCurrentStateThunk.fulfilled, (state, { payload }) => {
      const {
        Opportunity_List,
        Cost_Per_Head_Impacts,
        State_Parameters,
      } = payload;
      state.is_loading = false;
      state.Opportunity_List = Opportunity_List;
      state.Cost_Per_Head_Impacts = Cost_Per_Head_Impacts;
      if (State_Parameters) state.State_Parameters = State_Parameters;
      state.Future_State_Parameters = {
        ...state.Future_State_Parameters,
        ...State_Parameters,
      };
    });
    builder.addCase(postFutureStateThunk.pending, (state) => {
      state.is_loading = true;
    });
    builder.addCase(postFutureStateThunk.rejected, (state, action) => {
      if (action.payload?.statusCode !== 504) {
        state.is_loading = false;
        state.errorMessage = action?.payload?.message;
      }
    });
    builder.addCase(postFutureStateThunk.fulfilled, (state, { payload }) => {
      if (payload?.Opportunity_List) {
        state.Opportunity_List = payload.Opportunity_List;
      }

      if (payload?.Cost_Per_Head_Impacts) {
        state.Cost_Per_Head_Impacts = payload.Cost_Per_Head_Impacts;
      }

      if (payload?.Scenario_Id) {
        state.Scenario_Id = payload.Scenario_Id;
      }
      state.errorMessage = null;
      state.is_loading = false;
    });
  },
});

const currentStateParamSelector = () => {};

export {
  getCurrentStateThunk,
  postFutureStateThunk,
  currentStateParamSelector,
};

export const {
  toggle_is_loading,
  updateFutureState,
  togglePlanCommited,
  resetOpportunityState,
  updatePortfolioResult,
  updateCurrentStateSummary,
} = opportunityStateSlice.actions;

export default opportunityStateSlice.reducer;
