import propTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';

import { Alert } from '@mui/material';
import { Select, Button, PageSpinner } from '@components';
import { useAdjacencies, useTeamsForAdjacencies } from '@hooks/useAdjacencies';

import { useAlert } from '../../../context/AlertContext';

import Upload from '../../../containers/Upload/Upload';
import UploadTrackerBlock from '../../../containers/Upload/UploadTrackerBlock';
import { getSchema, saveMappedData, getSchemaList, getUploadStatus } from './service';

import correctOutputFormat from '../../../containers/Upload/flatfileConfig';
import { parsedErrorMessage } from '../../../service';
import { AdjacenciesForm } from './adjacency-form';
import { getFileMetadata, getOriginalFile } from '../../../service/appService';

import './SiteUpload.css';

const initOption = {
  name: '',
  schema: [],
};

export const SiteUpload = ({
  onGeneratePlans,
  onUploadFile,
  customerId,
  projectId,
  projectName,
}) => {
  const alert = useAlert();
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [defaultOption, setDefaultOption] = useState(initOption);
  const [schemaList, setSchemaList] = useState([]);
  const { adjacencies, saveAdjacencies } = useAdjacencies(projectId);
  const { teamsForAdjacencies } = useTeamsForAdjacencies(projectId);
  const [uploadStatus, setUploadStatus] = useState(null);

  // update latest option list
  const options = schemaList.map(({ option, disabled }) => ({
    value: option.value,
    label: option.name,
    disabled,
  }));

  const onDropdownChange = (name) => {
    if (name !== 'Adjacencies') {
      setIsLoading(true);
      getSchema(name)
        .then(({ data }) => {
          setDefaultOption({
            name,
            schema: data.schema,
          });
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          alert('error', e, e?.response?.data?.status);
        });
    } else {
      adjacencies.refetch();
      teamsForAdjacencies.refetch();
      setDefaultOption({ name });
    }
  };
  // Check and set the current upload status to the schema
  const setStatus = useCallback(
    async (schemaData) => {
      try {
        const compare = (a, b) => {
          if (a.required === b.required) return 0;
          if (b.required) return 1;
          return -1;
        };

        const schemaAdapter = (schemaResponse) =>
          [...schemaResponse].sort(compare).map((schema) => ({
            ...schema,
            option: {
              key: schema.id,
              value: schema.id,
              name: schema.displayName,
            },
          }));

        const { data: statusData } = await getUploadStatus(projectId);
        setUploadStatus(statusData);

        const combinedData = schemaData.map((schema) => {
          switch (schema.id) {
            case 'Adjacencies':
              return { ...schema, isCompleted: statusData.isAdjDataUploaded };
            case 'Space':
              return { ...schema, isCompleted: statusData.isSpaceDataUploaded };
            case 'Teams':
              return { ...schema, isCompleted: statusData.isTeamsDataUploaded };
            default:
              return schema;
          }
        });

        if (combinedData) {
          const adjacenciesIndex = combinedData.findIndex((schema) => schema.id === 'Adjacencies');
          // Adjacencies are unlocked if teams data is uploaded
          if (
            !combinedData.find((schema) => schema.id === 'Teams').isCompleted &&
            adjacenciesIndex > -1
          ) {
            combinedData[adjacenciesIndex].disabled = true;
          } else if (adjacenciesIndex > -1) {
            combinedData[adjacenciesIndex].disabled = false;
          }

          setSchemaList(schemaAdapter(combinedData));
          setIsInitialLoading(false);
          setIsLoading(false);

          if (statusData.isTeamsDataUploaded || statusData.isAdjDataUploaded) {
            onDropdownChange('Adjacencies');
          } else if (statusData.isSpaceDataUploaded) {
            onDropdownChange('Teams');
          } else {
            onDropdownChange('Space');
          }
        }
      } catch (e) {
        console.error(e);
        alert('error', e, e?.response?.data?.status);
      }
    },
    [customerId],
  );

  const fetchSchemaData = () => {
    setIsInitialLoading(true);
    // Fetch schema list
    getSchemaList()
      .then(({ data: schemaData }) => {
        // Format and add upload status to schema
        setStatus(schemaData);
      })
      .catch((e) => {
        alert('error', e, e?.response?.data?.status);
        setError(parsedErrorMessage(e));
        setIsInitialLoading(false);
      });
  };
  useEffect(() => {
    fetchSchemaData();
  }, [customerId, setStatus]);

  const onSubmitData = async (result) => {
    setIsLoading(true);
    const { name, schema } = defaultOption;
    const { batchId, data } = result;
    const {
      data: { headersMatched, originalFile },
    } = await getFileMetadata(batchId).catch((e) => {
      alert('error', e, e?.response?.data?.status);
      parsedErrorMessage(e);
    });

    const originalFileBlob = await getOriginalFile(batchId, originalFile);
    const schemaName = defaultOption.name === 'Teams' ? 'teams' : 'spaces';
    await onUploadFile(originalFile, originalFileBlob, schemaName);
    // Filter columns correctly matched to our schema
    data().then(({ rows }) => {
      const matchedFields = headersMatched.map((head) => head.matched_key);
      const processedData = correctOutputFormat(rows, matchedFields, schema);
      saveMappedData(name, customerId, matchedFields, processedData, projectId)
        .then(() => {
          setStatus(schemaList);
        })
        .catch((e) => {
          alert('error', e, e?.response?.data?.status);
          setError(parsedErrorMessage(e));
          setIsLoading(false);
        });
    });
  };

  const adjacencyHandling = async (adjacenciesToSave) => {
    try {
      await saveAdjacencies.mutateAsync({
        customer_id: customerId,
        project_id: projectId,
        teams_adjacency: adjacenciesToSave,
      });
      if (
        adjacenciesToSave.length === 0 ||
        (adjacencies.data.length === 0 && adjacenciesToSave.length > 0)
      ) {
        setStatus(schemaList);
      }
    } catch (e) {
      setError(parsedErrorMessage(e));
    }
  };

  // TODO: rewrite this with hooks as well
  if (isInitialLoading) {
    return <PageSpinner isOpen />;
  }

  const renderGeneratePlansButton = () => {
    if (schemaList.filter((schema) => schema.required).every((schema) => schema.isCompleted)) {
      return (
        <div className="generate-plans-button-container">
          <Button onClick={() => onGeneratePlans(uploadStatus.isAdjDataUploaded)}>
            Generate Plans
          </Button>
        </div>
      );
    }

    return null;
  };

  const renderAdjacenciesForm = () => {
    if (adjacencies?.data && teamsForAdjacencies?.data && !teamsForAdjacencies.isFetching) {
      return (
        <AdjacenciesForm
          saveAdjacencies={adjacencyHandling}
          teams={teamsForAdjacencies.data}
          adjacencies={adjacencies.data}
        />
      );
    }

    return null;
  };

  return (
    <>
      {error && (
        <Alert className="mt-4" severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <h2 className="app-title font-h4 mb-2">Upload Source Data</h2>
      {schemaList.length > 0 && (
        <div className="tgm-block mb-4">
          <div className="upload-tracker-container">
            <hr className="trackerline" />
            <div className="upload-tracker-box">
              {schemaList.map((sch) => (
                <UploadTrackerBlock
                  key={sch.option.key}
                  name={sch.option.name}
                  required={sch.required}
                  completed={sch.isCompleted}
                  disabled={sch.disabled}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="upload-container">
        <div className="upload-div">
          <div className="d-flex justify-content-between">
            <span style={{ width: 300, display: 'block' }}>
              <Select
                id="data-upload-category"
                label="Select Client Data Types"
                options={options}
                value={defaultOption.name}
                onChange={(e) => onDropdownChange(e.target.value)}
              />
            </span>
          </div>
          {defaultOption.name === 'Adjacencies' ? (
            renderAdjacenciesForm()
          ) : (
            <>
              <p className="dropzone-label">File Upload</p>
              <div id="drop-zone">
                <Upload
                  schemaName={defaultOption.name}
                  org={{ id: projectId, name: projectName }}
                  selectSchema={defaultOption.schema}
                  onSubmitData={onSubmitData}
                />
              </div>
            </>
          )}
        </div>
        {renderGeneratePlansButton()}
      </div>
      <PageSpinner
        position="fixed"
        isOpen={
          isLoading ||
          teamsForAdjacencies.isFetching ||
          adjacencies.isFetching ||
          saveAdjacencies.isLoading
        }
      />
    </>
  );
};

SiteUpload.propTypes = {
  onGeneratePlans: propTypes.func,
  onUploadFile: propTypes.func,
  customerId: propTypes.number,
  projectId: propTypes.number,
  projectName: propTypes.string,
};
