import { Link } from 'react-router-dom';
import { ThemeProvider, Typography } from '@mui/material';
import { Button } from '@components';
import { NotFound } from '@icons/index';
import { theme } from '../schemas';

const PageNotFound = () => (
  <ThemeProvider theme={theme}>
    <section style={styles.wrapper}>
      <figure>
        <NotFound />
        <figcaption style={styles.content}>
          <Typography variant="h5" sx={{ fontWeight: 400 }}>
            Sorry! The page you are looking for was not found.
          </Typography>
          <Link to="/v2/projects">
            <Button>Go Home</Button>
          </Link>
        </figcaption>
      </figure>
    </section>
  </ThemeProvider>
);

export default PageNotFound;

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    margin: '20px 0',
  },
};
