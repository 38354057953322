import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { DEBUG } from '@service/index';

import rootReducer from '../redux/reducers/index';
import monitorReducerEnhancer from './enhancers/monitorReducer';
// import loggerMiddleware from './middleware/logger';

const getPreparedMiddlewares = () => {
  const middlewares = [];

  if (DEBUG) {
    middlewares.push(createLogger({ collapsed: true }));
  }

  return middlewares;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: '',
      },
    }),
    ...getPreparedMiddlewares(),
  ],
  enhancers: DEBUG ? [monitorReducerEnhancer] : [],
});

export default store;
