import { Font, StyleSheet } from '@react-pdf/renderer';

import { PRIMARY_COLORS, SECONDARY_COLORS, PRIMARY_SILVER_COLOR } from '@constants/colors';

import FinancierSemiBold from '../../../fonts/FinancierDisplay/TestFinancierDisplay-Semibold.otf';

import CalibreLight from '../../../fonts/TestCalibre/Calibre-Light.otf';
import CalibreRegular from '../../../fonts/TestCalibre/Calibre-Regular.otf';
import CalibreMedium from '../../../fonts/TestCalibre/Calibre-Medium.otf';

const floorWidth = '510px';

Font.register({
  family: 'FinancierDisplayPdf',
  fonts: [
    {
      src: FinancierSemiBold,
      fontWeight: 'semibold',
    },
  ],
});

Font.register({
  family: 'CalibrePdf',
  fonts: [
    {
      src: CalibreLight,
      fontWeight: 'normal',
    },
    {
      src: CalibreRegular,
      fontWeight: 'medium',
    },
    {
      src: CalibreMedium,
      fontWeight: 'semibold',
    },
  ],
});

export const styles = StyleSheet.create({
  container: {
    padding: '40px',
    fontFamily: 'CalibrePdf',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.5,
    color: PRIMARY_COLORS.PRIMARY_BLACK,
  },
  title: {
    fontSize: '40px',
    // lineHeight: '52px',
    fontWeight: 600,
    fontFamily: 'FinancierDisplayPdf',
  },
  status: {
    color: SECONDARY_COLORS.VIOLET,
    fontSize: '13px',
    fontFamily: 'CalibrePdf',
    fontWeight: 600,
  },
  metaInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'uppercase',
  },
  metaInfoItem: {
    marginRight: '25px',
  },
  metaInfoLabel: {
    fontWeight: 600,
  },
  lockedEmpty: {
    backgroundColor: PRIMARY_COLORS.PRIMARY_SILVER,
  },
  floor: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '6px',
  },
  floorInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  floorMetaTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: floorWidth,
    color: SECONDARY_COLORS.DARK_GREY,
  },
  floorMetaLeft: {
    display: 'flex',
    flexDirection: 'row',
    color: SECONDARY_COLORS.DARK_GREY,
  },
  floorNumber: {
    fontWeight: 600,
    textTransform: 'uppercase',
    marginRight: '16px',
  },
  floorMetaRight: {
    display: 'flex',
    flexDirection: 'row',
    color: PRIMARY_COLORS.PRIMARY_SILVER,
  },
  floorMetaRightItem: {
    display: 'flex',
    marginLeft: '16px',
  },
  floorPlate: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  floorFilling: {
    height: '36px',
    width: floorWidth,
    marginBottom: '6px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: PRIMARY_SILVER_COLOR[300],
    marginRight: '24px',
  },
  teamOnFloor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamAmount: {
    fontWeight: 600,
    color: PRIMARY_COLORS.PRIMARY_WHITE,
    lineHeight: 1.2,
  },
  emptyFloor: {
    backgroundColor: PRIMARY_SILVER_COLOR[300],
    width: '100%',
    textTransform: 'uppercase',
  },
  emptyFloorText: {
    fontWeight: 500,
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    lineHeight: 1.2,
  },
  whiteEmptyText: {
    fontWeight: 500,
    color: PRIMARY_COLORS.PRIMARY_WHITE,
    lineHeight: 1.2,
  },
  teamsLabel: {
    fontWeight: 600,
    color: SECONDARY_COLORS.DARK_GREY,
    textTransform: 'uppercase',
    position: 'absolute',
    right: '-24px',
  },

  team: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '24px',
    marginBottom: '6px',
  },
  teamColor: {
    backgroundColor: 'green',
    height: '36px',
    width: '8px',
    marginRight: '4px',
  },
  teamInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    lineHeight: 1.2,
    // alignItems: 'flex-end',
  },
  teamName: {
    fontSize: '16px',
    fontWeight: 600,
  },
  teamSize: {
    color: PRIMARY_COLORS.PRIMARY_SILVER,
  },

  unAllocatedRow: {
    marginTop: '30px',
  },
  unAllocatedInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '36px',
    width: floorWidth,
    marginRight: '24px',
  },
  population: {
    display: 'flex',
    flexDirection: 'row',
  },
  populationLabel: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  unallocatedTeams: {
    fontWeight: 600,
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    textTransform: 'uppercase',
  },

  pageNumber: {
    color: PRIMARY_COLORS.PRIMARY_SILVER,
    fontSize: '13px',
    position: 'absolute',
    bottom: 10,
    left: 40,
  },
});
