import { Link } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { BreadcrumbOptions } from '../../types';

interface Props {
  list: BreadcrumbOptions[];
}

const Breadcrumbs = ({ list }: Props) => (
  <MUIBreadcrumbs aria-label="breadcrumb">
    {list.map(({ link, label }: BreadcrumbOptions) => (
      <Link key={link} to={link}>
        {label}
      </Link>
    ))}
  </MUIBreadcrumbs>
);

export default Breadcrumbs;
